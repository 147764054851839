<template>
  <div>
    <WsActionApi
      class="width-full"
      :fetchUrl="_fetchUrl"
      v-slot="{ res, isFetching }"
    >
      <WsLoading v-if="isFetching"></WsLoading>
      <vue-excel-editor
        ref="VueExcelEditor"
        v-else
        class="width-full"
        :value="$_getFormatedData(res.data)"
      >
        <vue-excel-column
          v-for="column in excelColumns"
          :key="column.field"
          v-bind="column"
        />
      </vue-excel-editor>
    </WsActionApi>
    <WsFixedIconBtns :btns="btns"></WsFixedIconBtns>
  </div>
</template>

<script>
export default {
  data() {
    return {
      btns: [
        {
          icon: "icon-md-print",
          text: "匯出",
          size: 60,
          onClick: () => {
            this.$_print();
          },
        },
      ],
      excelColumns: [
        {
          field: "year_month",
          label: "年/月",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "xc_employee_no",
          label: "員編",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "xc_employee_department",
          label: "部門",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "xc_employee_name",
          label: "姓名",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "employee_start_at",
          label: "到職日",
          type: "string",
          width: "100px",
          editable: false,
          readonly: true,
        },
        {
          field: "status",
          label: "狀態",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "approved_salary",
          label: "核定月薪",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "basic_salary",
          label: "本俸",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "meal_expenses",
          label: "伙食費",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "work_allowance",
          label: "工作加給",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "allowance",
          label: "津貼",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "transportation_allowance",
          label: "交通加給",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "supervisor_allowance",
          label: "主管加給",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "lunar_new_year_bonus",
          label: "春節年終獎金",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "performance_bonus",
          label: "績效獎金",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "welfare_fund",
          label: "福利金",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "retirement_employee_contribution",
          label: "退休金-勞工自願提撥",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "retirement_government_contribution",
          label: "退休金-公提勞退專戶",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "severance_reserve_fund",
          label: "退職準備金",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "tw_labor_insurance",
          label: "代扣勞保費",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "tw_health_insurance",
          label: "代扣健保費",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "personal_leave_deduction",
          label: "事假扣款",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "personal_leave_deduction_return",
          label: "事假扣款補回",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "sick_leave_deduction",
          label: "病假扣款",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "sick_leave_deduction_return",
          label: "病假扣款補回",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "annual_leave_convert",
          label: "特休逾期未休折算",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "total_salary",
          label: "實發金額",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "remark",
          label: "備註",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
      ],
    };
  },
  methods: {
    $_print() {
      const format = "xlsx";
      const exportSelectedOnly = false;
      const filename = "薪資單";
      this.$refs.VueExcelEditor.exportTable(
        format,
        exportSelectedOnly,
        filename
      );
    },
    $_getSlectValueText(value, items) {
      const item = items.find((item) => item.value === value);
      return item ? item.text : "";
    },
    $_getFormatedData(data) {
      return data.map((item) => {
        return {
          year_month: this.$moment(item.start_at).format("YYYY-MM"),
          xc_employee_no: item.xc_employee.no,
          xc_employee_name: item.xc_employee.name,
          xc_employee_department: item.xc_employee.xc_employee_department?.name,
          employee_start_at: this.$moment(
            item.xc_employee.employee_start_at
          ).format("YYYY-MM-DD"),
          status: this.$_getSlectValueText(
            item.xc_employee.status,
            this.$store.state.stone_model.xc_employee.fields.status.items
          ),
          approved_salary: item.approved_salary,
          approved_salary_without_transportation:
            item.approved_salary_without_transportation,
          basic_salary: item.basic_salary,
          meal_expenses: item.meal_expenses,
          work_allowance: item.work_allowance,
          transportation_allowance: item.transportation_allowance,
          allowance: item.allowance,
          supervisor_allowance: item.supervisor_allowance,
          lunar_new_year_bonus: item.lunar_new_year_bonus,
          performance_bonus: item.performance_bonus,
          welfare_fund: item.welfare_fund,
          retirement_employee_contribution:
            item.retirement_employee_contribution,
          retirement_government_contribution:
            item.retirement_government_contribution,
          severance_reserve_fund: item.severance_reserve_fund,
          total_salary: item.total_salary,
          remark: item.remark,
        };
      });
    },
  },
  computed: {
    _fetchUrl() {
      return `/xc_employee_salary_slip/index/date?start=${this.$route.query.start}&end=${this.$route.query.end}&xc_employee=${this.$route.query.xc_employee}`;
    },
  },
};
</script>

<style>
</style>