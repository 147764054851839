<template>
  <div>
    <WsActionApi
      :fetchUrl="`xc_project_member`"
      :params="_params"
      v-slot="{ res, isFetching }"
    >
      <WsLoading v-if="isFetching"></WsLoading>
      <XcProjectMemberMonthDayReadTable
        v-else
        :items="res.data"
      />
    </WsActionApi>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showFields: ["admin", "scopes"],
    };
  },
  props: {
    xc_project: {},
  },
  computed: {
    _params() {
      return {
        xc_project: this.xc_project.id,
      };
    },
    _createData() {
      return {
        xc_project: this.xc_project.id,
      };
    },
    _updateData() {
      return {
        xc_project: this.xc_project.id,
      };
    },
    _creatable() {
      return this.$o_o.$h._m.xc_project.hasScope(
        ["admin"],
        this.xc_project.admin_scope
      );
    },
    _updatable() {
      return this.$o_o.$h._m.xc_project.hasScope(
        ["admin"],
        this.xc_project.admin_scope
      );
    },
    _deletable() {
      return this.$o_o.$h._m.xc_project.hasScope(
        ["admin"],
        this.xc_project.admin_scope
      );
    },
  },
};
</script>

<style>
</style>