<template>
  <div class="tag-target-model-tags">
    <WsModelFetchIndex
      modelName="tag"
      :params="_params"
      v-slot="{modeldatas}"
    >
      <WsFlex :isWrap="true">
        <WsTag
          v-for="(tag,tagIndex) in modeldatas.data"
          :key="tagIndex"
          @click.native="$emit('click-tag',tag)"
        >
          {{tag.name}}
        </WsTag>
      </WsFlex>
    </WsModelFetchIndex>
  </div>
</template>

<script>
export default {
  props: {
    target: {},
  },
  computed: {
    _params() {
      return {
        target: this.target,
      };
    },
  },
};
</script>

<style lang="scss">
.tag-target-model-tags {
  .ws-tag {
    margin: 4px;
  }
}
body {
  &.dark {
    .tag-target-model-tags {
      .ws-tag {
        background-color: var(--black1l);
        color: var(--white5d);
      }
    }
  }
}
</style>