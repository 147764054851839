<template>
  <div class="ws-info">
    <WsText
      v-if="_label"
      size="14"
      class="ws-info__label mb-6"
    >{{ _label }}</WsText>
    <div class="ws-info__content">
      <WsText v-if="(value == null || value.length == 0 || (typeof (value) == 'object' && Object.keys(value).length === 0)) && _type != 'custom' && _type != 'icon' && _type != 'date-range-or-not'"> - </WsText>
      <WsInfoText
        v-else-if="_type == 'text' || _type == 'password' || _type == 'email' || _type == 'textarea' || _type == 'number' || _type == 'checkbox' || _type == 'rules' || _type == 'year'"
        v-bind="$props"
      ></WsInfoText>
      <WsInfoCurrency
        v-else-if="_type == 'currency'"
        v-bind="$props"
      ></WsInfoCurrency>
      <WsInfoLink
        v-else-if="_type == 'link'"
        v-bind="$props"
      ></WsInfoLink>
      <WsInfoLocales
        v-else-if="_type == 'locales'"
        v-bind="$props"
      ></WsInfoLocales>
      <WsInfoTime
        v-else-if="_type == 'time'"
        v-bind="$props"
      ></WsInfoTime>
      <WsInfoDatetime
        v-else-if="_type == 'datetime'"
        v-bind="$props"
      ></WsInfoDatetime>
      <WsInfoDate
        v-else-if="_type == 'date' || _type == 'dateOfBirth'"
        v-bind="$props"
      ></WsInfoDate>
      <WsInfoDateRangeOrNot
        v-else-if="_type == 'date-range-or-not'"
        v-bind="$props"
      ></WsInfoDateRangeOrNot>
      <WsInfoFile
        v-else-if="_type == 'file'"
        v-bind="$props"
      ></WsInfoFile>
      <WsInfoFiles
        v-else-if="_type == 'files'"
        v-bind="$props"
      ></WsInfoFiles>
      <WsInfoBelongsTo
        v-else-if="_type == 'belongsTo'"
        v-bind="$props"
      ></WsInfoBelongsTo>
      <WsInfoHasMany
        v-else-if="_type == 'hasMany'"
        v-bind="$props"
      ></WsInfoHasMany>
      <WsInfoBelongsToMany
        v-else-if="_type == 'belongsToMany'"
        v-bind="$props"
      ></WsInfoBelongsToMany>
      <WsInfoHtml
        v-else-if="_type == 'editor'"
        v-bind="$props"
      ></WsInfoHtml>
      <WsInfoSwitch
        v-else-if="_type == 'switch'"
        v-bind="$props"
      ></WsInfoSwitch>
      <WsInfoSelect
        v-else-if="_type == 'select'"
        v-bind="$props"
      ></WsInfoSelect>
      <WsInfoSelect
        v-else-if="_type == 'multi-select'"
        v-bind="$props"
        multiple
      ></WsInfoSelect>
      <WsInfoRadio
        v-else-if="_type == 'radio'"
        v-bind="$props"
      ></WsInfoRadio>
      <WsInfoList
        v-else-if="_type == 'list'"
        v-bind="$props"
        :showFields="_showFields"
      ></WsInfoList>
      <WsInfoList
        v-else-if="_type == 'hasManyList'"
        :value="value"
        :fields="fields"
        :showFields="_showFields"
      ></WsInfoList>
      <WsInfoPocketImage
        v-else-if="_type == 'pocket_image'"
        v-bind="$props"
      />
      <WsInfoImage
        v-else-if="_type == 'image'"
        v-bind="$props"
      />
      <WsInfoImages
        v-else-if="_type == 'images'"
        v-bind="$props"
      />
      <WsInfoImage
        v-else-if="_type == 'file_image'"
        v-bind="$props"
      />
      <WsInfoImages
        v-else-if="_type == 'file_images'"
        v-bind="$props"
      />
      <!-- <WsInfoImages
        v-else-if="_type=='images'"
        v-bind="$props"
      /> -->
      <WsInfoForm
        v-else-if="_type == 'form'"
        v-bind="$props"
      ></WsInfoForm>
      <WsInfoPocketFile
        v-else-if="_type == 'pocket_file'"
        v-bind="$props"
      />
      <WsInfoColor
        v-else-if="_type == 'color'"
        v-bind="$props"
      ></WsInfoColor>
      <WsInfoStringCompare
        v-else-if="_type == 'stringCompare'"
        v-bind="$props"
      ></WsInfoStringCompare>
      <WsInfoTags
        v-else-if="_type == 'tags'"
        v-bind="$props"
      ></WsInfoTags>
      <WsInfoSelect
        v-else-if="_type == 'checkboxs'"
        v-bind="$props"
        :multiple="true"
      ></WsInfoSelect>
      <WsInfoFields
        v-else-if="_type == 'fields'"
        v-bind="$props"
      >
      </WsInfoFields>
      <WsInfoForm
        v-else-if="_type == 'payload'"
        v-bind="$props"
      >
      </WsInfoForm>
      <WsInfoBusinessHours
        v-else-if="_type == 'business_hours'"
        v-bind="$props"
      ></WsInfoBusinessHours>
      <WsInfoAvailableTime
        v-else-if="_type == 'available_time'"
        v-bind="$props"
      ></WsInfoAvailableTime>
      <WsInfoWasaIntro
        v-else-if="_type == 'wasaintro'"
        v-bind="$props"
      ></WsInfoWasaIntro>
      <WsInfoIcon
        v-else-if="_type == 'icon' || _type == 'icon-picker'"
        v-bind="$props"
      ></WsInfoIcon>
      <WsInfoLocaleMessage
        v-else-if="_type == 'locale_message'"
        v-bind="$props"
      ></WsInfoLocaleMessage>
      <WsInfoFileSize
        v-else-if="_type == 'file-size'"
        v-bind="$props"
      ></WsInfoFileSize>
      <WsInfoVideo
        v-else-if="_type == 'video'"
        :value="value"
        v-bind="options"
        :hasControls="hasControls"
      ></WsInfoVideo>
      <WsInfoAudio
        v-else-if="_type == 'audio'"
        :value="value"
        v-bind="options"
        :hasControls="hasControls"
      ></WsInfoAudio>
      <WsInfoSystemAudio
        v-else-if="_type == 'system_audio'"
        :value="value"
        v-bind="options"
        :hasControls="hasControls"
      ></WsInfoSystemAudio>
      <WsUserLabel
        v-else-if="_type == 'user_label'"
        :value="value"
        v-bind="options"
      ></WsUserLabel>
      <WsIcon
        v-else-if="_type == 'icon_picker'"
        v-bind="$props"
        size="30"
      />
      <WsInfoVideos
        v-else-if="_type == 'videos'"
        v-bind="$props"
      />
      <WsInfoTextArray
        v-else-if="_type == 'text_array'"
        v-bind="$props"
      />
      <WsInfoHourRanges
        v-else-if="_type == 'hour_ranges'"
        v-bind="$props"
      />
      <component
        v-else-if="_type == 'component'"
        :is="component"
        v-bind="$props"
      ></component>
      <div
        v-else-if="_type == 'custom'"
        v-html="getValue(modelData, value)"
        :style="[{ 'color': color }]"
      ></div>
    </div>
    <WsFlex
      alignItems="center"
      class="mt-8"
      v-if="warnText"
    >
      <WsIcon name="icon-md-warning" />
      <WsText class="ml-4">{{warnText}}</WsText>
    </WsFlex>
  </div>
</template>

<script>
export default {
  computed: {
    _type() {
      if (this.infoType) {
        return this.infoType;
      } else {
        return this.type;
      }
    },
    _label() {
      if (this.displayLabel) {
        return this.$t(this.label);
      } else {
        return null;
      }
    },
    _showFields() {
      if (this.showFields) {
        return this.showFields;
      } else if (this.fields) {
        const _showFields = [];
        for (let fieldKey in this.fields) {
          _showFields.push(fieldKey);
        }
        return _showFields;
      } else {
        return [];
      }
    },
  },
  props: {
    hasControls: {},
    options: {
      type: Object,
    },
    modelData: {
      type: [Object, Array],
      default: null,
    },
    showFields: {
      type: Array,
      default: null,
    },
    hideOnNull: {
      type: Boolean,
      default: true,
    },
    displayLabel: {
      type: Boolean,
      default: true,
    },
    getValue: {
      type: Function,
      default: null,
    },
    getText: {
      type: Function,
      default: null,
    },
    relationPopup: {
      type: Boolean,
      default: false,
    },
    relationLink: {
      type: Boolean,
    },
    showLocalePicker: {
      type: Boolean,
      default: false,
    },
    activeText: {
      type: String,
      default: "Y",
    },
    inactiveText: {
      type: String,
      default: "N",
    },
    textKey: {
      type: String,
      default: "name",
    },
    locale: {
      type: String,
      default: null,
    },
    active: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: null,
    },
    conditions: {
      type: Array,
      default: null,
    },
    maxWidth: {},
    previewMaxWidth: {},
    previewMaxHeight: {},
    fields: {
      type: [Object, Array],
      default: null,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    infoType: {},
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    note: {
      type: String,
      default: null,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    isOk: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {},
    name: {
      type: [String, Array],
      default: null,
    },
    rules: {
      type: String,
      default: null,
    },
    modelName: {
      type: String,
      default: null,
    },
    parentState: {},
    parent: {
      type: String,
      default: null,
    },
    labelInLocale: {
      type: Boolean,
      default: false,
    },
    getDisplayText: {
      type: Function,
    },
    displayInText: {
      type: Boolean,
    },
    noStyle: {
      type: Boolean,
    },
    color: {
      type: String,
    },
    component: {},
    switchInInfo: {
      Boolean,
    },
    keyName: {},
    warnText: {},
  },
};
</script>