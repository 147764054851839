<template>
  <div
    class="ws-table"
    :class="[`style-mode-${styleMode}`]"
  >
    <WsNoDataMessage v-if="(!items||!items.length)&&!loading">{{$t('no data')}} :(</WsNoDataMessage>
    <div
      v-else
      class="ws-table__table-container"
    >
      <table
        cellspacing="0"
        cellpadding="0"
      >
        <tr>
          <th
            v-if="selectable"
            class="check"
          >
            <WsState
              type="checkbox"
              :value="_tableCheckValue"
              @input="$_onTableCheckInput($event)"
            ></WsState>
          </th>
          <th
            v-for="(headersItem,headersIndex) in headers"
            :key="headersIndex"
            :style="[{ width:`${headersItem.width}px` }, { 'max-width': `${headersItem.width}px` }, { 'min-width': `${headersItem.width}px` }]"
          >
            <span>{{headersItem.text}}</span>
          </th>
          <th
            v-if="actions"
            :style="[{ width: `${actionWidth}px` }, { 'min-width': `${actionWidth}px` }, { 'max-width': `${actionWidth}px` },]"
          >
          </th>
        </tr>
        <tr
          :key="itemIndex"
          v-for="(item,itemIndex) in items"
          :class="[
            {selected:$_getRowCheckValue(item)}
          ]"
        >
          <td
            v-if="selectable"
            class="check"
          >
            <WsState
              type="checkbox"
              :value="$_getRowCheckValue(item)"
              @input="$_onRowCheckInput($event,item)"
            ></WsState>
          </td>
          <td
            v-for="(headersItem,headersIndex) in headers"
            :key="headersIndex"
          >
            <slot
              name="td"
              :headersItem="headersItem"
              :item="item"
              :itemIndex="itemIndex"
            >
              {{$_getItemValue(item,headersItem)}}
            </slot>
          </td>
          <td v-if="actions">
            <WsFlex>
              <div
                v-for="(actionItem, actionIndex) in actions"
                :key="actionIndex"
              >
                <WsIconBtn
                  class="ma-4"
                  v-if="$_actionDisplayCheck(actionItem,item)"
                  :name="actionItem.icon"
                  :to="$_getTo(actionItem,item)"
                  @click="$emit(actionItem.emit,{
                    item:item,
                    itemIndex:itemIndex
                  })"
                ></WsIconBtn>
              </div>
            </WsFlex>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    $_onRowCheckInput($event, item) {
      let _value = this.value ? [...this.value] : [];
      const exist = _value.find((e) => {
        return e[this.modelDataKey] == item[this.modelDataKey];
      });
      if ($event) {
        if (!exist) {
          const _item = this.items.find((e) => {
            return e[this.modelDataKey] == item[this.modelDataKey];
          });
          _value.push(_item);
        }
      } else {
        if (exist) {
          const _valueIndex = this.value.findIndex((e) => {
            return e[this.modelDataKey] == item[this.modelDataKey];
          });
          _value.splice(_valueIndex, 1);
        }
      }
      this.$emit("input", _value);
    },
    $_getRowCheckValue(item) {
      if (!this.value) {
        return false;
      }
      const _item = this.value.find((e) => {
        return e[this.modelDataKey] == item[this.modelDataKey];
      });
      if (_item) {
        return true;
      } else {
        return false;
      }
    },
    $_onTableCheckInput($event) {
      let _value;
      if ($event) {
        _value = [...this.items];
      } else {
        _value = [];
      }
      this.$emit("input", _value);
    },
    $_getTo(actionItem, item) {
      if (actionItem.getTo) {
        return actionItem.getTo(item);
      } else if (actionItem.to) {
        return actionItem.to;
      } else {
        return undefined;
      }
    },
    $_getItemValue(item, headersItem) {
      if (headersItem.getDisplayValue) {
        return headersItem.getDisplayValue(item);
      } else if (item[headersItem.value] !== null) {
        return item[headersItem.value];
      } else {
        return "-";
      }
    },
    $_actionDisplayCheck(actionItem, item) {
      if (!actionItem.displayCheck) {
        return true;
      } else {
        return actionItem.displayCheck(item);
      }
    },
  },
  computed: {
    _tableCheckValue() {
      if (this.value && this.value.length) {
        if (this.value.length == this.items.length) {
          return true;
        } else {
          return "-";
        }
      } else {
        return false;
      }
    },
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
    },
    headers: {
      type: Array,
    },
    actions: {
      type: Array,
    },
    selectable: {
      type: Boolean,
    },
    value: {
      type: Array,
    },
    modelDataKey: {
      type: String,
      default: "id",
    },
    actionWidth: {},
    styleMode: {
      type: String,
    },
  },
};
</script>

<style>
</style>