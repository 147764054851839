<template>
  <WsLink :to="`/xc_task/${xc_task.id}`">
    <WsText>#{{ xc_task.id }} {{ xc_task.name }}</WsText>
  </WsLink>
</template>

<script>
export default {
  props: {
    xc_task: {},
  },
};
</script>

<style>
</style>