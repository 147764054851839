<template>
  <div>
    <WsInfoForm
      :fields="_fields"
      :value="xc_project"
    ></WsInfoForm>
    <WsBtn
      class="mt-40"
      v-if="$o_o.$h._m.xc_project.hasScope(['edit','admin'],xc_project.admin_scope)"
      :to="`/xc_project/${this.xc_project.id}/update`"
    >編輯資訊</WsBtn>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: `資訊 | ${this.xc_project.name}`,
    };
  },
  props: {
    xc_project: {},
  },
  computed: {
    _fields() {
      // const _fields = { ...this.$store.state.stone_model.xc_project.fields };
      const _fields = {
        ...this.$o_o.$h.model.getFieldsByKeys(
          this.$store.state.stone_model.xc_project.fields,
          this.$store.state.stone_model.xc_project.crudSetting.read.leftFields
        ),
      };
      if (
        !this.$o_o.$h._m.xc_project.hasScope(
          ["edit", "admin"],
          this.xc_project.admin_scope
        )
      ) {
        delete _fields.private_infos;
      }
      return _fields;
    },
  },
};
</script>

<style>
</style>