<template>
  <div>
    <WsUpdate
      label="Footer"
      :fields="fields"
      :primary="primary"
      getUrl="/system_setting/web"
      :completeRedirect="false"
      :postEncode="false"
      :value="state"
      @input="$_onInput($event)"
      emitInput
    />
    <WsMain v-if="state?.footer?.enable_custom_footer">
      <WsCard>
        <WsStateForm
          v-model="state.footer"
          :fields="custom_fields"
        />
      </WsCard>
      <WsCard v-if="state?.footer?.custom_footer_fields" class="mt-20">
        <WsText size="5">客製化區塊 - 欄位內容</WsText>
        <WsState
          type="form"
          v-model="state.footer.custom_footer_content"
          :fields="state.footer.custom_footer_fields"
        />
      </WsCard>
    </WsMain>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fields: {
        footer: {
          type: "form",
          fields: {
            is_active: {
              type: 'switch',
              label: this.$t('前台啟用 Footer'),
              activeText: 'Fixed',
              inactiveText: 'On Fixed',
              defaultValue: 1,
            },
            type: {
              type: "select",
              label: "WsFooterType",
              items: [
                {
                  text: "WsFooter001",
                  value: "WsFooter001",
                },
                {
                  text: "WsFooter002",
                  value: "WsFooter002",
                },
                {
                  text: "WsFooter003",
                  value: "WsFooter003",
                },
                {
                  text: "WsFooter004",
                  value: "WsFooter004",
                },
                {
                  text: "WsFooter005",
                  value: "WsFooter005",
                },
              ],
            },
            linkList: {
              type: "list",
              label: this.$t("連結選單"),
              fields: {
                name: {
                  type: "text",
                  label: this.$t("標題"),
                },
                type: {
                  type: "radio",
                  label: this.$t("選項類型"),
                  items: [
                    {
                      value: "text",
                      label: this.$t("文字"),
                    },
                    // {
                    //   value: 'icon',
                    //   label: 'Icon',
                    // },
                  ],
                },
                list: {
                  type: "list",
                  label: this.$t("選單列表"),
                  fields: {
                    href: {
                      type: "link",
                      label: this.$t("網站外部連結 (外部連結、內部連結設定其一即可)"),
                    },
                    to: {
                      type: "text",
                      label: this.$t("網站內部連結 (以 / 做開頭)"),
                    },
                    text: {
                      type: "text",
                      label: this.$t("文字"),
                    },
                  },
                },
              },
            },
            introList: {
              type: "list",
              label: this.$t("介紹文字列表"),
              fields: {
                text: {
                  type: "text",
                  label: this.$t("文字"),
                },
              },
            },
            qrcode: {
              type: "image",
              label: "qrcode",
            },
            displaySocialMediaList: {
              type: 'switch',
              label: this.$t('前台啟用 社群資訊'),
              defaultValue: true,
            },
            socialMediaTitle: {
              type: 'text',
              label: this.$t('社群資訊 標題'),
            },
            socialMediaList: {
              type: "list",
              label: this.$t("社群資訊 list"),
              fields: {
                text: {
                  type: "text",
                  label: this.$t("名稱（後台顯示）"),
                },
                icon: {
                  type: "text",
                  label: "icon",
                },
                link: {
                  type: "text",
                  label: this.$t("連結"),
                },
              },
            },
            contactList: {
              type: "list",
              label: this.$t("聯絡資訊"),
              fields: {
                icon: {
                  type: "text",
                  label: "icon",
                },
                text: {
                  type: "text",
                  label: "text",
                },
                link: {
                  type: 'text',
                  label: this.$t("連結"),
                }
              },
            },
            copyright: {
              type: "text",
              label: "Copyright",
            },
            powerByWasa: {
              type: 'switch',
              label: this.$t('前台顯示power by WASA'),
              activeText: this.$t('顯示'),
              inactiveText: this.$t('顯示'),
              defaultValue: 1,
            },
            logo: {
              type: "image",
              label: "Logo",
            },
            enable_custom_footer: {
              type: 'switch',
              label: this.$t('啟用客製化區塊'),
              activeText: 'On',
              inactiveText: 'Off',
              defaultValue: 0,
            },
          },
        },
      },
      custom_fields: {
        custom_footer_template: {
          type: 'textarea',
          label: `${this.$t('客製化區塊')} - Code - Vue Template`
        },
        custom_footer_methods: {
          type: 'textarea',
          label: `${this.$t('客製化區塊')}  - Code - Vue Methods`
        },
        custom_footer_data: {
          type: 'json',
          label: `${this.$t('客製化區塊')} - Code - Vue Data`
        },
        custom_footer_mounted: {
          type: 'textarea',
          label: `${this.$t('客製化區塊')} - Code - Vue Mounted`
        },
        custom_footer_style: {
          type: 'textarea',
          label: `${this.$t('客製化區塊')} - Code - Style`
        },
        custom_footer_fields: {
          type: 'fields',
          label: `${this.$t('客製化區塊')} - ${this.$t('欄位')} `,
          labelInLocale: true,
        },
      },
      primary: [
        {
          type: "stateCard",
          floors: [
            {
              fields: ["footer"],
            },
          ],
        },
      ],
      state: {},
    };
  },
  mounted() {
    if (this.$config.wsmodule.system_setting?.foooter?.WsFooterType === false) {
      delete this.fields.footer.fields.type
    }
  },
  methods: {
    $_onInput($event) {
      this.state = {
        ...this.state,
        ...$event,
      }
    },
  }
}
</script>