import H_Router from '@/__stone/helpers/router'

const routes = [
  ...H_Router.getCrudTemplateRoutes({
    routes: [
      'index',
      'read',
    ],
    modelName: 'xc_milestone',
    middleware: 'auth',
    meta: {
      requiredScopes: ['xc_milestone-admin'],
    },
  }),
]
export default routes