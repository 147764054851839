
const model = {
  options: {
    id: true,
  },
  modelName: "xc_leave_day",
  label: "",
  fields: {
    xc_employee: {
      type: "belongsTo",
      label: "申請人",
      textKey: "name",
      modelName: 'xc_employee'
    },
    status: {
      type: 'select',
      label: '狀態',
      readonly: true,
      defauleValue: 1,
      items: [
        {
          text: '簽核中',
          value: 'processing'
        },
        {
          text: '已核准',
          value: 'approved'
        },
        {
          text: '不同意',
          value: 'disapproved'
        },
        {
          text: '收回',
          value: 'withdrawn'
        },
        {
          text: '取消',
          value: 'canceled'
        },
        {
          text: '結算中',
          value: 'settling'
        },
        {
          text: '已支付',
          value: 'payed'
        },
      ]
    },
    name: {
      type: 'text',
      label: '名稱',
    },
    start_date: {
      type: 'date',
      label: '請假開始日期'
    },
    days: {
      type: 'number',
      label: '請假天數'
    },
    start_at: {
      type: 'datetime',
      label: '請假開始時間 (不足一日)',
      hint: '不足一日填寫'
    },
    hours: {
      type: 'number',
      label: '時數 (不足一日)',
      hint: '不足一日填寫'
    },
    note: {
      type: 'textarea',
      label: '事由'
    },
    type: {
      type: 'select',
      label: '假別',
      defaultValue: 'annual_leave',
      items: [
        {
          text: '特休假',
          value: 'annual_leave',
        },
        {
          text: '特休延休假',
          value: 'annual_leave_deferred',
        },
        {
          text: '加班補休',
          value: 'annual_leave_from_work_overtime',
        },
        {
          text: '病假',
          value: 'sick_leave',
        },
        {
          text: '事假',
          value: 'personal_leave',
        },
        {
          text: '生理假',
          value: 'menstrual_leave',
        },
        {
          text: '喪假-8天',
          value: 'funeral_leave_a',
        },
        {
          text: '喪假-6天',
          value: 'funeral_leave_b',
        },
        {
          text: '喪假-3天',
          value: 'funeral_leave_c',
        },
        {
          text: '喪假',
          value: 'funeral_leave',
        },
        {
          text: '婚假',
          value: 'marriage_leave',
        },
        {
          text: '家庭照顧假',
          value: 'family_care_leave',
        },
        {
          text: '公傷病假',
          value: 'occupational_injury_leave',
        },
        {
          text: '陪產假',
          value: 'paternity_leave',
        },
        {
          text: '產假',
          value: 'maternity_leave',
        },
        {
          text: '流產假',
          value: 'miscarriage_leave',
        },
        {
          text: '疫苗接種假',
          value: 'vaccine_leave',
        },
        {
          text: '疫苗接種假',
          value: 'quarantine_leave',
        },
      ],
    },
    created_at: {
      type: 'date',
      label: '申請時間',
      readonly: true,
    },
    relate_files: {
      type: 'file_files',
      label: "相關檔案",
      storageTarget: 'xc_leave_day',
      pocketable: false
    },
  },
}

export default model