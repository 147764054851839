import config from '@/__config';

const model = {
  modelName: "xc_wfh",
  label: "",
  fields: {
    id: {
      type: "text",
      label: 'ID',
      readonly: true
    },
    admin: {
      type: "belongsTo",
      label: "申請人",
      textKey: "name",
      modelName: config.wsmodule.admin.admin_blur ? 'cmser' : 'admin',
    },
    status: {
      type: 'select',
      label: '狀態',
      readonly: true,
      defauleValue: 1,
      items: [
        {
          text: '簽核中',
          value: 'processing'
        },
        {
          text: '已核准',
          value: 'approved'
        },
        {
          text: '不同意',
          value: 'disapproved'
        },
      ]
    },
    start_date: {
      type: 'date',
      label: '日期'
    },
    note: {
      type: 'textarea',
      label: '備註'
    },
    created_at: {
      type: 'datetime',
      label: '申請時間',
    },
  },
}

export default model