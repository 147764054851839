<template>
  <div class="ws-state-fields">
    <div class="ws-row full-width">
      <div class="ws-col col-6">
        <WsFlexWrap>
          <WsBtn @click="$_onCreate()">{{ $t('new_field') }}</WsBtn>
          <WsStateSortDialog
            textKey="label"
            :value="_arrayFields"
            @input="$_onSortInput($event)"
          ></WsStateSortDialog>
        </WsFlexWrap>
      </div>
      <slot name="extendCols"></slot>
    </div>
    <WsCrudTable
      class="mt-10"
      :showFields="showFields"
      :fields="_readFields"
      :items="_arrayFields"
      :paginate="false"
      :showExpand="false"
      :inRowBtnRead="true"
      :inRowBtnUpdate="true"
      :inRowBtnDelete="true"
      :dialogUpdate="true"
      :expandable="false"
      @read="$_onRead($event)"
      @update="$_onUpdate($event)"
      @delete="$_onDelete($event)"
    ></WsCrudTable>
    <WsCreateDialog
      ref="createDialog"
      :label="$t('new_field')"
      :fields="_createFields"
      :postEncode="false"
      @input="$_onCreateDialogInput($event)"
      @submit="$_onCreateSubmit($event)"
    />
    <WsModelUpdateDialog
      ref="updateDialog"
      :label="$t('edit_field')"
      :fields="_updateFields"
      :postEncode="false"
      @input="$_onUpdateDialogInput($event)"
      @submit="$_onUpdateSubmit($event)"
    />
    <WsReadDialog
      ref="readDialog"
      :fields="_readFields"
      titleKey="label"
      @delete="$_onDelete($event)"
    ></WsReadDialog>
    <WsAlert
      ref="deleteAlert"
      :title="$t('sure_to_delete')"
      :description="$t('delete_cannot_be_recovered')"
      @submit="$_onDeleteSubmit($event)"
    ></WsAlert>
  </div>
</template>

<script>
import ServiceAppField from "@/__stone/service/app/field";
import SP_state from "@/__stone/spare/state";
export default {
  data() {
    return {
      state: {
        createData: {},
        updateData: {},
      },
      showFields: ["name", "label", "type"],
      fields: {
        name: {
          type: "text",
          label: this.$t("name_in_code"),
          autofocus: true,
          rules: "required",
          tooltip:
            "【注意】當採用”title”時，前台區塊會顯示hover tooltip，若不希望於前台顯示 title tooltip，建議採用“dataTitle”",
        },
        label: {
          type: "text",
          label: this.$t("name_in_view"),
          rules: "required",
        },
        type: {
          type: "select",
          label: this.$t("type"),
          items: [],
          rules: "required",
        },
        tooltip: {
          type: "text",
          label: "Tooltip",
        },
      },
      typeExtendFields: {
        placeholder: {
          type: "text",
          label: "Placeholder",
        },
        rules: {
          type: "rules",
          label: "Rules",
        },
        fields: {
          type: "fields",
          label: this.$t("fields"),
        },
        textKey: {
          type: "tags",
          label: "textKey",
          placeholder: "Tulpa Page 排序時顯示此設定欄位",
        },
        items: {
          type: "list",
          label: "Items",
          fields: {
            text: {
              label: this.$t("顯示文字"),
            },
            value: {
              label: this.$t("值(程式用)"),
            },
          },
        },
      },
      typeItems: SP_state.types,
      tableHeaders: [
        {
          text: this.$t("name_in_code"),
          value: "name",
        },
        {
          text: this.$t("name_in_view"),
          value: "label",
        },
        {
          text: this.$t("type"),
          value: "type",
        },
        {
          text: "",
          value: "actions",
        },
      ],
      focusIndex: -1,
    };
  },

  methods: {
    $_onSortInput($event) {
      this.$emit("input", ServiceAppField.arrayFieldsToFields($event));
    },
    $_onCreateDialogInput($event) {
      this.state.createData = $event;
    },
    $_onUpdateDialogInput($event) {
      this.state.updateData = $event;
    },
    $_onRead($event) {
      const _index = $event.itemIndex;
      const _item = this._arrayFields[_index];
      this.focusIndex = _index;
      this.$refs.readDialog.open(_item, $event.itemIndex);
    },
    $_onUpdate($event) {
      const _index = $event.itemIndex;
      const _item = this._arrayFields[_index];
      this.focusIndex = _index;
      setTimeout(() => {
        this.$refs.updateDialog.open(_item);
      }, 0);
    },
    $_onDelete($event) {
      this.$refs.deleteAlert.open({
        item: $event.item,
        itemIndex: $event.itemIndex,
      });
    },
    $_validateRepeat(state) {
      let status = 1;
      let message = "";
      const _name = state.name;
      for (let fieldKey in this.value) {
        if (fieldKey == _name) {
          status = 0;
          message = this.$message["name has been used."];
        }
      }
      return {
        status: status,
        message: message,
      };
    },
    $_onCreateSubmit($event) {
      const _validateRepeat = this.$_validateRepeat($event);
      if (!_validateRepeat.status) {
        this.$refs.createDialog.error(_validateRepeat.message);
        return;
      }
      const _value = this._arrayFields
        ? JSON.parse(JSON.stringify(this._arrayFields))
        : [];
      _value.push($event);
      this.$emit("input", ServiceAppField.arrayFieldsToFields(_value));
      this.$refs.createDialog.close();
    },
    $_onUpdateSubmit($event) {
      const _value = this._arrayFields
        ? JSON.parse(JSON.stringify(this._arrayFields))
        : [];
      _value[this.focusIndex] = $event;
      this.$emit("input", ServiceAppField.arrayFieldsToFields(_value));
      this.focusIndex = -1;
      this.$refs.updateDialog.close();
    },
    $_onDeleteSubmit($event) {
      const _index = $event.itemIndex;
      const _value = this._arrayFields
        ? JSON.parse(JSON.stringify(this._arrayFields))
        : [];
      _value.splice(_index, 1);
      this.focusIndex = -1;
      this.$emit("input", ServiceAppField.arrayFieldsToFields(_value));
      this.$refs.deleteAlert.close();
    },
    $_onCreate() {
      this.$refs.createDialog.open();
    },
  },

  computed: {
    _arrayFields() {
      return ServiceAppField.fieldsToArrayFields(this.value);
    },
    _readFields() {
      const _readFields = JSON.parse(JSON.stringify(this.fields));
      _readFields.type.items = this.typeItems;
      _readFields.type.defaultValue = this.typeItems[0].value;
      if (this.focusIndex >= 0) {
        const _item = this._arrayFields[this.focusIndex];
        const _type = _item && _item.type ? _item.type : null;
        if (_type) {
          const _typeItem = this.typeItems.find((e) => {
            return e.value == _type;
          });
          const _extendFields = _typeItem.extendFields;
          if (_extendFields) {
            _extendFields.forEach((_extendFieldKey) => {
              _readFields[_extendFieldKey] =
                this.typeExtendFields[_extendFieldKey];
            });
          }
        }
      }
      return _readFields;
    },
    _updateFields() {
      const _updateFields = { ...this.fields };
      _updateFields.type.items = this.typeItems;
      _updateFields.type.defaultValue = this.typeItems[0].value;
      if (this.focusIndex >= 0) {
        const _type =
          this.state.updateData && this.state.updateData.type
            ? this.state.updateData.type
            : this._arrayFields[this.focusIndex].type;
        if (_type) {
          const _typeItem = this.typeItems.find((e) => {
            return e.value == _type;
          });
          const _extendFields = _typeItem.extendFields;
          if (_extendFields) {
            _extendFields.forEach((_extendFieldKey) => {
              _updateFields[_extendFieldKey] =
                this.typeExtendFields[_extendFieldKey];
            });
          }
        }
      }
      return _updateFields;
    },
    _createFields() {
      const _createFields = { ...this.fields };
      _createFields.type.items = this.typeItems;
      _createFields.type.defaultValue = this.typeItems[0].value;
      const _type =
        this.state.createData && this.state.createData.type
          ? this.state.createData.type
          : null;
      if (_type) {
        const _typeItem = this.typeItems.find((e) => {
          return e.value == _type;
        });
        const _extendFields = _typeItem.extendFields;
        if (_extendFields) {
          _extendFields.forEach((_extendFieldKey) => {
            _createFields[_extendFieldKey] =
              this.typeExtendFields[_extendFieldKey];
          });
        }
      }
      return _createFields;
    },
  },

  props: {
    label: {
      type: String,
      default: null,
    },
    value: {
      type: Object,
      default: null,
    },
  },
};
</script>