<template>
  <div class="ws-fixed-icon-btns">
    <div
      v-for="(btn, index) in btns"
      :key="index"
      v-bind="btn"
    >
      <WsIconBtn
        v-if="btn.icon"
        :name="btn.icon"
        :size="btn.size"
        @click="btn.onClick"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    btns: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss">
.ws-fixed-icon-btns {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}
</style>