<template>
  <WsModelIndex
    ref="WsModelIndex"
    :label="label"
    :modelName="$store.state.stone_model.xc_task.modelName"
    :filterTabs="_filterTabs"
    :filterSelects="filterSelects"
    :fields="_fields"
    :showFields="_showFields"
    :params="params"
    :createData="createData"
    :readable="true"
    :updateData="updateData"
    :creatable="_creatable"
    :updatable="_updatable"
    :deletable="_deletable"
    :inRowBtnRead="true"
    :pageMode="true"
    :dialogCreate="false"
    :dialogUpdate="true"
    :customTableActions="_customTableActions"
    :titleBarCustomBtns="titleBarCustomBtns"
    :getReadUrl="getReadUrl"
    :extendOrderItems="extendOrderItems"
    :indexFields="_indexFields"
    :createFields="createFields"
    :updateFields="updateFields"
    :paramsOnCrud="paramsOnCrud"
    :fetchOnOpen="fetchOnOpen"
    order="due_date"
    @tr-request="$_onTrRequest($event)"
    @open="$_onOpen($event)"
    @done="$_onDone($event)"
    @reject="$_onReject($event)"
    @close="$_onClose($event)"
    @pending="$_onPending($event)"
    @reopen="$_onReOpen($event)"
    @takerinprogress="$_onTakerInProgress($event)"
    @takerstay="$_onTakerStay($event)"
    :actionWidth="140"
    :getUrl="getUrl"
  ></WsModelIndex>
</template>

<script>
export default {
  data() {
    return {
      extendOrderItems: [
        {
          value: "due_date",
          text: "依期限排序",
          order_by: "due_date",
          order_way: "asc",
        },
      ],
    };
  },
  methods: {
    async $_onOpen($event) {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.xc_task.open($event.item.id);
        this.$refs.WsModelIndex.updateModelData({
          ...$event.item,
          status: "4",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    async $_onSumitDone($event) {
      this.$o_o.$s._m.xc_task.done(
        $event.data.item.id,
        $event.state.finish_hour
      );
      this.$refs.WsModelIndex.updateModelData({
        ...$event.data.item,
        status: "5",
        taker_status: "0",
      });
    },
    async $_onDone($event) {
      this.$store.dispatch("app/setStateDialog", {
        fields: {
          finish_hour: {
            ...this.$store.state.stone_model.xc_task.fields.finish_hour,
            defaultValue: 1,
          },
        },
        data: {
          item: $event.item,
        },
        title: "Done Task",
        onSubmit: this.$_onSumitDone,
      });
      // try {
      //   this.$store.dispatch("app/startPageLoading");
      //   await this.$o_o.$s._m.xc_task.done($event.item.id);
      //   this.$refs.WsModelIndex.updateModelData({
      //     ...$event.item,
      //     status: "5",
      //     taker_status: "0",
      //   });
      // } catch (error) {
      //   console.error(error);
      // } finally {
      //   this.$store.dispatch("app/stopPageLoading");
      // }
    },
    async $_onTrRequest($event) {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.xc_task.tr_request($event.item.id);
        this.$refs.WsModelIndex.updateModelData({
          ...$event.item,
          status: "2",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    async $_onReject($event) {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.xc_task.reject($event.item.id);
        this.$refs.WsModelIndex.updateModelData({
          ...$event.item,
          status: "11",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    async $_onClose($event) {
      this.$o_o.$s._m.xc_task.close($event.item.id);
      this.$refs.WsModelIndex.updateModelData({
        ...$event.item,
        status: "6",
        taker_status: "0",
      });
      // try {
      //   this.$store.dispatch("app/startPageLoading");
      // } catch (error) {
      //   console.error(error);
      // } finally {
      //   this.$store.dispatch("app/stopPageLoading");
      // }
    },
    async $_onPending($event) {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.xc_task.pending($event.item.id);
        this.$refs.WsModelIndex.updateModelData({
          ...$event.item,
          status: "12",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    async $_onReOpen($event) {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.xc_task.reopen($event.item.id);
        this.$refs.WsModelIndex.updateModelData({
          ...$event.item,
          status: "4",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    async $_onTakerInProgress($event) {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.xc_task.taker_status_in_progress($event.item.id);
        this.$refs.WsModelIndex.updateModelData({
          ...$event.item,
          taker_status: "1",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    async $_onTakerStay($event) {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.xc_task.taker_status_stay($event.item.id);
        this.$refs.WsModelIndex.updateModelData({
          ...$event.item,
          taker_status: "0",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
  },
  computed: {
    _filterTabs() {
      return this.filterTabs
        ? this.filterTabs
        : this.$store.state.stone_model.xc_task.crudSetting.index.filterTabs;
    },
    _showFields() {
      return this.showFields
        ? this.showFields
        : ["id", "status", "taker", "tags", "name"];
    },
    _indexFields() {
      const _indexFields = {
        ...this.$store.state.stone_model.xc_task.fields,
      };
      _indexFields.taker = {
        ...this.$store.state.stone_model.xc_task.fields.taker,
        type: "user_label",
      };
      return _indexFields;
    },
    _customTableActions() {
      const _customTableActions = [];
      if (this.avaiableActions.includes("taker-in-progress")) {
        _customTableActions.push({
          tooltip: this.$t("In Progress"),
          icon: "icon-md-play-arrow",
          title: "開始作業",
          displayCheck: (item) => {
            if (item.taker && item.taker.id != this._currentUser.id) {
              return false;
            }
            if (item.taker_status == 0) {
              return true;
            }
          },
          emit: "takerinprogress",
        });
      }
      if (this.avaiableActions.includes("taker-stay")) {
        _customTableActions.push({
          tooltip: this.$t("Stay"),
          icon: "icon-md-stop",
          title: "準備",
          displayCheck: (item) => {
            if (item.taker && item.taker.id != this._currentUser.id) {
              return false;
            }
            if (item.taker_status == 1) {
              return true;
            }
          },
          emit: "takerstay",
        });
      }
      if (this.avaiableActions.includes("tr-request")) {
        _customTableActions.push({
          emit: "tr-request",
          tooltip: this.$t("發出 TR"),
          text: "TR",
          title: "TR",
          displayCheck: (item) => {
            if (!["edit", "admin"].includes(item.admin_scope)) {
              return;
            }
            if ([1, 4, 12].includes(parseInt(item.status)) && item.taker) {
              return true;
            } else {
              return false;
            }
          },
        });
      }

      if (this.avaiableActions.includes("tr")) {
        _customTableActions.push({
          tooltip: this.$t("開始 TR"),
          text: "TR",
          title: "TR",
          displayCheck: (item) => {
            if (
              item.status == 2 &&
              item.taker &&
              item.taker.id == this._currentUser.id
            ) {
              return true;
            } else {
              return false;
            }
          },
          getUrl: (item) => {
            return `/xc_task/${item.id}/tr`;
          },
        });
      }
      if (this.avaiableActions.includes("open")) {
        _customTableActions.push({
          emit: "open",
          tooltip: this.$t("Open"),
          text: "Go",
          displayCheck: (item) => {
            if (
              [1, 3, 12].includes(parseInt(item.status)) &&
              item.taker &&
              item.creator &&
              item.creator.id == this._currentUser.id
            ) {
              return true;
            } else {
              return false;
            }
          },
        });
      }
      if (this.avaiableActions.includes("done")) {
        _customTableActions.push({
          tooltip: this.$t("Done"),
          icon: "icon-ws-outline-check",
          title: "Done",
          emit: "done",
          displayCheck: (item) => {
            if (
              [4, 11].includes(parseInt(item.status)) &&
              item.taker &&
              item.taker.id == this._currentUser.id
            ) {
              return true;
            } else {
              return false;
            }
          },
          // getUrl: (item) => {
          //   return `/xc_task/${item.id}/done`;
          // },
        });
      }
      if (this.avaiableActions.includes("reject")) {
        _customTableActions.push({
          tooltip: this.$t("Reject"),
          icon: "icon-md-block",
          title: "Reject",
          displayCheck: (item) => {
            if (!["edit", "admin"].includes(item.admin_scope)) {
              return;
            }
            if (item.status == 5) {
              return true;
            }
          },
          emit: "reject",
        });
      }
      if (this.avaiableActions.includes("close")) {
        _customTableActions.push({
          tooltip: this.$t("Close"),
          icon: "icon-md-archive",
          title: "Close",
          displayCheck: (item) => {
            if (!["edit", "admin"].includes(item.admin_scope)) {
              return;
            }
            if ([1, 2, 3, 4, 5, 11, 12].includes(parseInt(item.status))) {
              return true;
            }
          },
          emit: "close",
        });
      }
      if (this.avaiableActions.includes("pending")) {
        _customTableActions.push({
          tooltip: this.$t("Pending"),
          icon: "icon-md-pause-circle-outline",
          title: "Pending",
          displayCheck: (item) => {
            if (!["edit", "admin"].includes(item.admin_scope)) {
              return;
            }
            if ([3, 4, 5, 11].includes(parseInt(item.status))) {
              return true;
            }
          },
          emit: "pending",
        });
      }
      if (this.avaiableActions.includes("reopen")) {
        _customTableActions.push({
          tooltip: this.$t("ReOpen"),
          text: "Go",
          title: "Go",
          displayCheck: (item) => {
            if (!["edit", "admin"].includes(item.admin_scope)) {
              return;
            }
            if ([6].includes(parseInt(item.status))) {
              return true;
            }
          },
          emit: "reopen",
        });
      }

      return _customTableActions;
    },
    _creatable() {
      return this.creatable ? this.creatable : false;
    },
    _updatable() {
      return this.updatable ? this.updatable : false;
    },
    _deletable() {
      return this.deletable ? this.deletable : false;
    },
    _fields() {
      return this.fields
        ? this.fields
        : this.$store.state.stone_model.xc_task.fields;
    },
    _id() {
      return this.$route.params.id;
    },
    _currentUser() {
      return this.$store.state.auth.currentUser;
    },
  },
  props: {
    label: {},
    params: {},
    titleBarCustomBtns: {},
    filterTabs: {},
    fields: {},
    showFields: {},
    getReadUrl: {},
    creatable: {},
    updatable: {},
    deletable: {},
    createData: {},
    updateData: {},
    createFields: {},
    updateFields: {},
    paramsOnCrud: {},
    avaiableActions: {
      type: Array,
      default() {
        return [
          "tr-request",
          "tr",
          "open",
          "done",
          "reject",
          "close",
          "pending",
          "reopen",
          "taker-in-progress",
          "taker-stay",
        ];
      },
    },
    filterSelects: {},
    fetchOnOpen: {
      type: Boolean,
      default: false,
    },
    getUrl: {
      type: String,
      default: "/xc_task",
    },
  },
};
</script>

<style></style>