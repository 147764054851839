<template>
  <div>
    <div
      v-for="(valueItem, valueItemIndex) in value"
      :key="valueItemIndex"
    >
      <WsFlex class="my-8">
        <WsState
          class="mt-0"
          type="time"
          placeholder="開始時間"
          :value="valueItem[0]"
          @input="$_onInputTime($event, valueItemIndex, 0)"
        />
        <WsState
          class="mt-0 ml-8"
          type="time"
          placeholder="結束時間"
          :value="valueItem[1]"
          @input="$_onInputTime($event, valueItemIndex, 1)"
        />
      </WsFlex>
    </div>
    <WsBtn @click="$_add()">新增時段</WsBtn>
  </div>
</template>

<script>
export default {
  methods: {
    $_onInputTime($event, valueItemIndex, index) {
      const _value = [...this.value];
      _value[valueItemIndex][index] = $event;
      this.$emit("input", _value);
    },
    $_add() {
      const _value = [...this.value];
      _value.push(["00:00:00", "01:00:00"]);
      this.$emit("input", _value);
    },
  },
  props: {
    value: {
      default() {
        return [];
      },
    },
  },
};
</script>

<style>
</style>