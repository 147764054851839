const model = {
  options: {
    id: true,
    timestamp: true,
  },
  modelName: 'xc_employee_salary_adjustment',
  label: '調薪作業項目',
  fields: {
    xc_employee: {
      type: 'belongsTo',
      label: '員工',
      modelName: 'xc_employee',
    },
    basic_salary: {
      type: 'currency',
      label: '本俸',
    },
    meal_expenses: {
      type: 'currency',
      label: '伙食費',
    },
    work_allowance: {
      type: 'currency',
      label: '工作加給',
    },
    transportation_allowance: {
      type: 'currency',
      label: '交通津貼',
    },
    approved_salary: {
      type: 'currency',
      label: '核定薪資',
    }
  },
  crudSetting: {
  }
}

export default model