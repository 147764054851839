export default {
  getXcProjectMenu(data, res) {
    res.splice(1, 0, {
      title: "專案規格",
      link: `/xc_project/${data.id}/xc_project_spec`,
    });
    return res
  },
  getXcProjectExtendRoutes(data, res) {
    let _routes = []
    if (res) {
      _routes = res
    }
    _routes.push(...[
      {
        path: 'xc_project_spec',
        name: 'XcProjectSpec',
        component: () => import('@/__modules/xc_project_spec/extend_modules/xc_project/views/XcProjectSpec/Index.vue'),
        meta: {
          middleware: 'auth',
        },
      },
    ])
    return _routes
  }
}