<template>
  <WsModelFetchShow
    ref="WsModelFetchShow"
    modelName="xc_task"
    :id="id"
    @fetched="$_onFetched($event)"
    @change="$_onFetched($event)"
  >
    <WsFlex
      v-if="xc_task"
      class="sm-flex-col"
    >
      <div class="flex-1 width-full">
        <XcTaskReadInfo
          :hideExtendBtns="hideExtendBtns"
          :xc_task="xc_task"
          @tr-request="$_onTrRequest()"
          @open="$_onOpen()"
          @reject="$_onReject()"
          @close="$_onClose()"
          @reopen="$_onReOpen()"
          @pending="$_onPending()"
          @takerinprogress="$_onTakerInProgress()"
          @takerstay="$_onTakerStay()"
        ></XcTaskReadInfo>
        <WsRepliesSection
          class="mt-40 pa-20"
          modelName="xc_task_reply"
          :params="_xcTaskReplyParams"
          :extendPostData="_extendPostData"
          @scrollToHash="$_scrollToHash"
        ></WsRepliesSection>
      </div>
      <div class="xc-task-read__sideinfo">
        <slot name="sideInfoPre"></slot>
        <WsInfoForm
          :fields="_fields"
          :value="xc_task"
        ></WsInfoForm>
        <slot name="sideInfoPreMiddle"></slot>
        <WsCrud
          class="mt-40"
          modelName="xc_task_record"
          :label="$store.state.stone_model.xc_task_record.label"
          :fields="$store.state.stone_model.xc_task_record.fields"
          :liveSearching="false"
          :showFields="['created_at', 'action', 'admin','hour']"
          :infiniteScroll="false"
          :hasFilter="false"
          :updatable="false"
          :deletable="false"
          :creatable="false"
          :hasPaginate="false"
          :extendParams="_xcTaskRecordParams"
        ></WsCrud>
      </div>
    </WsFlex>
  </WsModelFetchShow>
</template>

<script>
export default {
  metaInfo() {
    let title = "Task";
    if (this.xc_task) {
      title += ` | ${this.xc_task.id} | ${this.xc_task.name}`;
    }
    return {
      title: title,
    };
  },
  data() {
    return {
      xc_task: undefined,
    };
  },
  computed: {
    _xcTaskRecordParams() {
      return {
        xc_task: this.id,
      };
    },
    _fields() {
      if (this.fields) {
        return this.fields;
      }
      const _fields = {
        taker_status: this.$store.state.stone_model.xc_task.fields.taker_status,
        status: this.$store.state.stone_model.xc_task.fields.status,
        taker: {
          ...this.$store.state.stone_model.xc_task.fields.taker,
          type: "user_label",
        },
        hour: this.$store.state.stone_model.xc_task.fields.hour,
        finish_hour: this.$store.state.stone_model.xc_task.fields.finish_hour,
        id: this.$store.state.stone_model.xc_task.fields.id,
        xc_project: this.$store.state.stone_model.xc_task.fields.xc_project,
        xc_task_template:
          this.$store.state.stone_model.xc_task.fields.xc_task_template,
        due_date: this.$store.state.stone_model.xc_task.fields.due_date,
        relate_xc_tasks:
          this.$store.state.stone_model.xc_task.fields.relate_xc_tasks,
      };
      return _fields;
    },
    _xcTaskReplyParams() {
      return {
        xc_task: this.id,
      };
    },
    _extendPostData() {
      return {
        xc_task: this.id,
      };
    },
  },
  methods: {
    async $_onTrRequest() {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.xc_task.tr_request(this.xc_task.id);
        this.$refs.WsModelFetchShow.updateModelData({
          status: "2",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    async $_onOpen() {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.xc_task.open(this.xc_task.id);
        this.$refs.WsModelFetchShow.updateModelData({
          status: "4",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    async $_onReOpen() {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.xc_task.reopen(this.xc_task.id);
        this.$refs.WsModelFetchShow.updateModelData({
          status: "4",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    async $_onPending() {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.xc_task.pending(this.xc_task.id);
        this.$refs.WsModelFetchShow.updateModelData({
          status: "12",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    async $_onTakerInProgress() {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.xc_task.taker_status_in_progress(this.xc_task.id);
        this.$refs.WsModelFetchShow.updateModelData({
          taker_status: "1",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    async $_onTakerStay() {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.xc_task.taker_status_stay(this.xc_task.id);
        this.$refs.WsModelFetchShow.updateModelData({
          taker_status: "0",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    async $_onReject() {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.xc_task.reject(this.xc_task.id);
        this.$refs.WsModelFetchShow.updateModelData({
          status: "11",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    async $_onClose() {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.xc_task.close(this.xc_task.id);
        this.$refs.WsModelFetchShow.updateModelData({
          status: "6",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    $_onFetched($event) {
      this.xc_task = $event;
    },
    $_scrollToHash() {
      if (this.$route.hash) {
        this.$nextTick(() => {
          setTimeout(() => {
            $_scrollTo(this.$route.hash);
            setTimeout(() => {
              let rect = document.querySelectorAll(this.$route.hash);
              let top;
              if (rect && rect[0]) {
                top = rect[0].getBoundingClientRect().top - 50;
              }
              if (Math.abs(top) > 10) {
                $_scrollTo(this.$route.hash);
              }
            }, 1000);
          }, 100);
          function $_scrollTo(hash) {
            let rect = document.querySelectorAll(hash);
            if (rect && rect[0]) {
              let top =
                rect[0].getBoundingClientRect().top - 50 + window.scrollY;
              window.scrollTo({
                top: top,
                behavior: "smooth",
              });
            }
          }
        });
      }
    },
  },
  props: {
    hideExtendBtns: {},
    id: {},
    xc_project: {},
    fields: {},
  },
};
</script>

<style lang="scss">
.xc-task-read__sideinfo {
  width: 240px;
  margin-left: 40px;
}
</style>