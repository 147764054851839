export default {
  options: {
    id: true,
    timestamp: true,
  },
  modelName: "xc_rfq_note",
  label: "RFQ 補充筆記",
  fields: {
    content: {
      type: 'editor',
      label: '內容',
    },
  },
  crudSetting: {
    index: {
      pageMode: true,
      showFields: [
        'created_at',
        'content',
      ],
    },
    create: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'content',
              ],
            },
          ],
        },
      ],
    },
    update: {
      submitToIndex: true,
      titleKey: 'created_at',
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                "content",
              ],
            },
          ],
        },
      ],
    },
    read: {
      leftFields: [
        "content",
      ],
      rightFields: [
        "created_at",
      ],
      titleKey: 'created_at',
    },
  }
}