import H_Router from '@/__stone/helpers/router'

const routes = [
  ...H_Router.getCrudTemplateRoutes({
    routes: ['read'],
    modelName: 'xc_project_spec',
    middleware: 'auth',
    meta: {
    },
  })
]
export default routes