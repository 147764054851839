const model = {
  options: {
    id: true,
    timestamp: true,
  },
  modelName: 'xc_employee_salary_adjustjob',
  label: '調薪作業',
  fields: {
    source: {
      type: 'radio',
      label: '調整目標',
      defaultValue: 'xc_employee_department',
      items: [
        {
          label: '指定部門',
          value: 'xc_employee_department'
        },
        {
          label: '指定員工',
          value: 'xc_employees'
        },
      ]
    },
    xc_employee_department: {
      type: 'belongsTo',
      label: '部門',
      modelName: 'xc_employee_department',
    },
    effective_at: {
      type: 'date',
      label: '生效日期',
      rules: 'required'
    },
    confirm_at: {
      type: 'date',
      label: '確認日期',
    },
    review_confirm_at: {
      type: 'date',
      label: '覆核確認日期',
    },
    xc_employee: {
      type: 'belongsTo',
      label: '負責人員',
      modelName: 'xc_employee',
    },
    review_xc_employee: {
      type: 'belongsTo',
      label: '覆核人員',
      modelName: 'xc_employee',
      rules: 'required'
    },
    xc_employees: {
      type: 'belongsToMany',
      label: '員工',
      modelName: 'xc_employee',
    },
    status: {
      type: 'select',
      label: '狀態',
      readOnly: true,
      items: [
        {
          text: '已執行',
          value: "executed"
        },
        {
          text: '已覆核',
          value: "review_confirmed"
        },
        {
          text: '等待覆核',
          value: "confirmed"
        },
        {
          text: '等待確認',
          value: "waiting"
        }
      ]
    }
  },
  crudSetting: {
  }
}

export default model