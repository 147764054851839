<template>
  <WsMain>
    <WsCrud
      ref="WsCrud"
      :modelName="$store.state.stone_model.xc_wfh.modelName"
      label="一日宅宅"
      :fields="_fields"
      :customTableActions="_customTableActions"
      :showFields="showFields"
      @status-ok="$_onStatusOk($event)"
      @status-no="$_onStatusNo($event)"
    ></WsCrud>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      showFields: ["admin", "start_date", "status"],
    };
  },
  methods: {
    async $_onStatusOk($event) {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.xc_wfh.approve($event.item.id);
        this.$refs.WsCrud.updateModelData({
          ...$event.item,
          status: "approved",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    async $_onStatusNo($event) {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.xc_wfh.disapprove($event.item.id);
        this.$refs.WsCrud.updateModelData({
          ...$event.item,
          status: "disapproved",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
  },
  computed: {
    _customTableActions() {
      return [
        {
          emit: "status-ok",
          text: "OK",
          displayCheck: (item) => {
            return item.status == "processing" || item.status == "disapproved";
          },
        },
        {
          emit: "status-no",
          text: "No",
          displayCheck: (item) => {
            return item.status == "processing" || item.status == "approved";
          },
        },
      ];
    },
    _fields() {
      return this.$o_o.$h.model.getFieldsByKeys(
        this.$store.state.stone_model.xc_wfh.fields,
        ["id", "status", "admin", "start_date", "note"]
      );
    },
  },
};
</script>