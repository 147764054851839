const model = {
  options: {
    id: true,
    timestamp: true,
  },
  modelName: 'xc_employee_salary_payjob',
  label: '發薪作業',
  fields: {
    start_at: {
      type: 'date',
      label: '核算開始時間'
    },
    end_at: {
      type: 'date',
      label: '核算結束時間'
    },
    pay_at: {
      type: 'date',
      label: '發薪時間'
    },
    confirm_at: {
      type: 'datetime',
      label: '確認時間'
    },
    review_confirm_at: {
      type: 'datetime',
      label: '覆核確認時間'
    },
    xc_employees: {
      type: "belongsToMany",
      label: "員工",
      modelName: 'xc_employee'
    },
    xc_employee: {
      type: "belongsTo",
      label: "負責人員",
      modelName: 'xc_employee'
    },
    review_xc_employee: {
      type: "belongsTo",
      label: "覆核人員",
      modelName: 'xc_employee'
    },
    status: {
      type: 'select',
      label: '狀態',
      readOnly: true,
      items: [
        {
          text: '已發薪',
          value: "payed"
        },
        {
          text: '已覆核',
          value: "review_confirmed"
        },
        {
          text: '等待覆核',
          value: "confirmed"
        },
        {
          text: '等待確認',
          value: "waiting"
        }
      ]
    }
  },
  crudSetting: {
  }
}

export default model