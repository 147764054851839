const model = {
  options: {
    id: true,
    // timestamp: true,
  },
  modelName: "xc_customer_interact_record",
  label: "客戶互動紀錄",
  fields: {
    interact_at: {
      type: 'datetime',
      label: '互動時間',
    },
    des: {
      type: "editor",
      label: "描述",
    },
    // xc_customer: {
    //   type: 'belongsTo',
    //   label: '客戶',
    //   textKey: "name",
    //   modelName: 'xc_customer'
    // }
  }
}

export default model