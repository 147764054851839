const model = {
  fields: {
    xc_customer: {
      type: 'belongsTo',
      label: '客戶',
      textKey: 'name',
      relationPopup: true,
      modelName: 'xc_customer'
    },
  },
  crudSetting: {
    index: {
      showFields: [
        'xc_customer'
      ]
    },
    create: {
      secondary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                "xc_customer",
              ],
            },
          ],
        },
      ],
    },
    update: {
      titleKey: 'name',
      secondary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                "xc_customer",
              ],
            },
          ],
        },
      ],
    },
    read: {
      rightFields: [
        "xc_customer",
      ],
    }
  }
}
export default model