<template>
  <div>
    <XcTaskIndex
      :showFields="showFields"
      :fields="_fields"
      :params="_params"
      :filterTabs="_filterTabs"
      :titleBarCustomBtns="_titleBarCustomBtns"
      :createData="_createData"
      :updateData="_updateData"
      :creatable="_creatable"
      :updatable="_updatable"
      :deletable="_deletable"
      :createFields="_createFields"
      :updateFields="_updateFields"
      :paramsOnCrud="_paramsOnCrud"
      fetchOnOpen
    ></XcTaskIndex>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: `Task | ${this.xc_project.name}`,
    };
  },
  data() {
    return {
      showFields: [
        "id",
        "status",
        "start_at",
        "taker",
        "tags",
        "name",
        "due_date",
      ],
    };
  },
  methods: {},
  computed: {
    _paramsOnCrud() {
      return {
        xc_project: this.xc_project?.id,
      };
    },
    _titleBarCustomBtns() {
      const _titleBarCustomBtns = [];
      if (
        this.$o_o.$h._m.xc_project.hasScope(
          ["edit", "admin"],
          this.xc_project.admin_scope
        )
      ) {
        _titleBarCustomBtns.push({
          label: "排程模式",
          to: `/xc_project/${this.xc_project.id}/xc_task/scheduling`,
        });
      }
      return _titleBarCustomBtns;
    },
    _createFields() {
      if (!this.xc_project) {
        return {};
      }
      const _createFields = {
        ...this.$store.state.stone_model.xc_task.fields,
        taker: {
          ...this.$store.state.stone_model.xc_task.fields.taker,
          fetchUrl: `/xc_project/${this.xc_project.id}/member`,
        },
      };
      delete _createFields.xc_project;
      return _createFields;
    },
    _updateFields() {
      if (!this.xc_project) {
        return {};
      }
      const _updateFields = {
        name: this.$store.state.stone_model.xc_task.fields.name,
        taker: {
          ...this.$store.state.stone_model.xc_task.fields.taker,
          fetchUrl: `/xc_project/${this.xc_project.id}/member`,
        },
        content: this.$store.state.stone_model.xc_task.fields.content,
        tags: this.$store.state.stone_model.xc_task.fields.tags,
        due_date: this.$store.state.stone_model.xc_task.fields.due_date,
      };
      return _updateFields;
    },
    _createData() {
      return {
        xc_project: this._id,
      };
    },
    _updateData() {
      return {
        xc_project: this._id,
      };
    },
    _fields() {
      const _fields = { ...this.$store.state.stone_model.xc_task.fields };
      delete _fields.xc_project;
      return _fields;
    },
    _id() {
      return this.$route.params.id;
    },
    _params() {
      return {
        xc_project: this._id,
      };
    },
    _creatable() {
      return this.$o_o.$h._m.xc_project.hasScope(
        ["edit", "admin"],
        this.xc_project.admin_scope
      );
    },
    _updatable() {
      return this.$o_o.$h._m.xc_project.hasScope(
        ["edit", "admin"],
        this.xc_project.admin_scope
      );
    },
    _deletable() {
      return this.$o_o.$h._m.xc_project.hasScope(
        ["edit", "admin"],
        this.xc_project.admin_scope
      );
    },
    _filterTabs() {
      return [
        {
          text: "Open",
          value: 4,
          params: {
            status: 4,
          },
        },
        {
          text: "Stay",
          value: 1,
          params: {
            status: 1,
          },
        },
        {
          text: "TR",
          value: 2,
          params: {
            status: 2,
          },
        },
        {
          text: "Ready",
          value: 3,
          params: {
            status: 3,
          },
        },
        {
          text: "Done",
          value: 5,
          params: {
            status: 5,
          },
        },
        {
          text: "Done-Reject",
          value: 11,
          params: {
            status: 11,
          },
        },
        {
          text: "Pending",
          value: 12,
          params: {
            status: 12,
          },
        },
        {
          text: "Close",
          value: 6,
          params: {
            status: 6,
          },
        },
        {
          text: "全部",
          value: "all",
          params: {},
        },
      ];
    },
  },
  props: {
    xc_project: {},
  },
};
</script>

<style></style>