import config from '@/__config';

let menu = {
  xc_project: {
    icon: 'icon-md-videogame-asset',
    title: '專案',
    link: '/xc_project',
    scopes: ['xc_project-admin'],
  },
}

if (config.wsmodule.xc_project.my_xc_project) {
  menu.my_xc_project = {
    icon: 'icon-md-videogame-asset',
    title: '我的 專案',
    link: '/xc_project',
  }
}

export default menu