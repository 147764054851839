const model = {
  options: {
    id: true,
    timestamp: true,
  },
  modelName: 'xc_employee_salary_slip',
  label: '薪資條',
  fields: {
    start_at: {
      type: 'datetime',
      lable: '核算開始時間'
    },
    end_at: {
      type: 'datetime',
      lable: '核算結束時間'
    },
    pay_at: {
      type: 'datetime',
      lable: '發薪時間'
    },
    confirm_at: {
      type: 'datetime',
      lable: '確認時間'
    },
    review_confirm_at: {
      type: 'datetime',
      lable: '覆核確認時間'
    },
    xc_employee: {
      type: "belongsTo",
      label: "員工",
      modelName: 'xc_employee'
    },
    approved_salary: {
      type: 'custom',
      label: `核定薪資(含交津)`,
    },
    approved_salary_without_transportation: {
      type: 'custom',
      label: `核定薪資(不含交津)`,
    },
    basic_salary: {
      type: 'currency',
      label: '本俸',
    },
    meal_expenses: {
      type: 'currency',
      label: '伙食費',
    },
    work_allowance: {
      type: 'currency',
      label: '工作加給',
    },
    transportation_allowance: {
      type: 'currency',
      label: '交通津貼',
    },
    allowance: {
      type: 'currency',
      label: '津貼',
    },
    supervisor_allowance: {
      type: 'currency',
      label: '主管加給',
    },
    lunar_new_year_bonus: {
      type: 'currency',
      label: '春節年終獎金',
    },
    performance_bonus: {
      type: 'currency',
      label: '績效獎金',
    },
    welfare_fund: {
      type: 'currency',
      label: '福利金',
    },
    retirement_employee_contribution: {
      type: 'currency',
      label: '退休金-勞工自願提撥',
    },
    retirement_government_contribution: {
      type: 'currency',
      label: '退休金-公提勞退專戶',
    },
    severance_reserve_fund: {
      type: 'currency',
      label: '退職準備金',
    },
    personal_leave_deduction: {
      type: 'currency',
      label: '事假扣款',
    },
    personal_leave_deduction_return: {
      type: 'currency',
      label: '事假扣款補回',
    },
    sick_leave_deduction: {
      type: 'currency',
      label: '病假扣款',
    },
    sick_leave_deduction_return: {
      type: 'currency',
      label: '病假扣款補回',
    },
    annual_leave_convert: {
      type: 'currency',
      label: '特休逾期未休折算'
    },
    total_salary: {
      type: 'currency',
      label: '實發薪資金額',
    },
    remark: {
      type: 'text',
      label: '備註',
    }
  },
  crudSetting: {
  }
}

export default model