<template>
  <WsMain class="off-day-remain-hours">
    <WsStateForm
      v-model="filter.state"
      :fields="filter.fields"
      @input="$_input"
      class="mb-10"
    />
    <WsActionApi
      v-if="xc_employee_id"
      :fetchUrl="`xc_employee/${xc_employee_id}/overview/xc_leave_day`"
      v-slot="{ res, isFetching }"
    >
      <WsLoading v-if="isFetching" />
      <WsBoardCard001
        v-else
        flexDirection="row"
        v-bind="$_getCardProps(res)"
      />
    </WsActionApi>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      xc_employee_id: null,
      filter: {
        state: {},
        fields: {
          xc_employee: {
            type: "belongsTo",
            label: "申請人",
            textKey: "name",
            modelName: "xc_employee",
          },
        },
      },
    };
  },
  methods: {
    $_getCardProps(res) {
      return {
        items: [
          {
            label: "可用休假餘額",
            items: Object.values(res).map((item) => ({
              label: item.name,
              value: `${item.total_days_and_hours_days}天 ${item.total_days_and_hours_hours}小時`,
            })),
          },
          {
            label: "已用休假餘額",
            items: Object.values(res).map((item) => ({
              label: item.name,
              value: `${item.used_days_and_hours_days}天 ${item.used_days_and_hours_hours}小時`,
            })),
          },
        ],
      };
    },
    $_input($event) {
      this.xc_employee_id = $event?.xc_employee?.id || null;
    },
  },
};
</script>
