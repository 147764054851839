import config from '@/__config';

const menu = {
  system_setting: {
    icon: 'icon-md-settings',
    title: '系統設定',
    menu: [
      {
        title: 'SMTP',
        link: '/system_setting/smtp',
        scopes: ['system_setting_smtp-admin'],
      },
      {
        title: 'SMTP 測試',
        link: '/system_setting/smtp_mailtest',
        scopes: ['system_setting_smtp-admin'],
      },
      {
        title: 'CMS 系統設定',
        link: '/system_setting/cms',
        scopes: ['system_setting_cms-admin'],
      },
      {
        title: 'CMS 通知設定',
        link: '/system_setting/cms_notification',
        scopes: ['system_setting_cms-admin'],
      },
      {
        title: '通用設定',
        link: '/system_setting/general',
        scopes: ['system_setting_general-admin'],
      },
    ],
    scopes: [
      'system_setting_smtp-admin',
      'system_setting_cms-admin',
      'system_setting_general-admin',
    ],
  },
};

if (config.wsmodule.system_setting.website_content) {
  menu.website_content = {
    icon: 'icon-md-view-compact',
    title: '網站內容管理',
    menu: [
      {
        title: 'Header',
        link: '/system_setting/web/header',
        scopes: ['system_setting_web_header-admin'],
      },
      {
        title: 'Footer',
        link: '/system_setting/web/footer',
        scopes: ['system_setting_web_footer-admin'],
      },
      {
        title: '樣式相關',
        link: '/system_setting/web/config',
        scopes: ['system_setting_web_config-admin'],
      },
      {
        title: '系統資源',
        link: '/system_setting/resource',
        scopes: ['system_setting_resource-admin'],
      },
      {
        title: '網頁設定',
        link: '/system_setting/web/other',
        scopes: ['system_setting_web-admin'],
      },
    ],
  }
}

export default menu