<template>
  <div>
    <WsCreate
      ref="WsCreate"
      :modelName="$store.state.stone_model.xc_employee_salary_payjob.modelName"
      :label="$store.state.stone_model.xc_employee_salary_payjob.label"
      :fields="$store.state.stone_model.xc_employee_salary_payjob.fields"
      :primary="_primary"
      v-model="state"
      @complete="$_onComplete($event)"
      :emitComplete="true"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      state: {},
    };
  },
  methods: {
    $_onComplete($event) {
      this.$router.replace(
        `/xc_employee_salary_payjob/${$event.data.data.id}/slips/update`
      );
    },
  },
  computed: {
    _primary() {
      const _primary = [
        {
          type: "stateCard",
          floors: [
            {
              fields: [
                "review_xc_employee",
                "start_at",
                "end_at",
                "xc_employees",
              ],
            },
          ],
        },
      ];
      return _primary;
    },
  },
};
</script>