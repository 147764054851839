<template>
  <WsModelIndex
    ref="WsModelIndex"
    label="新聘作業"
    :modelName="$store.state.stone_model.xc_employee_hire.modelName"
    urlModelName="xc_employee_hire_new"
    :fields="$store.state.stone_model.xc_employee_hire.fields"
    :showFields="$store.state.stone_model.xc_employee_hire.crudSetting.index.showFields"
    :creatable="false"
    :updatable="false"
    :readable="true"
    :pageMode="true"
    @item-cancel="$_onCancel($event)"
    @item-withdraw="$_onWithdraw($event)"
    :actionWidth="140"
    :params="params"
    :customTableActions="customTableActions"
  ></WsModelIndex>
</template>

<script>
export default {
  data() {
    return {
      params: {
        xc_employee_status: "working_new",
      },
      customTableActions: [
        // {
        //   text: "取消",
        //   emit: "item-cancel",
        //   displayCheck(item) {
        //     return item.status == "approved";
        //   },
        // },
        {
          text: "收回",
          emit: "item-withdraw",
          displayCheck(item) {
            return item.status == "processing";
          },
        },
      ],
    };
  },
  computed: {
    _paramsOnCrud() {
      return {
        xc_employee_status: "working_new",
      };
    },
  },
  methods: {
    async $_onCancel($event) {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.xc_employee_hire.cancel($event.item.id);
        this.$refs.WsModelIndex.updateModelData({
          ...$event.item,
          status: "canceled",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    async $_onWithdraw($event) {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.xc_employee_hire.withdraw($event.item.id);
        this.$refs.WsModelIndex.updateModelData({
          ...$event.item,
          status: "withdrawn",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
  },
};
</script>

<style>
</style>