const routes = [
  {
    path: '/my/wfh',
    name: 'my_wfh',
    component: () => import('@/__modules/xc_wfh/views/MyWfh/Index.vue'),
    meta: {
      middleware: 'auth'
    }
  },
  {
    path: '/wfh',
    name: 'wfh',
    component: () => import('@/__modules/xc_wfh/views/Wfh/Index.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['xc_wfh-admin'],
    }
  },
]

export default routes