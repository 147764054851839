export default {
  xc_employee: {
    icon: 'icon-md-people',
    title: '人力資源',
    menu: [
      {
        title: '員工',
        link: '/xc_employee'
      },
      {
        title: '工資管理',
        link: '/xc_employee_salary'
      },
      {
        title: '聘用作業',
        link: '/xc_employee_hire_change'
      },
      {
        title: '部門',
        link: '/xc_employee_department'
      },
      {
        title: '雇用別',
        link: '/xc_employee_type'
      },
      {
        title: '工作時間設定',
        link: '/xc_employee_working_schedule_setting'
      },
      {
        title: '工資異動紀錄',
        link: '/xc_employee_salary_update_record'
      },
    ],
    scopes: ['xc_employee-admin'],
  },
  xc_employee_my: {
    icon: 'icon-md-people',
    title: '我的 人事資料',
    link: '/myemployee'
  },
  xc_employee_salary: {
    icon: 'icon-md-attach-money',
    title: '財務相關',
    menu: [
      {
        title: '職位別+薪資對照',
        link: '/xc_employee_position_type'
      },
      {
        title: '工資異動紀錄',
        link: '/xc_employee_salary_update_record'
      },
      {
        title: '調薪作業',
        link: '/xc_employee_salary_adjustjob'
      },
      {
        title: '調薪作業覆核',
        link: '/xc_employee_salary_adjustjob_my_review'
      },
      {
        title: '發薪作業',
        link: '/xc_employee_salary_payjob'
      },
      {
        title: '查詢薪資單',
        link: '/xc_employee_salary_slip'
      },
      {
        title: '發薪作業覆核',
        link: '/xc_employee_salary_payjob_my_review'
      },
      {
        title: '薪轉帳戶',
        link: '/xc_employee_salary_transfer_account'
      },
    ],
    scopes: ['xc_employee-admin'],
  },
  // my_xc_employee_new: {
  //   icon: 'icon-md-people',
  //   title: '我的新聘作業',
  //   link: '/xc_employee_hire_new_my_manage'
  // },
  my_xc_employee_hire_renew: {
    icon: 'icon-md-people',
    title: '我的聘用作業',
    link: '/xc_employee_hire_change_my_manage',
    scopes: ['xc_employee-admin'],
  }
}