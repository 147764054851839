const model = {
  options: {
    id: true,
    timestamp: true,
  },
  modelName: 'xc_employee_type',
  label: '雇用別',
  fields: {
    name: {
      type: 'text',
      label: '名稱',
      autofocus: true,
      rules: 'required',
    },
  },
  crudSetting: {
    index: {
      showFields: [
        'name',
      ],
      inRowBtnRead: true,
    },
    read: {
      titleKey: 'name',
      cmsLog: true,
      leftFields: [
        'name',
      ],
      rightFields: [
      ],
    },
    create: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'name',
              ],
            },
          ],
        },
      ],
      secondary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
              ]
            }
          ]
        }
      ]
    },
    update: {
      titleKey: 'name',
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'name',
              ],
            },
          ],
        },
      ],
      secondary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
              ]
            }
          ]
        }
      ]
    }
  }
}

export default model