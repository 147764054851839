<template>
  <div>
    <WsModelIndex
      ref="WsModelIndex"
      label="國定補班"
      :modelName="$store.state.stone_model.system_event.modelName"
      :fields="_fields"
      :showFields="showFields"
      :creatable="true"
      :updatable="true"
      :deletable="true"
      :readable="true"
      :dialogRead="true"
      :pageMode="false"
      :paramsOnCrud="paramsOnCrud"
    ></WsModelIndex>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "國定補班",
    };
  },

  data() {
    return {
      showFields: ["start_date", "name"],
      paramsOnCrud: {
        type: "official_holiday_make_up",
        days: 1,
      },
    };
  },
  computed: {
    _fields() {
      return this.$o_o.$h.model.getFieldsByKeys(
        this.$store.state.stone_model.system_event.fields,
        ["id", "name", "start_date", "note"]
      );
    },
  },
};
</script>