import config from '@/__config';
import i18n from '@/i18n';
import moment from 'moment';
import H_Auth from './auth';
import H_Module from './module';

export default {
  getScopesFilteredFields(scopes, fields) {
    const _fields = {}
    for (const key in fields) {
      const field = fields[key]
      if (field.requireScopes) {
        if (!H_Auth.hasScope(field.requireScopes)) {
          continue
        }
      }
      _fields[key] = field
    }
    return _fields
  },
  getModuleModels() {
    let modelesJson = {}
    const moduleFiles = this.getModulesJson()
    if (!moduleFiles) {
      return
    }
    for (const moduleKey in moduleFiles) {
      if (!moduleFiles[moduleKey].models) {
        continue
      }
      for (const modelKey in moduleFiles[moduleKey].models) {
        modelesJson = {
          ...modelesJson,
          [modelKey]: this.getFormatedModel(moduleFiles[moduleKey].models[modelKey])
        }
      }
    }
    const extendModuleFiles = this.getExtendModulesJson()
    for (const moduleKey in extendModuleFiles) {
      if (!extendModuleFiles[moduleKey].extend_modules) {
        continue
      }
      for (const extendModuleKey in extendModuleFiles[moduleKey].extend_modules) {
        if (!extendModuleFiles[moduleKey].extend_modules[extendModuleKey].models) {
          continue
        }
        for (const extendModelKey in extendModuleFiles[moduleKey].extend_modules[extendModuleKey].models) {
          if (modelesJson[extendModelKey]) {
            modelesJson = {
              ...modelesJson,
              [extendModelKey]: this.mergeModelWithExtend(modelesJson[extendModelKey], extendModuleFiles[moduleKey].extend_modules[extendModuleKey].models[extendModelKey])
            }
          }
        }
      }
    }

    return modelesJson
  },
  mergeModelWithExtend(model, extendModel) {
    let _model = {
      ...model
    }
    if (extendModel.fields) {
      _model.fields = {
        ...model.fields,
        ...extendModel.fields
      }
    }
    if (!_model.crudSetting) {
      _model.crudSetting = {}
    }
    if (extendModel.crudSetting) {
      if (extendModel.crudSetting.index) {
        if (!_model.crudSetting.index) {
          _model.crudSetting.index = {}
        }
        _model.crudSetting.index = this.createOrMergeExtendKey(
          _model.crudSetting.index,
          extendModel.crudSetting.index,
          'showFields',
          'array'
        )
        _model.crudSetting.index = this.createOrMergeExtendKey(
          _model.crudSetting.index,
          extendModel.crudSetting.index,
          'filterSelects',
          'object'
        )
      }
      if (extendModel.crudSetting.create) {
        if (!_model.crudSetting.create) {
          _model.crudSetting.create = {}
        }
        if (
          extendModel.crudSetting.create.primary &&
          extendModel.crudSetting.create.primary[0]
        ) {
          if (!_model.crudSetting.create.primary) {
            _model.crudSetting.create.primary = []
          }
          if (!_model.crudSetting.create.primary[0]) {
            _model.crudSetting.create.primary[0] = {
              type: 'stateCard',
              floors: [
                {
                  fields: [],
                },
              ],
            }
          }
          if (extendModel.crudSetting.create.primary[0].floors[0]?.fields?.length) {
            _model.crudSetting.create.primary[0].floors[0].fields.push(
              ...extendModel.crudSetting.create.primary[0].floors[0].fields
            )
          }
        }
        if (
          extendModel.crudSetting.create.secondary &&
          extendModel.crudSetting.create.secondary[0]
        ) {
          if (!_model.crudSetting.create.secondary) {
            _model.crudSetting.create.secondary = []
          }
          if (!_model.crudSetting.create.secondary[0]) {
            _model.crudSetting.create.secondary[0] = {
              type: 'stateCard',
              floors: [
                {
                  fields: [],
                },
              ],
            }
          }
          if (extendModel.crudSetting.create.secondary[0].floors[0]?.fields?.length) {
            _model.crudSetting.create.secondary[0].floors[0].fields.push(
              ...extendModel.crudSetting.create.secondary[0].floors[0].fields
            )
          }
        }
      }
      if (extendModel.crudSetting.update) {
        if (!_model.crudSetting.update) {
          _model.crudSetting.update = {}
        }
        if (
          extendModel.crudSetting.update.primary &&
          extendModel.crudSetting.update.primary[0]
        ) {
          if (!_model.crudSetting.update.primary) {
            _model.crudSetting.update.primary = []
          }
          if (!_model.crudSetting.update.primary[0]) {
            _model.crudSetting.update.primary[0] = {
              type: 'stateCard',
              floors: [
                {
                  fields: [],
                },
              ],
            }
          }
          if (extendModel.crudSetting.update.primary[0].floors[0]?.fields?.length) {
            _model.crudSetting.update.primary[0].floors[0].fields.push(
              ...extendModel.crudSetting.update.primary[0].floors[0].fields
            )
          }
        }
      }
      if (extendModel.crudSetting.update) {
        if (!_model.crudSetting.update) {
          _model.crudSetting.update = {}
        }
        if (
          extendModel.crudSetting.update.secondary &&
          extendModel.crudSetting.update.secondary[0]
        ) {
          if (!_model.crudSetting.update.secondary) {
            _model.crudSetting.update.secondary = []
          }
          if (!_model.crudSetting.update.secondary[0]) {
            _model.crudSetting.update.secondary[0] = {
              type: 'stateCard',
              floors: [
                {
                  fields: [],
                },
              ],
            }
          }
          if (extendModel.crudSetting.update.secondary[0].floors[0]?.fields?.length) {
            _model.crudSetting.update.secondary[0].floors[0].fields.push(
              ...extendModel.crudSetting.update.secondary[0].floors[0].fields
            )
          }
        }
      }
      if (extendModel.crudSetting.read) {
        if (!_model.crudSetting.read) {
          _model.crudSetting.read = {
            leftFields: [],
            rightFields: [],
          }
        }
        if (extendModel.crudSetting.read.leftFields?.length) {
          _model.crudSetting.read.leftFields.push(
            ...extendModel.crudSetting.read.leftFields
          )
        }
        if (extendModel.crudSetting.read.rightFields?.length) {
          _model.crudSetting.read.rightFields.push(
            ...extendModel.crudSetting.read.rightFields
          )
        }
        if (extendModel.crudSetting.read?.childCruds?.length > 0) {
          for (const childCrudKey in extendModel.crudSetting.read.childCruds) {
            _model.crudSetting.read.childCruds.push(
              extendModel.crudSetting.read.childCruds[childCrudKey]
            )
          }
        }
      }
      if (extendModel.crudSetting.create_my) {
        if (!_model.crudSetting.create_my) {
          _model.crudSetting.create_my = {}
        }
        if (
          extendModel.crudSetting.create_my.primary &&
          extendModel.crudSetting.create_my.primary[0]
        ) {
          if (!_model.crudSetting.create_my.primary) {
            _model.crudSetting.create_my.primary = []
          }
          if (!_model.crudSetting.create_my.primary[0]) {
            _model.crudSetting.create_my.primary[0] = {
              type: 'stateCard',
              floors: [
                {
                  fields: [],
                },
              ],
            }
          }
          if (extendModel.crudSetting.create_my.primary[0].floors[0]?.fields?.length) {
            _model.crudSetting.create_my.primary[0].floors[0].fields.push(
              ...extendModel.crudSetting.create_my.primary[0].floors[0].fields
            )
          }
        }
        if (
          extendModel.crudSetting.create_my.secondary &&
          extendModel.crudSetting.create_my.secondary[0]
        ) {
          if (!_model.crudSetting.create_my.secondary) {
            _model.crudSetting.create_my.secondary = []
          }
          if (!_model.crudSetting.create_my.secondary[0]) {
            _model.crudSetting.create_my.secondary[0] = {
              type: 'stateCard',
              floors: [
                {
                  fields: [],
                },
              ],
            }
          }
          if (extendModel.crudSetting.create_my.secondary[0].floors[0]?.fields?.length) {
            _model.crudSetting.create_my.secondary[0].floors[0].fields.push(
              ...extendModel.crudSetting.create_my.secondary[0].floors[0].fields
            )
          }
        }
      }
      for (const key in extendModel.crudSetting) {
        if (['index', 'create', 'update', 'read'].includes(key)) {
          continue
        }
        if (!_model.crudSetting[key]) {
          _model.crudSetting[key] = {}
        }
        _model.crudSetting[key] = this.createOrMergeExtendKey(
          _model.crudSetting[key],
          extendModel.crudSetting[key],
          'showFields',
          'array'
        )
        // if (!_model.crudSetting[key]) {
        //   _model.crudSetting[key] = {}
        // }
        // _model.crudSetting[key] = {
        //   ..._model.crudSetting[key],
        //   ...extendModel.crudSetting[key]
        // }
      }
    }
    return _model
  },
  createOrMergeExtendKey(objOri, objExt, key, type = 'object') {
    const _objOri = {
      ...objOri
    }
    if (objExt[key]) {
      if (!_objOri[key]) {
        if (type == 'object') {
          _objOri[key] = {}
        } else if (type == 'array') {
          _objOri[key] = []
        }
      }
      if (type == 'object') {
        _objOri[key] = {
          ..._objOri[key],
          ...objExt[key]
        }
      } else if (type == 'array') {
        _objOri[key] = [
          ..._objOri[key],
          ...objExt[key]
        ]
      }
    }
    return _objOri
  },
  getModelMerged(newModel, model) {
    let _newModel = this.getFormatedModel(newModel)
    if (model) {
      if (
        model.modelName &&
        !_newModel.modelName
      ) {
        _newModel.modelName = model.modelName
      }
      if (
        model.label &&
        !_newModel.label
      ) {
        _newModel.label = model.label
      }
      if (
        model.fields
      ) {
        if (!_newModel.fields) {
          _newModel.fields = {}
        }
        _newModel.fields = {
          ..._newModel.fields,
          ...model.fields
        }
      }
    }
    return _newModel
  },
  getModulesContext() {
    try {
      return require.context('@' + '/__modules/', true, /\.js/i);
    } catch (error) {
      return
    }
  },
  getExtendModulesJson() {
    const modules = H_Module.getModulesContext()
    if (!modules) {
      return
    }
    const extendModuleFiles = {}
    modules.keys().forEach(modulePath => {
      const keyArr = modulePath.split('/')
      const moduleKey = keyArr[1]
      if (!config.wsmodule[moduleKey]) {
        return
      }
      if (!extendModuleFiles[moduleKey]) {
        extendModuleFiles[moduleKey] = {}
      }
      if (!extendModuleFiles[moduleKey][keyArr[2]]) {
        extendModuleFiles[moduleKey][keyArr[2]] = {}
      }
      if (
        keyArr[2] == 'extend_modules' &&
        keyArr[4] == 'models'
      ) {
        const extendModuleName = keyArr[3]
        const extendModelName = keyArr[5].split('.')[0]
        if (!extendModuleFiles[moduleKey][keyArr[2]][extendModuleName]) {
          extendModuleFiles[moduleKey][keyArr[2]][extendModuleName] = {}
        }
        if (!extendModuleFiles[moduleKey][keyArr[2]][extendModuleName].models) {
          extendModuleFiles[moduleKey][keyArr[2]][extendModuleName].models = {}
        }
        extendModuleFiles[moduleKey][keyArr[2]][extendModuleName].models[extendModelName] = modules(modulePath).default
      }
    });
    return extendModuleFiles
  },
  getModulesJson() {
    const modules = H_Module.getModulesContext()
    if (!modules) {
      return
    }
    const moduleFiles = {}
    modules.keys().forEach(modulePath => {
      const keyArr = modulePath.split('/')
      const key = keyArr[1]
      if (!moduleFiles[key]) {
        moduleFiles[key] = {}
      }
      if (!moduleFiles[key][keyArr[2]]) {
        moduleFiles[key][keyArr[2]] = {}
      }
      if (keyArr[2] == 'models') {
        const modelKey = keyArr[3].split('.')[0]
        moduleFiles[key][keyArr[2]][modelKey] = modules(modulePath).default
      }
    });
    return moduleFiles
  },
  getFormatedModel(model) {
    const _model = {
      ...model
    }
    if (model.options) {
      if (model.options.sq) {
        _model.fields = {
          ..._model.fields,
          sq: {
            type: 'text',
            label: '順序設定',
          },
        }
      }
      if (model.options.tags) {
        _model.fields = {
          ..._model.fields,
          tags: {
            type: 'tags',
            label: 'Tags',
            target: model.modelName,
          },
        }
      }
      if (model.options.uuid) {
        _model.fields = {
          uuid: {
            type: 'text',
            label: 'UUID',
            readonly: true,
          },
          ..._model.fields,
        }
      }
      if (model.options.id) {
        _model.fields = {
          id: {
            type: 'text',
            label: 'ID',
            readonly: true,
          },
          ..._model.fields,
        }
      }
      if (model.options.timestamp) {
        _model.fields = {
          ..._model.fields,
          created_at: {
            type: 'datetime',
            label: i18n.t('建立時間'),
            readonly: true,
          },
          updated_at: {
            type: 'datetime',
            label: i18n.t('最後更新時間'),
            readonly: true,
          },
        }
      }
      if (model.options.ws_class) {
        _model.fields = {
          ..._model.fields,
          ws_classes: {
            type: 'belongsToMany',
            label: '分類',
            modelName: 'ws_class',
            extendParams: {
              target: model.modelName
            },
          },
        }
      }
    }
    return _model;
  },
  getShowFieldsFromFields(fields) {
    return Object.keys(fields)
  },
  getReadFieldsFromFields(fields) {
    return Object.keys(fields)
  },
  getFormatedStates(fields, resourceData, postEncode) {
    const _fStates = {};
    for (let fieldKey in fields) {
      const field = fields[fieldKey];
      if (field.readonly) {
        continue;
      }
      if (field.type == 'belongs-to-many-multi-layers' && resourceData) {
        field.layerFields.forEach((layerField) => {
          _fStates[layerField.stateKey] = [];
          if (resourceData[layerField.stateKey]) {
            resourceData[layerField.stateKey].forEach((resourceDataItem) => {
              _fStates[layerField.stateKey].push(resourceDataItem.id);
            });
          }
        });
      } else if (resourceData && resourceData[fieldKey] != undefined) {
        if (field.type == 'date') {
          _fStates[fieldKey] = moment(resourceData[fieldKey]).format(
            'YYYY-MM-DD',
          );
        } else if (field.type == 'date-range-or-not' && resourceData) {
          _fStates.start_date = resourceData[fieldKey].startDate
          _fStates.end_date = resourceData[fieldKey].endDate
        } else if (field.type == 'datetime') {
          _fStates[fieldKey] = moment(resourceData[fieldKey])
        } else if (field.type == 'time') {
          _fStates[fieldKey] = moment(resourceData[fieldKey]);
        } else if (field.type == 'belongsTo') {
          _fStates[fieldKey] = resourceData[fieldKey].id;
        } else if (field.type == 'system_audio') {
          _fStates[fieldKey] = resourceData[fieldKey].id;
        } else if (
          field.type == 'file_image' &&
          config.general.postEncode &&
          !!postEncode
        ) {
          _fStates[fieldKey] = btoa(resourceData[fieldKey]);
        } else if (
          field.type == 'file_images' &&
          config.general.postEncode &&
          !!postEncode
        ) {
          const aa = JSON.stringify(resourceData[fieldKey]);
          const bb = btoa(unescape(encodeURIComponent(aa)));
          _fStates[fieldKey] = bb;
        } else if (
          field.type == 'file_files' &&
          config.general.postEncode &&
          !!postEncode
        ) {
          const aa = JSON.stringify(resourceData[fieldKey]);
          const bb = btoa(unescape(encodeURIComponent(aa)));
          _fStates[fieldKey] = bb;
        } else if (
          field.type == 'image' &&
          config.general.postEncode &&
          !!postEncode
        ) {
          _fStates[fieldKey] = btoa(resourceData[fieldKey]);
        } else if (
          field.type == 'file' &&
          config.general.postEncode &&
          !!postEncode
        ) {
          _fStates[fieldKey] = btoa(resourceData[fieldKey]);
        } else if (
          field.type == 'link' &&
          config.general.postEncode &&
          !!postEncode
        ) {
          resourceData
          _fStates[fieldKey] = btoa(resourceData[fieldKey]);
        } else if (
          field.type == 'list' &&
          config.general.postEncode &&
          !!postEncode
        ) {
          const aa = JSON.stringify(resourceData[fieldKey]);
          const bb = btoa(unescape(encodeURIComponent(aa)));
          _fStates[fieldKey] = bb;
        } else if (
          field.type == 'images' &&
          config.general.postEncode &&
          !!postEncode
        ) {
          const aa = JSON.stringify(resourceData[fieldKey]);
          const bb = btoa(unescape(encodeURIComponent(aa)));
          _fStates[fieldKey] = bb;
        } else if (
          field.type == 'files' &&
          config.general.postEncode &&
          !!postEncode
        ) {
          const aa = JSON.stringify(resourceData[fieldKey]);
          const bb = btoa(unescape(encodeURIComponent(aa)));
          _fStates[fieldKey] = bb;
        } else if (
          field.type == 'payload' &&
          config.general.postEncode &&
          !!postEncode
        ) {
          const aa = JSON.stringify(resourceData[fieldKey]);
          const bb = btoa(unescape(encodeURIComponent(aa)));
          _fStates[fieldKey] = bb;
        } else if (
          field.type == 'wasaintro' &&
          config.general.postEncode &&
          !!postEncode
        ) {
          const aa = JSON.stringify(resourceData[fieldKey]);
          const bb = btoa(unescape(encodeURIComponent(aa)));
          _fStates[fieldKey] = bb;
        } else if (field.type == 'pocket_image') {
          _fStates[fieldKey] = resourceData[fieldKey].id;
        } else if (field.type == 'pocket_file') {
          _fStates[fieldKey] = resourceData[fieldKey].id;
        } else if (field.type == 'belongsToMany') {
          const _value = [];
          const valueKey = field.valueKey ? field.valueKey : 'id';
          resourceData[fieldKey].forEach((valueItem) => {
            _value.push(valueItem[valueKey]);
          });
          _fStates[fieldKey] = _value;
        } else if (field.type == 'date-range') {
          _fStates[fieldKey] = resourceData[fieldKey].join(',');
        } else if (field.type == 'section_content') {
          const _content = {};
          for (const key in resourceData[fieldKey]) {
            const contentItem = resourceData[fieldKey][key];
            _content[key] = {
              id: contentItem.id,
              content: contentItem.content,
            };
          }
          const aa = JSON.stringify(_content);
          const bb = btoa(unescape(encodeURIComponent(aa)));
          _fStates[fieldKey] = bb;
        } else {
          _fStates[fieldKey] = resourceData[fieldKey];
        }
      } else if (field.defaultValue !== undefined) {
        _fStates[fieldKey] = field.defaultValue;
        continue;
      } else if (field.type == 'form') {
        const _resourceData = resourceData ? resourceData[fieldKey] : null;
        _fStates[fieldKey] = this.getFormatedStates(
          field.fields,
          _resourceData,
        );
      } else if (_fStates[fieldKey]) {
        continue;
      } else {
        _fStates[fieldKey] = null;
      }
    }
    return _fStates;
  },
  getFieldsByKeys(fields, keys) {
    const _fields = {}
    keys.forEach((fieldKey) => {
      if (fields[fieldKey]) {
        _fields[fieldKey] = fields[fieldKey]
      }
    })
    return _fields
  },
  getQueryFromStates(states, fields) {
    const _query = {
      ...states
    }
    for (const fieldKey in fields) {
      const _field = fields[fieldKey]
      if (_field.type == 'belongsTo') {
        if (!_query[fieldKey]) {
          continue;
        }
        _query[fieldKey] = _query[fieldKey].id
      }
      if (_field.type == 'belongsToMany') {
        if (!_query[fieldKey]) {
          continue;
        }
        const _value = _query[fieldKey].map(e => {
          return e.id
        })
        _query[fieldKey] = _value.join(',')
      }
    }
    return _query;
  },
  getStatesFromQuery(query, fields) {
    const _states = {
      ...query
    }
    for (const fieldKey in fields) {
      const _field = fields[fieldKey]
      if (_field.type == 'belongsTo') {
        if (!_states[fieldKey]) {
          continue;
        }
        _states[fieldKey] = {
          id: _states[fieldKey]
        }
      }
      if (_field.type == 'belongsToMany') {
        if (!_states[fieldKey]) {
          continue;
        }
        let _value = _states[fieldKey].split(',')

        _states[fieldKey] = _value.map(e => {
          return {
            id: e
          }
        })
      }
    }
    return _states
  },
  getUpdateFields(fields) {
    let _fields = {};
    for (let fieldKey in fields) {
      const field = fields[fieldKey];
      if (field.readonly) {
        continue;
      } else if (field.type == 'custom') {
        continue;
      } else {
        const _field = { ...field }
        if (_field.updateRules !== undefined) {
          _field.rules = _field.updateRules;
        }
        if (_field.type == "hasMany") {
          if (!_field.belongsToKey) {
            _field.belongsToKey = this.modelName;
          }
          _field.belongsToValue = this.modelData ? this.modelData.id : '';
        }
        if (
          typeof field.rules == 'object' &&
          field.rules !== null &&
          field.rules.update !== undefined
        ) {
          _field.rules = field.rules.update
        }
        _fields[fieldKey] = _field;
      }
    }
    return _fields;
  },
  getCreateFields(fields) {
    let _fields = {};
    for (let fieldKey in fields) {
      const field = fields[fieldKey];
      if (field.readonly) {
        continue;
      } else if (field.type == 'custom') {
        continue;
      } else if (field.type == 'hasMany') {
        continue;
      } else {
        const _field = { ...field }
        if (
          typeof field.rules == 'object' &&
          field.rules !== null &&
          field.rules.create !== undefined
        ) {
          _field.rules = field.rules.create
        }
        _fields[fieldKey] = _field;
      }
    }
    return _fields;
  },
  filterObjectByKeys(originalObject, keysToKeep) {
    return keysToKeep.reduce((filteredObj, key) => {
      if (key in originalObject) {
        filteredObj[key] = originalObject[key];
      }
      return filteredObj;
    }, {});
  }
}