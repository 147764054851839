<template>
  <div class="ws-easy-table">
    <!-- <div
      v-for="(field, fieldKey) in fields"
      :key="fieldKey"
    > -->
    <WsEasyTableItem
      v-for="(field, fieldKey) in fields"
      :key="fieldKey"
      :field="field"
      :isOdd="$_checkIsOdd(fieldKey)"
      :modelData="modelData"
      :value="$_getValueByFieldKey(fieldKey)"
      :copyable="field.copyable"
    />
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  props: {
    modelName: {
      type: String,
      default: null,
    },
    fields: Object,
    modelData: {
      type: [Object, Array],
      default: null,
    },
  },
  methods: {
    $_checkIsOdd(fieldKey) {
      const _fieldsArr = Object.entries(this.fields);
      const _index = _fieldsArr.findIndex((e) => {
        return e[0] == fieldKey;
      });
      return _index % 2 ? false : true;
    },
    $_getValueByFieldKey(fieldKey) {
      if (!this.modelData) {
        return null;
      } else {
        return this.$o_o.$h.state.getValueByFieldKey(fieldKey, this.modelData);
      }
    },
  },
  computed: {},
};
</script>