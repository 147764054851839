import H_Router from '@/__stone/helpers/router'

const routes = [
  {
    path: '/my/off_day',
    name: 'my_off_day',
    component: () => import('@/__modules/xc_leave_day/views/MyOffDay/Index.vue'),
    meta: {
      middleware: 'auth'
    }
  },
  {
    path: '/my/off_day_remain_hours',
    name: 'my_off_day_remain_hours',
    component: () => import('@/__modules/xc_leave_day/views/MyOffDay/MyOffDayRemainHours.vue'),
    meta: {
      middleware: 'auth'
    }
  },
  {
    path: '/off_day',
    name: 'off_day',
    component: () => import('@/__modules/xc_leave_day/views/OffDay/Index.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['xc_leave_day-admin'],
    }
  },
  {
    path: '/off_day_remain_hours',
    name: 'off_day_remain_hours',
    component: () => import('@/__modules/xc_leave_day/views/OffDay/OffDayRemainHours.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['xc_leave_day-admin'],
    }
  },
  ...H_Router.getCrudTemplateRoutes({
    routes: ['index_v2', 'read', 'create', 'update'],
    modelName: 'xc_leave_day_adjustment',
    middleware: 'auth',
  }),
  {
    path: '/xc_leave_day_search',
    name: 'xc_leave_day_search',
    component: () => import('@/__modules/xc_leave_day/views/OffDay/Search.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['xc_leave_day-admin'],
    }
  },
  {
    path: '/print/xc_leave_day',
    name: 'print_xc_leave_day',
    component: () => import('@/__modules/xc_leave_day/views/OffDay/Print.vue'),
    meta: {
      template: 'print',
      middleware: 'auth',
      requiredScopes: [
        'xc_leave_day-admin',
      ],
    }
  },
  // ...H_Router.getCrudTemplateRoutes({
  //   routes: [
  //     'index',
  //     'read',
  //     'create',
  //   ],
  //   modelName: 'xc_leave_day_settlement',
  //   middleware: 'auth',
  //   meta: {
  //     requiredScopes: ['xc_leave_day-admin'],
  //   }
  // }),
]

export default routes