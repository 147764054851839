<template>
  <WsMain>
    <WsReadSection
      ref="WsReadSection"
      :label="$store.state.stone_model.xc_employee_salary_adjustjob.label"
      :fields="$store.state.stone_model.xc_employee_salary_adjustjob.fields"
      v-bind="$store.state.stone_model.xc_employee_salary_adjustjob"
      :leftFields="_leftFields"
      v-model="state"
    />
    <WsActionApi
      class="mt-40"
      v-if="state.id"
      fetchUrl="/xc_employee_salary_adjustment"
      :params="_childCrudParams"
      v-slot="{ res, isFetching }"
    >
      <WsLoading v-if="isFetching"></WsLoading>
      <XcEmployeeSalaryAdjustmentReadTable
        v-else
        :xc_employee_salary_adjustments="res.data"
      />
    </WsActionApi>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      state: {},
    };
  },
  computed: {
    _childCrudParams() {
      return {
        xc_employee_salary_adjustjob: this.state.id,
      };
    },
    _leftFields() {
      if (!this.state) {
        return [];
      } else if (this.state.source == "xc_employee_department") {
        return [
          "effective_at",
          "confirm_at",
          "review_confirm_at",
          "xc_employee",
          "review_xc_employee",
          "xc_employee_department",
        ];
      } else if (this.state.source == "xc_employees") {
        return [
          "effective_at",
          "confirm_at",
          "review_confirm_at",
          "xc_employee",
          "review_xc_employee",
          "xc_employees",
        ];
      } else {
        return [];
      }
    },
  },
  methods: {},
};
</script>

<style>
</style>