export default {
  getXcProjectMenu(data, res) {
    res.splice(1, 0, {
      title: "會議",
      link: `/xc_project/${data.id}/xc_meeting`,
    });
    return res
  },
  getXcProjectExtendRoutes(data, res) {
    let _routes = []
    if (res) {
      _routes = res
    }
    _routes.push(...[
      {
        path: 'xc_meeting',
        name: 'XcMeeting',
        component: () => import('@/__modules/xc_meeting/extend_modules/xc_project/views/XcProject/XcMeeting.vue'),
        meta: {
          middleware: 'auth',
        },
      },
    ])
    return _routes
  }
}