<template>
  <WsModelIndex
    ref="WsModelIndex"
    label="發薪作業覆核"
    getUrl="/xc_employee_salary_payjob/index/myreview"
    :modelName="$store.state.stone_model.xc_employee_salary_payjob.modelName"
    :fields="$store.state.stone_model.xc_employee_salary_payjob.fields"
    :showFields="showFields"
    :creatable="false"
    :updatable="false"
    :readable="true"
    :pageMode="true"
    :actionWidth="140"
    :customTableActions="customTableActions"
  ></WsModelIndex>
</template>

<script>
export default {
  data() {
    return {
      showFields: [
        "status",
        "start_at",
        "end_at",
        "confirm_at",
        "review_confirm_at",
      ],
      customTableActions: [
        {
          icon: "icon-md-check",
          emit: "check",
          tooltip: this.$t("發薪覆核"),
          getUrl: (item) => {
            return `/xc_employee_salary_payjob/${item.id}/slips/review`;
          },
          displayCheck: (item) => {
            if (item.status === "confirmed") {
              return true;
            } else {
              return false;
            }
          },
        },
      ],
    };
  },
  computed: {},
  methods: {},
};
</script>

<style>
</style>