<template>
  <WsRow class="ws-info-form">
    <WsCol
      v-for="(field, fieldKey) in fields"
      class="py-12"
      :class="[`col-${field.col ? field.col : 12}`, `sm-col-${field.colSm ? field.sm : 12}`]"
      :key="fieldKey"
    >
      <WsInfo
        v-bind="field"
        :parent="field.parentKey"
        :value="$_getValue(value,fieldKey,field)"
        :modelData="value"
        :displayLabel="displayLabel"
      />
    </WsCol>
  </WsRow>
</template>

<script>
import H_State from "@/__stone/helpers/state";

export default {
  methods: {
    $_getValue(item, key, field) {
      if (field.type == "date-range-or-not") {
        return H_State.getValueFromFieldAndFormValue(field, item, key);
      } else {
        return this.$o_o.$h.state.getValueByFieldKey(key, item);
      }
      // if (field.type == "custom") {
      //   return field.getValue(value);
      // } else if (field.type == "date-range-or-not") {
      //   if (value.start_date && value.end_date) {
      //     return {
      //       startDate: value.start_date,
      //       endDate: value.end_date,
      //     };
      //   } else {
      //     return {};
      //   }
      // } else if (!value) {
      //   return null;
      // } else {
      //   return value[fieldKey];
      // }
    },
  },
  props: {
    id: {},
    value: {
      type: Object,
    },
    modelName: {},
    fields: {
      type: Object,
      default: null,
    },
    displayLabel: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style>
</style>