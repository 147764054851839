<template>
  <div>
    <WsActionApi
      v-if="_from_xc_employee_id"
      :fetchUrl="`/xc_employee/${_from_xc_employee_id}`"
      @onFetch="$_onFetch"
    >
    </WsActionApi>
    <WsCreate
      ref="WsCreate"
      :modelName="$store.state.stone_model.xc_employee_hire.modelName"
      urlModelName="xc_employee_hire_change"
      label="人員異動作業"
      :fields="$store.state.stone_model.xc_employee_hire.fields"
      :extendPostData="_extendPostData"
      :primary="primary"
      v-model="state"
      :emitInput="true"
    >
      <template v-slot:beforeContent>
        <!-- <WsInfo
          label="員工狀態"
          v-bind="$store.state.stone_model.xc_employee_hire.fields.xc_employee_status"
          :value="_from_xc_employee_status"
        >
        </WsInfo> -->
        <!-- <WsInfoForm
          class="pt-12"
          :fields="_readFields"
          :value="state"
        >
        </WsInfoForm> -->
      </template>
    </WsCreate>
  </div>
</template>

<script>
export default {
  data() {
    return {
      state: {},
      primary: [
        {
          type: "stateCard",
          floors: [
            {
              fields: [
                "xc_employee_status",
                "xc_process_work_unit",
                "name",
                "name_eng",
                "no",
                "email",
                "xc_employee_position_type",
                "xc_employee_department",
                "xc_employee_type",
                "xc_employee_working_schedule_setting",
                "id_card_no",
                "gender",
                "nationality",
                "employee_start_at",
                "company_phone",
                "company_phone_ext",
                "native_place",
                "contact_address_city",
                "contact_address_district",
                "contact_address_zip",
                "contact_address_detail",
                "household_address_city",
                "household_address_district",
                "household_address_zip",
                "household_address_detail",
                "mobile",
                "contact_tel",
                "expertise",
                "research_range",
                "research_field",
                "autobiography",
                "autobiography_eng",
                "emergency_contacts",
                "family_members",
                "educations",
                "work_experiences",
                "exam_qualifications",
                "education_trainings",
                "publications",
                "articles",
                "research_projects_internal",
                "research_projects_external",
                "languages",
                "language_exams",
                "graduation_diplomas",
                "graduation_transcripts",
                "health_exams",
                "basic_salary",
                "meal_expenses",
                "work_allowance",
                "transportation_allowance",
                "approved_salary",
                "approved_salary_without_transportation",
              ],
            },
          ],
        },
      ],
    };
  },
  methods: {
    $_onFetch($event) {
      this.state = {
        ...$event.data,
        xc_employee_status: this._from_xc_employee_status,
      };
    },
  },
  computed: {
    _extendPostData() {
      return {
        // xc_employee_status: this._from_xc_employee_status,
        xc_employee: this._from_xc_employee_id,
      };
    },
    _from_xc_employee_id() {
      return this.$route.query.xc_employee;
    },
    _from_xc_employee_status() {
      return this.$route.query.xc_employee_status;
    },
    _fields() {
      const _fields = {
        ...this.$store.state.stone_model.xc_employee_hire.fields,
      };
      delete _fields.xc_employee;
      delete _fields.employee_end_at;
      return _fields;
    },
  },
  mounted() {},
};
</script>