export default {
  my_wfh: {
    icon: 'icon-md-weekend',
    title: '我的 一日宅宅',
    link: '/my/wfh',
  },
  wfh: {
    icon: 'icon-md-weekend',
    title: '一日宅宅',
    link: '/wfh',
    scopes: ['xc_wfh-admin']
  },
};