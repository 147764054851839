<template>
  <div class="ws-model-index__table">
    <div class="ws-model-index__table__table-container">
      <table>
        <WsModelIndexTableHeader
          v-bind="$props"
          :headers="_headers"
        ></WsModelIndexTableHeader>
        <WsModelIndexTableItem
          v-for="(modeldata, itemIndex) in modeldatas"
          :key="itemIndex"
          :index="itemIndex"
          v-bind="$props"
          :item="modeldata"
          :headers="_headers"
          @read="$emit('read',modeldata)"
          @update="$emit('update',modeldata)"
          @delete="$emit('delete',modeldata)"
          @duplicate="$emit('duplicate',modeldata)"
          @custom-table-action="$emit('custom-table-action',{emit:$event.emit,data:{item:modeldata}})"
        ></WsModelIndexTableItem>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    $_onCustomTableAction() {},
  },
  computed: {
    _headers() {
      let _headers = [];
      this._showFields.forEach((fieldKey) => {
        const field = this.fields[fieldKey];
        if (!field) {
          return;
        }
        if (field.type && field.type == "list") {
          return;
        }
        _headers.push({
          value: fieldKey,
          text: field.label,
          width: field.width,
        });
      });
      return _headers;
    },
    _showFields() {
      if (this.showFields) {
        return this.showFields;
      } else {
        const _showFields = [];
        for (const fieldKey in this.fields) {
          _showFields.push(fieldKey);
        }
        return _showFields;
      }
    },
  },
  props: {
    modeldatas: {},
    showFields: {},
    fields: {},
    inRowBtnRead: {},
    inRowBtnUpdate: {},
    inRowBtnDelete: {},
    inRowBtnComplete: {},
    customTableActions: {},
    modelName: {},
    readable: {},
    creatable: {},
    updatable: {},
    deletable: {},
    pageMode: {},
    getUpdateUrl: {},
    getReadUrl: {},
    actionWidth: {},
    dialogRead: {},
    dialogUpdate: {},
    urlModelName: {},
  },
};
</script>

<style lang="scss">
.ws-model-index__table {
  // border-top: solid 1px var(--gray5d);
  // position: relative;
  // flex: 1;
  // display: flex;
  // flex-direction: column;
  width: 100%;
  .ws-progress-bar {
    position: absolute;
    top: 0;
    left: 0;
  }
  .ws-model-index__table__table-container {
    width: 100%;
    // overflow: auto;
    // flex: 1;
    // display: flex;
    // flex-direction: column;
    // @media (max-width: 600px) {
    //   padding-bottom: 60px;
    // }
    table {
      min-width: 100%;
      color: var(--text-1);
      background-color: var(--white1d);
      table-layout: fixed;
      tr {
        position: relative;
        &.row-click-read {
          cursor: pointer;
        }
        th {
          padding: 12px 6px;
          font-size: 0.75rem;
          font-weight: 600;
          line-height: 1.43;
          text-align: left;
          background-color: var(--white1d);
          border-bottom: solid 1px var(--gray6l);
          &.actions {
            right: 0;
            padding: 0;
            position: sticky;
            z-index: 1;
            background-color: var(--white1d);
          }
          &.actions-xs {
            right: 0;
            position: sticky;
            z-index: 1;
            background-color: var(--white1d);
            width: 32px;
            padding: 0;
          }
          &.check {
            width: 48px;
            min-width: 48px;
            padding-left: 8px;
            .ws-state-checkbox {
              &:focus {
                border: 0;
              }
            }
          }
          .ws-icon {
            padding: 2px;
          }
        }
        td {
          height: 50px;
          padding: 6px 6px;
          font-size: 0.875rem;
          line-height: 1.43;
          text-align: left;
          border-top-style: solid;
          border-top-width: 1px;
          border-color: transparent;
          @media (max-width: 600px) {
            height: 32px;
            padding: 4px 6px;
          }
          .ws-text {
            // white-space: nowrap;
          }
          input {
            z-index: 0;
          }
          &.check {
            .ws-state-checkbox {
              &:focus {
                border: 0;
              }
            }
          }
          &.actions {
            right: 0;
            padding: 8px;
            position: sticky;
            z-index: 1;
            background-color: var(--white1d);
            width: 0;
            .ws-icon-btn {
              margin: 0 4px;
              float: left;
            }
          }
          &.actions-xs {
            right: 0;
            position: sticky;
            z-index: 1;
            background-color: var(--white1d);
            width: 32px;
            padding: 0;
          }
        }
      }
      &:nth-child(1) tr th {
        position: sticky;
        top: 0px;
        z-index: 2;
      }
    }
  }
}
</style>