<template>
  <WsUpdate
    ref="WsUpdate"
    :modelName="$store.state.stone_model.xc_employee_salary_adjustjob.modelName"
    :label="$store.state.stone_model.xc_employee_salary_adjustjob.label"
    :fields="$store.state.stone_model.xc_employee_salary_adjustjob.fields"
    :primary="_primary"
    titleKey="effective_at"
    v-model="state"
    emitInput
    :getCompleteRedirectUrl="$_getCompleteRedirectUrl"
  />
</template>

<script>
export default {
  data() {
    return {
      state: {},
    };
  },
  methods: {
    $_getCompleteRedirectUrl() {
      return `/xc_employee_salary_adjustjob`;
    },
  },
  computed: {
    _primary() {
      const _primary = [
        {
          type: "stateCard",
          floors: [
            {
              fields: ["review_xc_employee", "effective_at", "source"],
            },
          ],
        },
      ];
      if (this.state.source) {
        if (this.state.source === "xc_employees") {
          _primary[0].floors[0].fields.push("xc_employees");
        } else if (this.state.source === "xc_employee_department") {
          _primary[0].floors[0].fields.push("xc_employee_department");
        }
      }
      return _primary;
    },
  },
};
</script>
