const routes = [
  {
    path: '/system_event',
    name: 'system_event',
    component: () =>
      import('@/__modules/system_event/views/SystemEvent/Index.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['system_event-admin'],
    },
  },
  {
    path: '/additional_on_day',
    name: 'additional_on_day',
    component: () =>
      import('@/__modules/system_event/views/SystemAdditionalOnDay/Index.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['system_event-admin'],
    },
  },
  {
    path: '/additional_off_day',
    name: 'additional_off_day',
    component: () =>
      import('@/__modules/system_event/views/SystemAdditionalOffDay/Index.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['system_event-admin'],
    },
  },
  {
    path: '/official_holiday',
    name: 'official_holiday',
    component: () =>
      import('@/__modules/system_event/views/SystemOfficialHoliday/Index.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['system_event-admin'],
    },
  },
  {
    path: '/official_holiday_make_up',
    name: 'official_holiday_make_up',
    component: () =>
      import(
        '@/__modules/system_event/views/SystemOfficialHolidayMakeUp/Index.vue'
      ),
    meta: {
      middleware: 'auth',
      requiredScopes: ['system_event-admin'],
    },
  },
];

export default routes