
<template>
  <div v-if="_displayTable" class="ws-state-spec-table mt-24">
    <WsCard class="main-card pa-0">
      <div class="main-card-header pa-16">
        <WsText size="16" class="mb-10" bold>規格表</WsText>
        <WsFlex alignItems="center" gap="16px">
          <WsStateForm
            v-model="headerState"
            :fields="headerItems"
          />
          <WsBtn @click="$_allCover()">全部套用</WsBtn>
          <WsBtn @click="$_allClean()" outlined>全部清除</WsBtn>
        </WsFlex>
      </div>
      <div class="pa-16">
        <div 
          v-for="(item, index) in _specItems"
          :key="index"
        >
          <WsText size="16" class="mt-10" bold>{{ item.name }}</WsText>
          <WsStateForm
            v-model="specState[index]"
            :fields="specFields"
          />
        </div>
      </div>
    </WsCard>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headerState: null,
      headerItems: {
        price: {
          type: 'number',
          placeholder: '商品售價',
          rules: 'min_value:1|integer',
          col: 6
        },
        stock_count: {
          type: 'number',
          placeholder: '庫存數量',
          rules: 'min_value:0|integer',
          col: 6
        }
      },
      specState: [],
      specFields: {
        price: {
          type: 'number',
          label: '售價',
          rules: 'required|min_value:1|integer',
          col: 6
        },
        stock_count: {
          type: 'number',
          label: '庫存數量',
          rules: 'required|min_value:0|integer',
          col: 6
        }
      }
    }
  },
  methods: {
    $_init($event) {
      this.specState = $event
    },
    $_allCover() {
      this.specState.forEach(spec => {
        spec.price = this.headerState.price
        spec.stock_count = this.headerState.stock_count
      })
    },
    $_allClean() {
      this.specState.forEach(spec => {
        spec.price = null
        spec.stock_count = null
      })
    },
    $_addSpec() {
      this.specState.push({
        price: null,
        stock_count: null
      })
    },
    $_deleteSpec(spec_setting_item_index) {
      this.specState.splice(spec_setting_item_index, 1)
    }
  },
  computed: {
    _displayTable() {
      return this.spec_settings?.length > 0 ? true : false
    },
    _specItems() {
      let _specItems = []
      specItemsInit(this.spec_settings)
      function specItemsInit(spec_settings, prefix = '') {
        if (spec_settings.length === 0) {
          _specItems.push({ name: prefix.slice(0, -3) })
          return
        }
        const [currentSetting, ...remainingSettings] = spec_settings
        currentSetting.spec_setting_items.forEach(item => {
          specItemsInit(remainingSettings, `${prefix}${item.name} - `)
        })
      }
      if (_specItems.length != this.specState.length) {
        let _newSpecState = []
        _specItems.forEach(item => {
          _newSpecState.push({
            name: item.name,
            price: null,
            stock_count: null
          })
        })
        this.$_init(_newSpecState)
      } else {
        _specItems.forEach((item, index) => {
          this.specState[index].name = item.name
        })
      }
      return _specItems
    }
  },
  props: {
    spec_settings: {
      type: Array,
      default() {
        return []
      }
    }
  },
  watch: {
    specState(newVal) {
      this.$emit('input', newVal)
    },
  },
}
</script>
