export default {
  $_returnMyBoardComponent() {
    return [
      {
        keepLocation: 'left',
        componentName: 'XcWorkOvertimeMyOverview',
        sq: 2
      },
      {
        keepLocation: 'right',
        componentName: 'MyXcWorkOvertimeBoard',
        sq: 7
      },
    ]
  }
}
