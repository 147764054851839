import $numeral from 'numeral';

const model = {
  options: {
    id: true,
    timestamp: true,
  },
  modelName: 'xc_employee_salary_update_record',
  label: '工資異動紀錄',
  fields: {
    xc_employee: {
      type: 'belongsTo',
      label: '員工',
      modelName: 'xc_employee',
    },
    approved_salary: {
      type: 'custom',
      label: `核定薪資(含交津)`,
      showInUpdate: true,
      info: true,
      getValue: function (value) {
        let _value = 0
        if (value.basic_salary) {
          _value += parseInt(value.basic_salary)
        }
        if (value.meal_expenses) {
          _value += parseInt(value.meal_expenses)
        }
        if (value.work_allowance) {
          _value += parseInt(value.work_allowance)
        }
        if (value.transportation_allowance) {
          _value += parseInt(value.transportation_allowance)
        }
        return $numeral(_value).format('$0,0');
      },
    },
    approved_salary_without_transportation: {
      type: 'custom',
      label: `核定薪資(不含交津)`,
      showInUpdate: true,
      info: true,
      getValue: function (value) {
        let _value = 0
        if (value.basic_salary) {
          _value += parseInt(value.basic_salary)
        }
        if (value.meal_expenses) {
          _value += parseInt(value.meal_expenses)
        }
        if (value.work_allowance) {
          _value += parseInt(value.work_allowance)
        }
        return $numeral(_value).format('$0,0');
      },
    },
    basic_salary: {
      type: 'currency',
      label: '本俸',
    },
    meal_expenses: {
      type: 'currency',
      label: '伙食費',
    },
    work_allowance: {
      type: 'currency',
      label: '工作加給',
    },
    transportation_allowance: {
      type: 'currency',
      label: '交通津貼',
    },
  },
  crudSetting: {
    index: {
      showFields: [
        'xc_employee',
        'approved_salary',
        'approved_salary_without_transportation',
        'basic_salary',
        'meal_expenses',
        'work_allowance',
        'transportation_allowance',
      ],
      creatable: false,
      updatable: false,
      deletable: false,
      inRowBtnRead: true,
    },
  }
}

export default model