export default {
  modelName: 'system_event',
  label: '',
  fields: {
    id: {
      type: 'text',
      label: 'ID',
      readonly: true,
    },
    name: {
      type: 'text',
      label: '名稱',
      rules: 'required',
    },
    start_date: {
      type: 'date',
      label: '日期',
      rules: 'required',
    },
    start_at: {
      type: 'datetime',
      label: '開始時間',
    },
    hours: {
      type: 'number',
      label: '時數',
    },
    days: {
      type: 'number',
      label: '天數',
      defaultValue: 1,
      rules: 'required',
    },
    note: {
      type: 'textarea',
      label: '備註',
    },
    type: {
      type: 'select',
      label: '分類',
      placeholder: '選擇分類',
      items: [
        {
          text: '額外休息日',
          value: 'additional_off_day',
        },
        {
          text: '額外工作日',
          value: 'additional_on_day',
        },
        {
          text: '國定假日',
          value: 'official_holiday',
        },
        {
          text: '補班',
          value: 'official_holiday_make_up',
        },
      ],
      col: 4,
    },
  },
};
