<template>
  <WsDialogRead
    ref="WsDialogRead"
    v-model="dialog"
  >
    <template #content>
      <WsEasyTable
        :fields="fields"
        :modelData="item"
      />
    </template>
  </WsDialogRead>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      item: null,
    };
  },
  methods: {
    async open(item) {
      this.$refs.WsDialogRead.open();
      if (this.fetchOnOpen) {
        try {
          this.$store.dispatch("app/startPageLoading");
          const _fetchUrl = this.$_getFetchUrl(item);
          const res = await this.$axios.get(_fetchUrl);
          this.item = res.data.data;
        } catch (error) {
          alert("讀取資料錯誤");
        } finally {
          this.$store.dispatch("app/stopPageLoading");
        }
      } else {
        this.item = item;
      }
    },
    $_getFetchUrl(item) {
      if (this.getReadFetchUrl) {
        return this.getReadFetchUrl(item);
      } else {
        return `${this.modelName}/${item.id}`;
      }
    },
  },
  props: {
    fields: {},
    modelName: {},
    getReadFetchUrl: {},
    fetchOnOpen: {
      type: Boolean,
      default: true,
    },
  },
};
</script>