<template>
  <WsUpdate
    ref="WsUpdate"
    :modelName="$store.state.stone_model.xc_employee_salary_payjob.modelName"
    :label="$store.state.stone_model.xc_employee_salary_payjob.label"
    :fields="$store.state.stone_model.xc_employee_salary_payjob.fields"
    :primary="_primary"
    titleKey="start_at"
    v-model="state"
    emitInput
    :getCompleteRedirectUrl="$_getCompleteRedirectUrl"
  />
</template>

<script>
export default {
  data() {
    return {
      state: {},
    };
  },
  methods: {
    $_getCompleteRedirectUrl() {
      return `/xc_employee_salary_payjob`;
    },
  },
  computed: {
    _primary() {
      const _primary = [
        {
          type: "stateCard",
          floors: [
            {
              fields: [
                "review_xc_employee",
                "start_at",
                "end_at",
                "xc_employees",
              ],
            },
          ],
        },
      ];
      return _primary;
    },
  },
};
</script>
