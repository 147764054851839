<template>
  <div
    class="ws-reply"
    :id="`reply_${id}`"
  >
    <WsReplyInput
      :cancelable="true"
      v-if="active.edit"
      :autofocus="true"
      ref="WsEditInput"
      :value="content"
      @submit="$_onEditSubmit($event)"
      @cancel="$_onEditCancel()"
    ></WsReplyInput>
    <WsFlex v-else>
      <WsAvatar
        size="32"
        :src="user.avatar"
      ></WsAvatar>
      <WsCard class="ml-8 pa-0 ws-reply__card">
        <div class="px-20 py-8">
          <WsFlex alignItems="center">
            <WsText
              size="14"
              class="flex-0"
            >{{ user.name }}</WsText>
            <WsText
              class="ml-8 pt-3 flex-0"
              size="12"
              :color="$color.gray"
            >於 {{ $o_o.$h.time.getTimeText(created_at) }} <span v-if="edit_at">(編輯於
                {{ $o_o.$h.time.getTimeText(edit_at) }})</span> </WsText>
            <WsSpacer></WsSpacer>
            <WsFlex v-if="!user_deleted_at">
              <WsIconBtn
                @click="$_onClickEdit()"
                v-if="_editable"
                :noBorder="true"
                size="28"
                iconSize="18"
                name="icon-md-edit"
              ></WsIconBtn>
              <WsIconBtn
                @click="$_onClickDelete()"
                v-if="_deletable"
                :noBorder="true"
                size="28"
                iconSize="18"
                name="icon-md-delete"
              ></WsIconBtn>
              <WsIconBtn
                @click="$_onClickRouteCopy()"
                :noBorder="true"
                size="28"
                iconSize="18"
                name="icon-md-link"
              ></WsIconBtn>
              <WsIconBtn
                @click="$_onClickReply()"
                :noBorder="true"
                size="28"
                iconSize="18"
                name="icon-md-reply"
              ></WsIconBtn>
            </WsFlex>
          </WsFlex>
        </div>
        <div class="ws-reply__content">
          <div v-if="user_deleted_at">此則留言此刪除:)</div>
          <WsInfoHtml
            v-else
            :value="content"
            clickImgOpenPopup
          ></WsInfoHtml>
        </div>
      </WsCard>
    </WsFlex>
    <div class="ws-reply__replies">
      <WsReply
        class="my-12"
        v-for="(reply, replyIndex) in replies"
        :key="replyIndex"
        v-bind="reply"
        :user="reply.admin"
        :params="_params"
        :modelName="modelName"
        @update="$_onReplyUpdate(replyIndex, $event)"
        @delete="$_onReplyDelete(replyIndex)"
      ></WsReply>
      <WsReplyInput
        :cancelable="true"
        :autofocus="true"
        v-if="active.reply"
        ref="WsReplyInput"
        @submit="$_onReplySubmit($event)"
        @cancel="$_onReplyCancel()"
      />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      replies: [],
      active: {
        edit: false,
        delete: false,
        reply: false,
      },
    };
  },
  methods: {
    $_onClickEdit() {
      this.active.edit = true;
    },
    async $_onDeleteSubmit() {
      this.$store.dispatch("app/startPageLoading");
      try {
        await this.$axios.delete(`${this.modelName}/${this.id}`);
        this.$emit("delete");
        this.active.delete = false;
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    $_onClickDelete() {
      this.$store.dispatch("app/setAlert", {
        title: "確定刪除？",
        description: "",
        action: this.$_onDeleteSubmit,
      });
    },
    $_onClickRouteCopy(){
      let _url= `${location.origin}${location.pathname}#reply_${this.id}`;
      try {
        navigator.clipboard.writeText(_url);
        alert('已複製');
      } catch (error) {
        console.error(error);
      }
    },
    $_onClickReply() {
      this.active.reply = true;
    },
    $_onReplyCancel() {
      this.active.reply = false;
    },
    $_onReplyDelete(replyIndex) {
      const _replies = [...this.replies];
      _replies.splice(replyIndex, 1);
      this.replies = _replies;
    },
    $_onReplyUpdate(replyIndex, $event) {
      const _replies = [...this.replies];
      _replies.splice(replyIndex, 1);
      _replies.splice(replyIndex, 0, $event);
      this.replies = _replies;
    },
    async $_onEditSubmit($event) {
      this.$store.dispatch("app/startPageLoading");
      try {
        const res = await this.$axios.patch(`${this.modelName}/${this.id}`, {
          content: $event,
        });
        this.$emit("update", res.data.data);
        this.$refs.WsEditInput?.clear();
        this.active.edit = false;
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    $_onEditCancel() {
      this.active.edit = false;
    },
    async $_onReplySubmit($event) {
      this.$store.dispatch("app/startPageLoading");
      try {
        const res = await this.$axios.post(`${this.modelName}`, {
          ...this._params,
          content: $event,
        });
        this.replies.push(res.data.data);
        this.$refs.WsReplyInput?.clear();
        this.active.reply = false;
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
  },
  computed: {
    _editable() {
      return this._currentUser.id == this.user.id;
    },
    _deletable() {
      return this._currentUser.id == this.user.id;
    },
    _currentUser() {
      return this.$store.state.auth.currentUser;
    },
    _params() {
      return {
        ...this.params,
        [this.modelName]: this.id,
      };
    },
  },
  mounted() {
    this.replies = this.xc_task_replies ? this.xc_task_replies : [];
  },
  props: {
    id: {},
    content: {},
    user: {},
    created_at: {},
    updated_at: {},
    xc_task_replies: {},
    params: {},
    modelName: {},
    user_deleted_at: {},
    edit_at: {},
  },
};
</script>