export default {
  my_substitute: {
    icon: 'icon-md-find-replace',
    title: '我的 職位代理(交代人)',
    link: '/my/substitute',
  },
  my_substitute_substitute: {
    icon: 'icon-md-find-replace',
    title: '我的 職位代理(代理人)',
    link: '/my_substitute/substitute',
  },
  xc_substitute: {
    icon: 'icon-md-find-replace',
    title: '職位代理',
    link: '/substitute',
    scopes: ['xc_substitute-admin']
  },
};