<template>
  <WsMain>
    <WsActionApi
      fetchUrl="my/overview/xc_leave_day"
      v-slot="{ res, isFetching }"
    >
      <WsLoading v-if="isFetching" />
      <WsBoardCard001
        v-else
        flexDirection="row"
        v-bind="$_getCardProps(res)"
      />
    </WsActionApi>
  </WsMain>
</template>

<script>
export default {
  methods: {
    $_getCardProps(res) {
      return {
        items: [
          {
            label: "可用休假餘額",
            items: Object.values(res).map((item) => ({
              label: item.name,
              value: `${item.total_days_and_hours_days}天 ${item.total_days_and_hours_hours}小時`,
            })),
          },
          {
            label: "已用休假餘額",
            items: Object.values(res).map((item) => ({
              label: item.name,
              value: `${item.used_days_and_hours_days}天 ${item.used_days_and_hours_hours}小時`,
            })),
          },
        ],
      };
    },
  },
};
</script>
