<template>
  <XcTaskIndex
    label="Task"
    :showFields="showFields"
    :params="_params"
    :titleBarCustomBtns="_titleBarCustomBtns"
    :filterTabs="_filterTabs"
    :creatable="true"
    :updatable="true"
    :deletable="true"
    :filterSelects="_filterSelects"
    fetchOnOpen
  ></XcTaskIndex>
</template>

<script>
export default {
  data() {
    return {
      showFields: [
        "id",
        "status",
        "taker_status",
        "due_date",
        "taker",
        "xc_project",
        "name",
      ],
    };
  },
  methods: {},
  computed: {
    _filterSelects() {
      let _filterSelects = {
        taker: {
          label: "執行人",
          type: "belongsTo",
          modelName: this.$o_o.$c.wsmodule.admin.admin_blur ? "cmser" : "admin",
          textKey: "name",
          col: 3,
          extendParams: {
            is_active: 1,
          },
        },
      };
      _filterSelects = this.$o_o.$h.module.extendModuleHelperAction(
        "xc_task",
        "xc_task.js",
        "getIndexFilterSelects",
        {
          filterSelects: _filterSelects,
        }
      );
      return _filterSelects;
    },
    _titleBarCustomBtns() {
      const _titleBarCustomBtns = [];
      _titleBarCustomBtns.push(
        ...[
          {
            label: "排程模式",
            to: `/xc_task/scheduling`,
          },
          {
            label: "批次新增",
            to: `/xc_task/create/batch`,
          },
        ]
      );
      return _titleBarCustomBtns;
    },
    _params() {
      return {};
    },
    _filterTabs() {
      return [
        {
          text: "Open",
          value: 4,
          params: {
            status: 4,
          },
        },
        {
          text: "Stay",
          value: 1,
          params: {
            status: 1,
          },
        },
        {
          text: "TR",
          value: 2,
          params: {
            status: 2,
          },
        },
        {
          text: "Ready",
          value: 3,
          params: {
            status: 3,
          },
        },
        {
          text: "Done",
          value: 5,
          params: {
            status: 5,
          },
        },
        {
          text: "Done-Reject",
          value: 11,
          params: {
            status: 11,
          },
        },
        {
          text: "Pending",
          value: 12,
          params: {
            status: 12,
          },
        },
        {
          text: "Close",
          value: 6,
          params: {
            status: 6,
          },
        },
        {
          text: "全部",
          value: "all",
          params: {
            status: "__all",
          },
        },
      ];
    },
  },
};
</script>

<style></style>