import config from '@/__config'

const model = {
  options: {
    id: true,
    timestamp: true,
  },
  modelName: 'xc_employee_salary_transfer_account',
  label: '薪轉帳戶',
  fields: {
    xc_employee: {
      type: 'belongsTo',
      label: '員工',
      modelName: 'xc_employee',
    },
    bank_code: {
      type: 'text',
      label: '銀行代號',
      defaultValue: config.wsmodule.xc_employee.xc_employee_salary_transfer_account?.bank_code?.default
    },
    bank_name: {
      type: 'text',
      label: '銀行名稱',
      defaultValue: config.wsmodule.xc_employee.xc_employee_salary_transfer_account?.bank_name?.default
    },
    bank_account_name: {
      type: 'text',
      label: '戶名',
    },
    bank_account: {
      type: 'text',
      label: '帳號',
    }
  },
  crudSetting: {
    index: {
      showFields: [
        "xc_employee",
        "bank_account_name",
        "bank_code",
        "bank_name",
        "bank_account",
      ],
      inRowBtnRead: true,
    },
  }
}

export default model