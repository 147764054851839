<template>
  <tr
    class="pa-8"
    @drag="$_onDrag($event)"
  >
    <td>
      <WsFlex>
        <FileIcon :ext="_fileExtention"></FileIcon>
        <WsLink
          class="ml-4"
          :href="url"
          target="_blank"
        >
          <WsText :color="$color.link">{{ file_name }}</WsText>
        </WsLink>
      </WsFlex>
    </td>
    <td>
      <FileSize :value="file_size"></FileSize>
    </td>
    <td>
      <WsText>{{ $moment(updated_at).format('YYYY.MM.DD HH:mm:ss') }}</WsText>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    file_name: {},
    file_size: {},
    updated_at: {},
    url: {},
  },
  methods: {
    $_onDrag($event) {
      console.log("$_onDrag", $event);
    },
  },
  computed: {
    _fileExtention() {
      return this.$o_o.$h.file.getFileExtension(this.file_name);
    },
  },
};
</script>

<style>
</style>