<template>
  <div>
    <WsCreate
      :modelName="modelName"
      :urlModelName="urlModelName"
      :label="label"
      :extendPostData="_extendPostData"
      :fields="_fields"
      :value="_value"
      :postUrl="postUrl"
      :params="_params"
      :returnUrl="returnUrl"
      :emitSubmit="emitSubmit"
      v-bind="crudSetting[bindingKey]"
      :completeRedirectUrl="completeRedirectUrl"
      @afterSubmit="$_afterSubmit($event)"
      @input="$_onInput($event)"
      @error="$_onError($event)"
      @submit="$_onSubmit($event)"
    >
    </WsCreate>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: `新增${this.label}`,
    };
  },
  data() {
    return {
      state: null,
    };
  },
  methods: {
    $_onSubmit($event) {
      if (this.crudSetting[this.bindingKey].emitSubmit) {
        this.$emit("submit", $event);
      }
      if (this.emitSubmit) {
        this.$emit('submit', $event)
      }
    },
    $_onInput($event) {
      this.state = $event;
      this.$emit("input", $event);
      // }
    },
    $_afterSubmit($event) {
      this.$emit("afterSubmit", $event);
    },
    $_onError($event) {
      if (
        $event.response &&
        $event.response.data &&
        $event.response.data.message
      ) {
        this.$store.dispatch("app/addSnack", `${this.$t("儲存錯誤！")}!`);
      }
    },
  },
  computed: {
    _fields() {
      if (this.getFields) {
        return this.getFields(this.state)
      } else {
        return this.fields
      }
    },
    _extendPostData() {
      let _extendPostData = {};
      if (this.extendPostData) {
        _extendPostData = {
          ..._extendPostData,
          ...this.extendPostData,
        };
      }
      if (
        this.crudSetting &&
        this.crudSetting[this.bindingKey] &&
        this.crudSetting[this.bindingKey].extendPostData
      ) {
        _extendPostData = {
          ..._extendPostData,
          ...this.crudSetting[this.bindingKey].extendPostData,
        };
      }
      return _extendPostData;
    },
    _value() {
      if (this.value) {
        return this.value;
      } else {
        return this.state;
      }
    },
    _params() {
      let _params = this.crudSetting[this.bindingKey].params;
      if (
        this.crudSetting[this.bindingKey].countryCode &&
        this.$store.state.auth.currentUser.country_code
      ) {
        _params = {
          ...this.crudSetting[this.bindingKey].params,
          country_code: this.$store.state.auth.currentUser.country_code,
        };
      }
      if (this.params) {
        _params = {
          ..._params,
          ...this.params,
        };
      }
      return _params;
    },
  },
  props: {
    postUrl: {
      type: String,
    },
    bindingKey: {
      type: String,
      default: "create",
    },
    modelName: {
      type: String,
    },
    urlModelName: {
      type: String,
    },
    label: {
      type: String,
    },
    fields: {
      type: Object,
    },
    crudSetting: {
      type: Object,
    },
    value: {
      type: Object,
    },
    extendPostData: {
      type: Object,
    },
    params: {
      type: Object,
    },
    returnUrl: {},
    getFields: {},
    completeRedirectUrl: {
      type: String
    },
    emitSubmit: {
      type: Boolean,
      default: false
    }
  },
};
</script>