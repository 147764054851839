<template>
  <WsModelIndex
    ref="WsModelIndex"
    label="調薪作業"
    getUrl="/xc_employee_salary_adjustjob/index/myown"
    :modelName="$store.state.stone_model.xc_employee_salary_adjustjob.modelName"
    :fields="$store.state.stone_model.xc_employee_salary_adjustjob.fields"
    :showFields="showFields"
    :creatable="true"
    :updatable="false"
    :deletable="true"
    :readable="true"
    :pageMode="true"
    :actionWidth="140"
    :customTableActions="customTableActions"
  ></WsModelIndex>
</template>

<script>
export default {
  data() {
    return {
      showFields: [
        "status",
        "effective_at",
        "xc_employee",
        "source",
        "xc_employee_department",
      ],
      customTableActions: [
        {
          icon: "icon-md-check",
          emit: "check",
          tooltip: this.$t("調薪確認"),
          getUrl: (item) => {
            return `/xc_employee_salary_adjustjob/${item.id}/adjustments/update`;
          },
          displayCheck: (item) => {
            if (item.status === "waiting") {
              return true;
            } else {
              return false;
            }
          },
        },
        {
          icon: "icon-md-edit",
          emit: "edit",
          tooltip: this.$t("編輯"),
          getUrl: (item) => {
            return `/xc_employee_salary_adjustjob/${item.id}/update`;
          },
          displayCheck: (item) => {
            if (item.status === "waiting") {
              return true;
            } else {
              return false;
            }
          },
        },
      ],
    };
  },
  computed: {},
  methods: {},
};
</script>

<style>
</style>