<template>
  <div
    class="xc_task_read_info"
    v-if="xc_task"
  >
    <WsFlex>
      <XcTaskStatusBar
        class="flex-1"
        :xc_task="xc_task"
      ></XcTaskStatusBar>
      <WsFlex v-if="!hideExtendBtns">
        <WsIconBtn
          class="ma-4"
          v-if="xc_task.taker?.id==_currentUser.id&&xc_task.taker_status==0"
          @click="$emit('takerinprogress',xc_task)"
          name="icon-md-play-arrow"
          tooltip="In Progress"
        ></WsIconBtn>
        <WsIconBtn
          class="ma-4"
          v-if="(xc_task.taker?.id == _currentUser.id && xc_task.taker_status==1)"
          @click="$emit('takerstay',xc_task)"
          name="icon-md-stop"
          tooltip="Stay"
        ></WsIconBtn>
        <WsIconBtn
          class="ma-4"
          v-if="[1,4,12].includes(parseInt(xc_task.status)) && xc_task.taker && (['edit', 'admin'].includes(xc_task.admin_scope))"
          @click="$_onTrRequest(xc_task)"
          text
          tooltip="發出 TR"
        >TR</WsIconBtn>
        <WsIconBtn
          class="ma-4"
          v-if="xc_task.status == 2 && xc_task.taker"
          text
          tooltip="開始 TR"
          :to="`/xc_task/${xc_task.id}/tr`"
        >TR</WsIconBtn>
        <WsIconBtn
          class="ma-4"
          v-if="[1, 3, 12].includes(parseInt(xc_task.status)) && (['edit', 'admin'].includes(xc_task.admin_scope))"
          @click="$_onOpen(xc_task)"
          text
          tooltip="Open"
        >Go</WsIconBtn>
        <WsIconBtn
          class="ma-4"
          v-if="(xc_task.status == 5) && (['edit', 'admin'].includes(xc_task.admin_scope))"
          @click="$_onReject(xc_task)"
          name="icon-md-block"
          tooltip="Reject"
        ></WsIconBtn>
        <WsIconBtn
          class="ma-4"
          v-if="[1, 2, 3, 4, 5, 11, 12].includes(parseInt(xc_task.status)) && (['edit', 'admin'].includes(xc_task.admin_scope))"
          name="icon-md-archive"
          tooltip="Close"
          @click="$emit('close', xc_task)"
        ></WsIconBtn>
        <!-- <WsIconBtn
            class="ma-4"
            v-if="[1, 2, 3, 4, 5, 11, 12].includes(parseInt(xc_task.status)) && (['edit', 'admin'].includes(xc_task.admin_scope))"
            @click="$emit('close', xc_task)"
            name="icon-md-archive"
            tooltip="Close"
          ></WsIconBtn> -->
        <WsIconBtn
          class="ma-4"
          v-if="[4, 11].includes(parseInt(xc_task.status)) && xc_task.taker && xc_task.taker?.id == _currentUser.id"
          name="icon-ws-outline-check"
          tooltip="Done"
          :to="`/xc_task/${xc_task.id}/done`"
        ></WsIconBtn>
        <WsIconBtn
          class="ma-4"
          v-if="[3, 4, 5, 11].includes(parseInt(xc_task.status)) && (['edit', 'admin'].includes(xc_task.admin_scope))"
          name="icon-md-pause-circle-outline"
          tooltip="Pending"
          @click="$emit('pending', xc_task)"
        ></WsIconBtn>
        <WsIconBtn
          class="ma-4"
          v-if="[6].includes(parseInt(xc_task.status)) && (['edit', 'admin'].includes(xc_task.admin_scope))"
          @click="$_onReOpen(xc_task)"
          text
          tooltip="ReOpen"
        >Go</WsIconBtn>
        <WsIconBtn
          class="ma-4"
          v-if="(['edit', 'admin'].includes(xc_task.admin_scope))"
          :to="`/xc_task/${xc_task.id}/update`"
          @click="$_onReOpen(xc_task)"
          tooltip="Edit"
          name="icon-md-edit"
        ></WsIconBtn>
      </WsFlex>
      <!-- <WsBtn>Done</WsBtn> -->
    </WsFlex>
    <div class="pa-20">
      <WsText
        class="mt-40"
        size="36"
      >{{ xc_task.name }}</WsText>
      <WsInfoHtml
        v-if="xc_task.content"
        class="mt-80"
        :value="xc_task.content"
        clickImgOpenPopup
      ></WsInfoHtml>
      <div class="mt-80">
        <XcTaskTaskNameLink
          v-for="(relate_xc_task,relate_xc_taskIndex) in xc_task.relate_xc_tasks"
          :key="relate_xc_taskIndex"
          :xc_task="relate_xc_task"
        />
      </div>
      <div v-if="xc_task.xc_task_execute_ref_items">
        <XcTaskExecuteRefItem
          v-for="(xc_task_execute_ref_item, xc_task_execute_ref_item_index) in xc_task.xc_task_execute_ref_items"
          :key="xc_task_execute_ref_item_index"
          :xc_task_execute_ref_item="xc_task_execute_ref_item"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    xc_task: {},
    hideExtendBtns: {},
  },
  methods: {
    async $_onTrRequest() {
      this.$emit("tr-request");
    },
    $_onReject() {
      this.$emit("reject");
    },
    async $_onOpen() {
      this.$emit("open");
    },
    async $_onDone() {
      this.$emit("done");
    },
    async $_onReOpen() {
      this.$emit("reopen");
    },
  },
  computed: {
    _currentUser() {
      return this.$store.state.auth.currentUser;
    },
    _breadcrumbsItems() {
      const _breadcrumbsItems = [
        {
          text: "My Task",
          to: "/my/task",
        },
      ];
      if (this.xc_task) {
        _breadcrumbsItems.push({
          text: `${this.xc_task.id}`,
          disabled: true,
        });
      }
      return _breadcrumbsItems;
    },
  },
};
</script>

<style lang="scss">
.xc_task_read_info {
  .ProseMirror {
    max-width: 600px;

    img {
      max-width: 500px;
    }
  }
}
</style>