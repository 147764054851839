<template>
  <WsModelIndex
    ref="WsModelIndex"
    label="發薪作業"
    getUrl="/xc_employee_salary_payjob/index/myown"
    :modelName="$store.state.stone_model.xc_employee_salary_payjob.modelName"
    :fields="$store.state.stone_model.xc_employee_salary_payjob.fields"
    :showFields="showFields"
    :creatable="true"
    :updatable="false"
    :readable="true"
    :pageMode="true"
    :actionWidth="140"
    :customTableActions="customTableActions"
    @pay="$_onPayCheck"
  ></WsModelIndex>
</template>

<script>
export default {
  data() {
    return {
      showFields: [
        "status",
        "start_at",
        "end_at",
        "confirm_at",
        "review_confirm_at",
      ],
      customTableActions: [
        {
          icon: "icon-md-date-available",
          emit: "check",
          tooltip: this.$t("發薪確認"),
          getUrl: (item) => {
            return `/xc_employee_salary_payjob/${item.id}/slips/update`;
          },
          displayCheck: (item) => {
            if (item.status === "waiting") {
              return true;
            } else {
              return false;
            }
          },
        },
        // {
        //   icon: "icon-md-edit",
        //   emit: "edit",
        //   tooltip: this.$t("編輯"),
        //   getUrl: (item) => {
        //     return `/xc_employee_salary_payjob/${item.id}/update`;
        //   },
        //   displayCheck: (item) => {
        //     if (item.status === "waiting") {
        //       return true;
        //     } else {
        //       return false;
        //     }
        //   },
        // },
        {
          icon: "icon-md-payment",
          emit: "pay",
          tooltip: this.$t("發薪"),
          displayCheck: (item) => {
            if (item.status === "review_confirmed") {
              return true;
            } else {
              return false;
            }
          },
        },
      ],
    };
  },
  computed: {},
  methods: {
    async $_onPay($event) {
      this.$store.dispatch("app/startPageLoading");
      try {
        await this.$axios.post(
          `/xc_employee_salary_payjob/${$event.item.id}/pay`
        );
        this.$refs.WsModelIndex.updateModelData({
          ...$event.item,
          status: "payed",
        });
      } catch (error) {
        alert(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    $_onPayCheck($event) {
      this.$store.dispatch("app/setAlert", {
        title: "確定標示發薪完成？",
        description: "",
        data: {
          item: $event.item,
        },
        action: this.$_onPay,
      });
    },
  },
};
</script>

<style>
</style>