const model = {
  options: {
    id: true,
    timestamp: true,
    tags: true,
  },
  modelName: "xc_customer",
  label: "客戶",
  fields: {
    name: {
      type: 'text',
      label: '公司抬頭',
      autofocus: true,
      hint: '用字要完全一致、注意類似『台、臺』的差異'
    },
    score: {
      type: "number",
      label: "分數",
      defaultValue: 5
    },
    uniform_numbers: {
      type: 'text',
      label: '統一編號',
    },
    email: {
      type: 'email',
      label: 'Email',
    },
    tel: {
      type: 'text',
      label: '電話',
    },
    address: {
      type: 'text',
      label: '地址',
      hint: '包含至少三碼郵遞區號，六碼更好)'
    },
    officialsite_url: {
      type: "link",
      label: "官網連結",
    },
    remark: {
      type: 'textarea',
      label: '備註',
    },
    contact_windows: {
      type: "list",
      label: "聯絡窗口",
      fields: {
        name: {
          type: 'text',
          label: '名稱'
        },
        mobile: {
          type: 'text',
          label: '手機'
        },
        email: {
          type: 'email',
          label: 'Email'
        },
        line: {
          type: 'text',
          label: 'LINE'
        },
        facebook: {
          type: 'text',
          label: 'Facebook'
        },
        remark: {
          type: 'textarea',
          label: '備註'
        },
      }
    },
    status: {
      type: 'radio',
      label: '狀態',
      items: [
        { label: '尚未合作', value: 1 },
        { label: '已合作', value: 2 },
      ],
      defaultValue: 1
    },
    source: {
      type: 'radio',
      label: '來源',
      items: [
        { label: 'Google搜尋', value: 'googlesearch' },
        { label: '廣告', value: 'googleads' },
        { label: '轉介', value: 'referral' },
      ],
    },
    source_remark: {
      type: 'textarea',
      label: '來源備註'
    },
    is_following: {
      type: 'switch',
      label: '追蹤',
      modelName: 'xc_customer',
      defaultValue: true,
      switchInInfo: true,
      keyName: 'is_following'
    },
  },
  crudSetting: {
    index: {
      pageMode: true,
      filterTabs: [
        {
          text: "已合作",
          value: '2',
          params: {
            status: 2,
          },
        },
        {
          text: "尚未合作",
          value: '1',
          params: {
            status: 1,
          },
        },
        {
          text: "全部",
          value: 'all',
          params: {},
        }
      ],
      showFields: [
        'is_following',
        'name',
        'status',
        'uniform_numbers',
        'email',
        'tel',
        'officialsite_url',
        'tags',
      ],
    },
    read: {
      titleKey: 'name',
      leftFields: [
        'name',
        'score',
        'email',
        'tel',
        'address',
        'officialsite_url',
        'remark',
        'contact_windows',
        'source',
        'source_remark',
      ],
      rightFields: [
        'uniform_numbers',
        'status',
        'tags',
      ],
      childCruds: [
        {
          modelName: 'xc_customer_interact_record',
          inRowBtnRead: true,
          showFields: [
            'interact_at',
          ],
        }
      ],
    },
    create: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'name',
                'score',
                'email',
                'tel',
                'address',
                'officialsite_url',
                'remark',
                'contact_windows',
                'source',
                'source_remark',
              ],
            },
          ],
        },
      ],
      secondary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'uniform_numbers',
                'status',
                'tags',
              ]
            }
          ]
        }
      ]
    },
    update: {
      titleKey: 'id',
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'name',
                'score',
                'email',
                'tel',
                'address',
                'officialsite_url',
                'remark',
                'contact_windows',
                'source',
                'source_remark',
              ],
            },
          ],
        },
      ],
      secondary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'uniform_numbers',
                'status',
                'tags',
              ]
            }
          ]
        }
      ]
    }
  }
}

export default model