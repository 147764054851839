<template>
  <WsMain>
    <XcTaskRead :id="_id">
      <template v-slot:sideInfoPreMiddle>
        <div class="my-20">
          <WsStateForm
            v-model="state"
            :fields="_fieldsDueDate"
          ></WsStateForm>
          <WsBtn
            class="mt-8"
            @click="$_onSubmit()"
          >送出</WsBtn>
        </div>
      </template>
    </XcTaskRead>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      state: {
        due_date: "",
      },
    };
  },
  computed: {
    _id() {
      return this.$route.params.id;
    },
    _fieldsDueDate() {
      let _fields = {
        due_date: this.$store.state.stone_model.xc_task.fields.due_date,
      };
      _fields.due_date.col = undefined;
      return _fields;
    },
  },
  methods: {
    async $_onSubmit() {
      this.$store.dispatch("app/startPageLoading");
      try {
        await this.$o_o.$s._m.xc_task.due_date(this._id, this.state.due_date);
        this.$router.push("/xc_task/");
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
  },
  props: {},
};
</script>