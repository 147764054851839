<template>
  <div>
    <WsModelIndex
      ref="WsModelIndex"
      :label="$store.state.stone_model.xc_milestone.label"
      :modelName="$store.state.stone_model.xc_milestone.modelName"
      :fields="$store.state.stone_model.xc_milestone.fields"
      :filterTabs="filterTabs"
      :showFields="$store.state.stone_model.xc_milestone.crudSetting.index.showFields"
      :extendOrderItems="$store.state.stone_model.xc_milestone.crudSetting.index.extendOrderItems"
      :order="$store.state.stone_model.xc_milestone.crudSetting.index.order"
      :creatable="true"
      :updatable="true"
      :deletable="true"
      :readable="true"
      :dialogCreate="true"
      :dialogUpdate="true"
      :actionWidth="140"
      :pageMode="true"
      :createFields="_createFields"
      :updateFields="_updateFields"
      :paramsOnCrud="_paramsOnCrud"
    ></WsModelIndex>
  </div>
</template>

<script>
export default {
  computed: {
    _createFields() {
      const _createFields = {
        ...this.$store.state.stone_model.xc_milestone.fields,
      };
      delete _createFields.xc_project;
      return _createFields;
    },
    _updateFields() {
      const _updateFields = {
        ...this.$store.state.stone_model.xc_milestone.fields,
      };
      delete _updateFields.xc_project;
      return _updateFields;
    },
    _id() {
      return this.$route.params.id;
    },
    _paramsOnCrud() {
      return {
        xc_project: this._id,
      };
    },
    _fields() {
      return {
        ...this.fields,
      };
    },
    _primary() {
      return [
        {
          type: "stateCard",
          floors: [
            {
              fields: [...this.primary[0].floors[0].fields],
            },
          ],
        },
      ];
    },
  },
  data() {
    return {
      fields: {
        robots_txt_allow_robots: {
          type: "switch",
          label: "Allow Robots",
        },
        robots_txt_crawl_delay: {
          type: "select",
          label: "Crawl Delay",
          items: [
            {
              text: "0",
              value: 0,
            },
            {
              text: "5",
              value: 5,
            },
            {
              text: "10",
              value: 10,
            },
            {
              text: "20",
              value: 20,
            },
            {
              text: "60",
              value: 60,
            },
            {
              text: "120",
              value: 120,
            },
          ],
        },
        robots_txt_sitemap_url: {
          type: "text",
          label: "Sitemap URL",
        },
        robots_txt_restricted_directories: {
          type: "text_array",
          label: "Restricted Directories",
        },
      },
      primary: [
        {
          type: "stateCard",
          floors: [
            {
              fields: [
                "robots_txt_allow_robots",
                "robots_txt_crawl_delay",
                "robots_txt_sitemap_url",
                "robots_txt_restricted_directories",
              ],
            },
          ],
        },
      ],
    };
  },
};
</script>

<style></style>
