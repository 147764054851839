<template>
  <WsText size="14">{{_content}}</WsText>
</template>

<script>
export default {
  methods: {
    $_getItemByValue(value) {
      const item = this._items.find((e) => {
        return e.value == value;
      });
      return item;
    },
  },
  computed: {
    _items() {
      if (this.items.length) {
        if (typeof this.items[0] == "string") {
          const _items = [];
          this.items.forEach((item) => {
            _items.push({
              value: item,
              text: item,
            });
          });
          return _items;
        } else {
          return this.items;
        }
      } else {
        return [];
      }
    },
    _content() {
      if (this.value == undefined || !this._items.length) {
        return null;
      } else {
        if (this.multiple) {
          let _value = "";
          this.value.forEach((valueItem, valueIndex) => {
            if (valueIndex > 0) {
              _value += ", ";
            }
            if (this.$_getItemByValue(valueItem)) {
              _value += this.$t(this.$_getItemByValue(valueItem).text);
            }
          });
          return _value;
        } else {
          if (this.$_getItemByValue(this.value)) {
            return this.$_getItemByValue(this.value).text;
          } else {
            return null;
          }
        }
      }
    },
  },
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Number, String, Array],
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>