import axios from 'axios'

export default {
  index_date(start, end, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`xc_leave_day/index/date`, {
          params: {
            ...params,
            start: start,
            end: end,
          }
        })
        .then((res) => {
          resolve(res.data.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  approve(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`xc_leave_day/${id}/approve`)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  disapprove(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`xc_leave_day/${id}/disapprove`)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  my_cancel(id) { // 取消 我的請假
    return new Promise((resolve, reject) => {
      axios
        .post(`/my/xc_leave_day/${id}/cancel`)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  my_withdraw(id) { // 收回 我的請假
    return new Promise((resolve, reject) => {
      axios
        .post(`/my/xc_leave_day/${id}/withdraw`)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}