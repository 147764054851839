export default {
  getXcProjectMenu(data, res) {
    res.splice(0, 0, {
      title: "行事曆",
      link: `/xc_project/${data.id}/calendar`,
    });
    return res
  },
  getXcProjectExtendRoutes(data, res) {
    let _routes = []
    if (res) {
      _routes = res
    }
    _routes.push(...[
      {
        path: 'calendar',
        name: 'Calendar',
        component: () => import('@/__modules/calendar/extend_modules/xc_project/views/XcProject/Calendar.vue'),
        meta: {
          middleware: 'auth',
        },
      },
    ])
    return _routes
  }
}