<template>
  <WsMain>
    <WsCrud
      label=""
      :modelName="$store.state.stone_model.xc_project.modelName"
      :fields="$store.state.stone_model.xc_project.fields"
      :showFields="$store.state.stone_model.xc_project.crudSetting.index.showFields"
      :filterTabs="filterTabs"
      :creatable="_creatable"
      :updatable="false"
      :deletable="false"
      :inRowBtnRead="true"
      :rowClickRead="false"
      :pageMode="true"
    ></WsCrud>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      filterTabs: [
        {
          text: "進行中",
          value: "ing",
          params: {
            status: "ing",
          },
        },
        {
          text: "結案",
          value: "complete",
          params: {
            status: "complete",
          },
        },
        {
          text: "全部",
          value: "all",
          params: {},
        },
      ],
    };
  },
  methods: {},
  computed: {
    _creatable() {
      return this.$o_o.$h.auth.hasScope([
        "xc_project-admin",
        "xc_project-create",
      ]);
    },
  },
};
</script>

<style>
</style>