export default {
  $_returnMyBoardComponent() {
    return [
      {
        keepLocation: 'right',
        componentName: 'XcEmployeeHireWaitingNextActionBoard',
        sq: 2
      },
    ]
  }
}
