<template>
  <WsUpdate
    label="系統設定 - 系統資源設定"
    :fields="fields"
    :primary="primary"
    getUrl="/system_setting/resource"
    :completeRedirect="false"
    :postEncode="false"
  >
  </WsUpdate>
</template>

<script>
export default {
  data() {
    return {
      fields: {
        static_images: {
          type: "file_images",
          label: "網頁靜態圖片",
        },
        static_files: {
          type: "file_files",
          label: "網頁靜態檔案",
        },
      },
      primary: [
        {
          type: "stateCard",
          floors: [
            {
              fields: ["static_images", "static_files"],
            },
          ],
        },
      ],
    };
  },
};
</script>

<style></style>