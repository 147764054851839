export default {
  getXcProjectMenu(data, res) {
    res.splice(1, 0, {
      title: "Task",
      link: `/xc_project/${data.id}/xc_task`,
    });
    return res
  },
  getXcProjectRedirectPath(data) {
    return `/xc_project/${data.id}/xc_task`
  },
  getXcProjectExtendRoutes(data, res) {
    let _routes = []
    if (res) {
      _routes = res
    }
    _routes.push(...[
      {
        path: 'xc_task',
        name: 'Task',
        component: () => import('@/__modules/xc_task/extend_modules/xc_project/views/XcProject/XcTask/Index.vue'),
        meta: {
          middleware: 'auth',
        },
      },
      {
        path: 'xc_task/scheduling',
        name: 'Task_scheduling',
        component: () => import('@/__modules/xc_task/extend_modules/xc_project/views/XcProject/XcTask/Scheduling.vue'),
        meta: {
          middleware: 'auth',
        },
      },
    ])
    return _routes
  }
}