import H_Router from '@/__stone/helpers/router'

const routes = [
  {
    path: '/my_substitute/substitute',
    name: 'my_substitute_substitute',
    component: () => import('@/__modules/xc_substitute/views/MySubstituteXcSubstitute/Index.vue'),
    meta: {
      middleware: 'auth'
    }
  },
  {
    path: '/my/substitute',
    name: 'my_substitute',
    component: () => import('@/__modules/xc_substitute/views/MyXcSubstitute/Index.vue'),
    meta: {
      middleware: 'auth'
    }
  },
  // {
  //   path: '/substitute',
  //   name: 'substitute',
  //   component: () => import('@/__modules/xc_substitute/views/XcSubstitute/Index.vue'),
  //   meta: {
  //     middleware: 'auth',
  //     requiredScopes: ['xc_substitute-admin'],
  //   }
  // },
  ...H_Router.getCrudTemplateRoutes({
    routes: ['index'],
    urlModelName: 'substitute',
    modelName: 'xc_substitute',
    middleware: 'auth',
    meta: {
      params: {},
    },
  }),
]

export default routes