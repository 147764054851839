<template>
  <div>
    <div
      v-for="(valueItem, valueItemIndex) in value"
      :key="valueItemIndex"
    >
      <WsText>{{$moment(valueItem[0]).format('HH:mm:ss')}} ~ {{$moment(valueItem[1]).format('HH:mm:ss')}}</WsText>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default() {
        return [];
      },
    },
  },
};
</script>

<style>
</style>