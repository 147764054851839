<template>
  <div
    class="ws-easy-table__content-item"
    :style="[{'background-color':_backgroundColor}]"
  >
    <div class="ws-easy-table__content-title">
      <WsText size="14">{{field.label}}</WsText>
    </div>
    <div class="ws-easy-table__content-content">
      <WsIconBtn
        v-if="copyable"
        name="icon-md-content-copy"
        @click="$_onCopyValue()"
      ></WsIconBtn>
      <WsInfo
        :value="value"
        v-bind="field"
        :label="undefined"
        :modelData="modelData"
      ></WsInfo>
    </div>

  </div>
</template>

<script>
export default {
  computed: {
    _backgroundColor() {
      if (this.isOdd) {
        return this._backgroundColorOdd;
      } else {
        return this._backgroundColorEven;
      }
    },
    _backgroundColorOdd() {
      if (this.backgroundColorOdd) {
        return this.backgroundColorOdd;
      } else {
        return null;
      }
    },
    _backgroundColorEven() {
      if (this.backgroundColorEven) {
        return this.backgroundColorEven;
      } else {
        return null;
      }
    },
    _type() {
      if (this.field.type == "list") {
        return "list";
      } else if (this.field.type == "hasMany") {
        return "hasMany";
      } else if (this.field.type == "files") {
        return "files";
      } else {
        return "text";
      }
    },
  },
  props: {
    modelData: {
      type: [Object, Array],
      default: null,
    },
    modelName: {
      type: String,
      default: null,
    },
    backgroundColorOdd: {
      type: String,
    },
    backgroundColorEven: {
      type: String,
    },
    field: Object,
    value: {},
    isOdd: {
      type: Boolean,
      default: false,
    },
    copyable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    $_onCopyValue() {
      navigator.clipboard.writeText(this.value);
      alert("已複製");
    },
  },
};
</script>