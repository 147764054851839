<template>
  <div>
    <WsActionApi fetchUrl="/drive/folder">
      <template v-slot="{ res }">
        {{ res }}
      </template>
    </WsActionApi>
    <WsInfiniteScroll
      v-slot="{ items }"
      fetchUrl="/drive/file"
      :bodyScroll="true"
    >
      <table class="w-full">
        <tr>
          <td>
            <WsDes>名稱</WsDes>
          </td>
          <td>
            <WsDes>檔案大小</WsDes>
          </td>
          <td>
            <WsDes>最後更新時間</WsDes>
          </td>
        </tr>
        <component
          :is="_listItemComponent"
          v-for="(item, itemIndex) in items"
          :key="itemIndex"
          v-bind="item"
        ></component>
      </table>
    </WsInfiniteScroll>
  </div>
</template>

<script>
export default {
  data() {
    return {
      layoutMode: "list",
    };
  },
  computed: {
    _listItemComponent() {
      if (this.layoutMode === "list") {
        return "FileSystemDriveListItem";
      } else {
        return "FileSystemDriveGridItem";
      }
    },
  },
};
</script>

<style>
</style>