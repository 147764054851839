const model = {
  options: {
    id: true,
    timestamp: true,
  },
  modelName: "xc_leave_day_settlement",
  label: "請假核扣",
  fields: {
    start_at: {
      type: 'datetime',
      label: '核算起始時間',
    },
    end_at: {
      type: 'datetime',
      label: '核算結束時間',
    },
    // source: {
    //   type: 'select',
    //   label: '核發來源',
    //   defaultValue: 'xc_employees',
    //   items: [
    //     {
    //       text: '指定專案',
    //       value: 'xc_project',
    //     },
    //     {
    //       text: '指定人員',
    //       value: 'xc_employees',
    //     },
    //   ]
    // },
    status: {
      type: 'select',
      label: '狀態',
      readonly: true,
      items: [
        {
          text: '處裡中',
          value: 'processing',
        },
        {
          text: '已核扣',
          value: 'payed',
        },
      ]
    },
    xc_employees: {
      type: "belongsToMany",
      label: "核發對象",
      textKey: "name",
      modelName: 'xc_employee'
    },
    payed_at: {
      type: 'datetime',
      label: '核扣時間',
      readonly: true,
    },
  },
  crudSetting: {
    index: {
      showFields: [
        "start_at",
        "end_at",
        "status",
        "payed_at",
      ],
      pageMode: true,
      creatable: true,
      updatable: true
    },
    read: {
      titleKey: 'name',
      leftFields: [
        "start_at",
        "end_at",
        "statys",
        "xc_employees",
        "payed_at",
      ],
    },
    create: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: ['start_at', 'end_at', "xc_employees"],
            },
          ],
        },
      ],
    },
  }
}

export default model