export default {
  options: {
    id: true,
    timestamp: true,
  },
  modelName: "xc_project_spec",
  label: "專案規格",
  fields: {
    name: {
      type: 'text',
      label: '名稱',
      rules: 'required'
    },
    content: {
      type: 'editor',
      label: '內容',
      rules: 'required'
    },
  },
  crudSetting: {
    index: {
      showFields: [
        'name',
      ],
      creatable: true,
      updatable: true,
      deletable: true,
    },
    create: {
      primary: [
        {
          type: "stateCard",
          floors: [
            {
              fields: ['name', 'content'],
            },
          ],
        },
      ],
    },
    update: {
      submitToIndex: true,
      titleKey: 'name',
      primary: [
        {
          type: "stateCard",
          floors: [
            {
              fields: ['name', 'content'],
            },
          ],
        },
      ],
    },
    read: {
      leftFields: ['name', 'content'],
      titleKey: 'name',
    },
  }
}