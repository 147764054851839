export default {
  my_off_day: {
    icon: 'icon-md-near-me',
    title: '我的 請假',
    link: '/my/off_day',
  },
  my_off_day_remain_hours: {
    icon: 'icon-md-near-me',
    title: '我的 假別餘額',
    link: '/my/off_day_remain_hours',
  },
  off_day: {
    icon: 'icon-md-near-me',
    title: '請假管理',
    scopes: ['xc_leave_day-admin'],
    menu: [
      {
        title: '請假單',
        link: '/off_day',
      },
      // {
      //   title: '請假核扣',
      //   link: '/xc_leave_day_settlement',
      // },
      {
        title: '餘額查詢',
        link: '/off_day_remain_hours',
      },
      {
        title: '休假數量變更',
        link: '/xc_leave_day_adjustment',
      },
      {
        title: '查詢請假單',
        link: '/xc_leave_day_search',
      },
    ]
  },
};