<template>
  <WsIcon
    :size="size"
    :name="_name"
    :color="_color"
  ></WsIcon>
</template>

<script>
export default {
  props: {
    ext: {},
    size: {
      default: 24,
    },
  },
  computed: {
    _name() {
      switch (this.ext) {
        case "aiff":
          return "icon-ws-fileicon-audio-aiff";
        case "mp3":
          return "icon-ws-fileicon-audio-mp3";
        case "wav":
          return "icon-ws-fileicon-audio-wav";
        case "css":
          return "icon-ws-fileicon-code-css";
        case "html":
          return "icon-ws-fileicon-code-html";
        case "json":
          return "icon-ws-fileicon-code-json";
        case "doc":
          return "icon-ws-fileicon-document-doc";
        case "docx":
          return "icon-ws-fileicon-document-docx";
        case "odt":
          return "icon-ws-fileicon-document-odt";
        case "pages":
          return "icon-ws-fileicon-document-pages";
        case "pdf":
          return "icon-ws-fileicon-document-pdf";
        case "rtf":
          return "icon-ws-fileicon-document-rtf";
        case "txt":
          return "icon-ws-fileicon-document-txt";
        case "csv":
          return "icon-ws-fileicon-form-csv";
        case "numbers":
          return "icon-ws-fileicon-form-numbers";
        case "ods":
          return "icon-ws-fileicon-form-ods";
        case "tsv":
          return "icon-ws-fileicon-form-tsv";
        case "xls":
          return "icon-ws-fileicon-form-xls";
        case "xlsx":
          return "icon-ws-fileicon-form-xlsx";
        case "xml":
          return "icon-ws-fileicon-form-xml";
        case "bmp":
          return "icon-ws-fileicon-image-bmp";
        case "bpg":
          return "icon-ws-fileicon-image-bpg";
        case "esp":
          return "icon-ws-fileicon-image-esp";
        case "gif":
          return "icon-ws-fileicon-image-gif";
        case "jpeg":
          return "icon-ws-fileicon-image-jpeg";
        case "jpg":
          return "icon-ws-fileicon-image-jpg";
        case "png":
          return "icon-ws-fileicon-image-png";
        case "svg":
          return "icon-ws-fileicon-image-svg";
        case "tiff":
          return "icon-ws-fileicon-image-tiff";
        case "webp":
          return "icon-ws-fileicon-image-webp";
        case "avi":
          return "icon-ws-fileicon-video-avi";
        case "mov":
          return "icon-ws-fileicon-video-mov";
        case "mp4":
          return "icon-ws-fileicon-video-mp4";
        case "wmv":
          return "icon-ws-fileicon-video-wmv";
        default:
          return "icon-ws-fileicon-common";
      }
    },
    _color() {
      switch (this.ext) {
        case "aiff":
          return this.$color.white5d;
        case "mp3":
          return this.$color.white5d;
        case "wav":
          return this.$color.white5d;
        case "css":
          return this.$color.white5d;
        case "html":
          return this.$color.white5d;
        case "json":
          return this.$color.white5d;
        case "doc":
          return this.$color.white5d;
        case "docx":
          return this.$color.white5d;
        case "odt":
          return this.$color.white5d;
        case "pages":
          return this.$color.white5d;
        case "pdf":
          return this.$color.white5d;
        case "rtf":
          return this.$color.white5d;
        case "txt":
          return this.$color.white5d;
        case "csv":
          return this.$color.white5d;
        case "numbers":
          return this.$color.white5d;
        case "ods":
          return this.$color.white5d;
        case "tsv":
          return this.$color.white5d;
        case "xls":
          return this.$color.white5d;
        case "xlsx":
          return this.$color.white5d;
        case "xml":
          return this.$color.white5d;
        case "bmp":
          return this.$color.white5d;
        case "bpg":
          return this.$color.white5d;
        case "esp":
          return this.$color.white5d;
        case "gif":
          return this.$color.white5d;
        case "jpeg":
          return this.$color.white5d;
        case "jpg":
          return this.$color.white5d;
        case "png":
          return this.$color.white5d;
        case "svg":
          return this.$color.white5d;
        case "tiff":
          return this.$color.white5d;
        case "webp":
          return this.$color.white5d;
        case "avi":
          return this.$color.white5d;
        case "mov":
          return this.$color.white5d;
        case "mp4":
          return this.$color.white5d;
        case "wmv":
          return this.$color.white5d;
        default:
          return this.$color.white5d;
      }
    },
  },
};
</script>

<style>
</style>