<template>
  <div>
    <WsText
      size="16"
      class="ws-info-text"
      v-html="_value"
    ></WsText>
    <!-- :inner-html.prop="value|__filterText" -->
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number, Boolean],
      default: null,
    },
    getDisplayText: {
      type: Function,
    },
  },
  computed: {
    _value() {
      if (this.getDisplayText) {
        return this.getDisplayText(this.value);
      } else {
        return this.value;
      }
    },
  },
};
</script>