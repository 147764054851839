import TextareaAutosize from 'vue-textarea-autosize'
import linkify from 'vue-linkify';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { SlickList, SlickItem, HandleDirective } from "vue-slicksort";
import Vue2Transitions from 'vue2-transitions'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/zh-tw';
import { Chrome, Sketch } from 'vue-color'
import vClickOutside from 'v-click-outside'
import VueMeta from 'vue-meta'
import Croppa from 'vue-croppa'
import 'vue-croppa/dist/vue-croppa.css'
import Ripple from 'vue-ripple-directive'
import VueLazyload from 'vue-lazyload'
import VTooltip from 'v-tooltip'
import VueClipboard from 'vue-clipboard2'
import VueExcelEditor from 'vue-excel-editor'



export default (app) => {
  app.use(TextareaAutosize)
  app.directive('linkified', linkify);
  app.component('ValidationProvider', ValidationProvider);
  app.component('ValidationObserver', ValidationObserver);
  app.component('SlickList', SlickList);
  app.component('SlickItem', SlickItem);
  app.directive('handle', HandleDirective);
  app.use(Vue2Transitions)
  app.component('DatePicker', DatePicker)
  app.component('ColorPicker', Chrome)
  app.component('SketchColorPicker', Sketch)
  app.use(vClickOutside)
  app.use(VueMeta)
  app.use(Croppa)
  Ripple.color = 'rgba(255, 255, 255, 0.2)';
  app.directive('ripple', Ripple);
  app.use(VueLazyload, {
    loading: require('@/__stone/img/loading.gif')
  })
  app.use(VTooltip)
  VueClipboard.config.autoSetContainer = true
  app.use(VueClipboard)
  app.use(VueExcelEditor)
}