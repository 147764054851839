export default {
  toCalendarEvent(
    xc_milestone,
  ) {
    const _event = {}
    if (!xc_milestone) {
      return
    }
    _event.title = `[Milestone] ${xc_milestone.name}`
    _event.color = '#0281b0'
    _event.start = xc_milestone.start_at
    _event.end = xc_milestone.end_at
    _event.allDay = true
    _event.extendedProps = {
      ...xc_milestone,
      modelName: 'xc_milestone'
    }
    return _event
  }
}