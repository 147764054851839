<template>
  <div class="ws-state-date">
    <DatePicker
      ref="input"
      :lang="lang"
      class="ws-state-date-picker"
      :value="_value"
      @input="$_onInput($event)"
      :placeholder="_placeholder"
      :format="format"
      :clearable="clearable"
      :disabled-date="$_disabledDate"
      :disabled="disabled"
    >
      <slot name="icon-calendar">
        <i class="icon-calendar"></i>
      </slot>
    </DatePicker>
  </div>
</template>

<script>
export default {
  name: "WsStateDate",

  data: () => ({
    lang: {
      formatLocale: {
        firstDayOfWeek: 7,
      },
    },
  }),

  props: {
    disabledDate: {
      type: Function,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    value: {},
    placeholder: {
      type: String,
      default: "選擇日期",
    },
    format: {
      type: String,
      default: "YYYY-MM-DD",
    },
    startDate: {
      type: String,
    },
    modelData: {
      type: Object,
    },
  },

  computed: {
    _value() {
      if (this.value) {
        return new Date(this.value);
      } else {
        return null;
      }
    },
    _placeholder(){
      return `${this.$t(this.placeholder)}`
    }
  },

  methods: {
    $_disabledDate(date) {
      let check = false;
      if (this.disabledDate) {
        check = this.disabledDate(date);
      }
      if (!check && this.startDate) {
        if (this.$moment(date).isBefore(this.startDate)) {
          check = true;
        }
      } else if (typeof this.disabledDate == "function") {
        return this.disabledDate(this.modelData, date);
      } else {
        return false;
      }
      return check;
    },
    $_onInput($event) {
      if (!$event) {
        return this.$emit("input", null);
      }
      this.$emit("input", this.$moment($event).format(this.format));
    },
    customFormatter(date) {
      return this.$moment(date).format(this.format);
    },
  },
};
</script>