<template>
  <div>
    <WsMain>
      <WsActionApi
        fetchUrl="/xc_employee_salary_adjustment"
        :params="_params"
        v-slot="{ res, isFetching }"
      >
        <WsLoading v-if="isFetching"></WsLoading>
        <XcEmployeeSalaryAdjustmentUpdateTable
          v-else
          :xc_employee_salary_adjustments="res.data"
        />
      </WsActionApi>
    </WsMain>
    <WsBottomNav>
      <template v-slot:rightActions>
        <WsBtn
          class="ml-8"
          @click="$_onSubmitCheck()"
        >完成覆核</WsBtn>
      </template>
    </WsBottomNav>
  </div>
</template>

<script>
export default {
  methods: {
    $_onSubmitCheck() {
      this.$store.dispatch("app/setAlert", {
        title: "確定完成覆核？",
        description: "",
        action: this.$_onSubmit,
      });
    },
    async $_onSubmit() {
      this.$store.dispatch("app/startPageLoading");
      try {
        await this.$axios.post(
          `/xc_employee_salary_adjustjob/${this._id}/review_confirm`
        );
        this.$router.go(-1);
      } catch (error) {
        alert(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
  },
  computed: {
    _params() {
      return {
        xc_employee_salary_adjustjob: this._id,
      };
    },
    _id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style>
</style>