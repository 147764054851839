<template>
  <div>
    <WsActionApi
      class="width-full"
      :fetchUrl="_fetchUrl"
      v-slot="{ res, isFetching }"
    >
      <WsLoading v-if="isFetching"></WsLoading>
      <vue-excel-editor
        ref="VueExcelEditor"
        v-else-if="res"
        class="width-full"
        :value="$_getFormatedData(res.data)"
      >
        <vue-excel-column
          v-for="column in excelColumns"
          :key="column.field"
          v-bind="column"
        />
      </vue-excel-editor>
    </WsActionApi>
    <WsFixedIconBtns :btns="btns"></WsFixedIconBtns>
  </div>
</template>

<script>
export default {
  data() {
    return {
      btns: [
        {
          icon: "icon-md-print",
          text: "匯出",
          size: 60,
          onClick: () => {
            this.$_print();
          },
        },
      ],
      excelColumns: [
        {
          field: "xc_employee_department",
          label: "部門",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "xc_employee_name",
          label: "姓名",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "xc_employee_type",
          label: "雇用別",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "type",
          label: "假別",
          type: "string",
          width: "150px",
          editable: false,
          readonly: true,
        },
        {
          field: "created_at",
          label: "申請日期",
          type: "string",
          width: "100px",
          editable: false,
          readonly: true,
        },
        {
          field: "start_date",
          label: "請假開始日期",
          type: "string",
          width: "100px",
          editable: false,
          readonly: true,
        },
        {
          field: "days",
          label: "請假天數",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "start_at",
          label: "請假開始時間 (不足一日)",
          type: "string",
          width: "150px",
          editable: false,
          readonly: true,
        },
        {
          field: "hours",
          label: "時數 (不足一日)",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "note",
          label: "事由",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "status",
          label: "表單狀態",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "is_aboard",
          label: "是否出國",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "pay_at",
          label: "發薪年月",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
      ],
    };
  },
  methods: {
    $_print() {
      const format = "xlsx";
      const exportSelectedOnly = false;
      const filename = "請假單";
      this.$refs.VueExcelEditor.exportTable(
        format,
        exportSelectedOnly,
        filename
      );
    },
    $_getSlectValueText(value, items) {
      const item = items.find((item) => item.value === value);
      return item ? item.text : "";
    },
    $_getFormatedData(data) {
      return data.map((item) => {
        return {
          xc_employee_department: item.xc_employee.xc_employee_department?.name,
          xc_employee_name: item.xc_employee.name,
          xc_employee_type: item.xc_employee.xc_employee_type?.name,
          type: this.$_getSlectValueText(
            item.type,
            this.$store.state.stone_model.xc_leave_day.fields.type.items
          ),
          status: this.$_getSlectValueText(
            item.status,
            this.$store.state.stone_model.xc_leave_day.fields.status.items
          ),
          created_at: this.$moment(item.created_at).format("YYYY-MM-DD"),
          start_date: this.$moment(item.start_date).format("YYYY-MM-DD"),
          days: item.days,
          start_at: item.start_at
            ? this.$moment(item.start_at).format("YYYY-MM-DD HH:mm:ss")
            : null,
          hours: item.hours,
          note: item.note,
          is_aboard: item.is_aboard ? "是" : "否",
          pay_at: null,
        };
      });
    },
  },
  computed: {
    _fetchUrl() {
      return `/xc_leave_day/index/date?start=${this.$route.query.start}&end=${this.$route.query.end}&xc_employee=${this.$route.query.xc_employee}`;
    },
  },
};
</script>

<style>
</style>