<template>
  <div>
    <WsCrud
      :titleBarCustomBtns="titleBarCustomBtns"
      v-bind="$store.state.stone_model.xc_project_member"
      :showFields="$store.state.stone_model.xc_project_member.crudSetting.index.showFields"
      :params="_params"
      :createData="_createData"
      :updateData="_updateData"
      :creatable="_creatable"
      :updatable="_updatable"
      :deletable="_deletable"
    ></WsCrud>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: `成員|${this.xc_project.name}`,
    };
  },
  data() {
    return {
      titleBarCustomBtns: [
        {
          label: "計畫人月",
          to: `/xc_project/${this.xc_project.id}/member_month_days`,
        },
      ],
    };
  },
  props: {
    xc_project: {},
  },
  computed: {
    _params() {
      return {
        xc_project: this.xc_project.id,
      };
    },
    _createData() {
      return {
        xc_project: this.xc_project.id,
      };
    },
    _updateData() {
      return {
        xc_project: this.xc_project.id,
      };
    },
    _creatable() {
      return this.$o_o.$h._m.xc_project.hasScope(
        ["admin"],
        this.xc_project.admin_scope
      );
    },
    _updatable() {
      return this.$o_o.$h._m.xc_project.hasScope(
        ["admin"],
        this.xc_project.admin_scope
      );
    },
    _deletable() {
      return this.$o_o.$h._m.xc_project.hasScope(
        ["admin"],
        this.xc_project.admin_scope
      );
    },
  },
};
</script>

<style>
</style>