<template>
  <div
    class="ws-navigation-drawer"
    :class="[{active: _sideMenuOpen}]"
  >
    <div
      @click="$_close()"
      class="bg"
    ></div>
    <div
      :class="['ws-navigation-drawer__container', {'desktop-hide': _desktopHide}]"
      :style="[{'background-color':backgroundColor}]"
    >
      <WsFlex alignItems="center" class="px-16 py-10">
        <RouterLink to="/" class="ws-navigation-drawer__logo">
          <img :src="_logo">
        </RouterLink>
        <WsIcon @click="$_onMenuBtnClick()" name="icon-md-dock-to-right" size="24" class="cursor-pointer" />
      </WsFlex>
      <div class="ws-navigation-drawer__menu">
        <WsMenuGroup :items="_menu"></WsMenuGroup>
      </div>
    </div>
  </div>
</template>

<script>
import S_APP_Cms from "@/__stone/service/app/cms";
export default {
  computed: {
    _mainScope() {
      return S_APP_Cms.getMainScope();
    },
    _menu() {
      return this.$o_o.$h.menu.getCmsMenu(this.$store.state.app.topic);
    },
    _sideMenuOpen() {
      return this.$store.state.app.sideMenuOpen
    },
    _desktopHide() {
      if (this.$store.state.app.window.width >= 767) {
        return !this._sideMenuOpen
      } else {
        return false
      }
    },
    _logo() {
      if (this.$config.menu.sideMenuImg) {
        return this.$config.menu.sideMenuImg
      } else {
        return require('@/__stone/img/wasajon.svg')
      }
    }
  },
  mounted() {
    if (this.$store.state.app.window.width > 600) {
      this.$store.dispatch('app/menuOpen')
    }
  },
  methods: {
    $_close() {
      this.$store.dispatch("app/menuClose");
    },
    $_onMenuBtnClick() {
      this.$store.dispatch('app/menuToggle')
    },
  },
  props: {
    icon: {
      type: String,
      default: null,
    },
    contentPadding: {
      type: Boolean,
      default: true,
    },
    backgroundColor: {
      type: String,
    },
  },
};
</script>