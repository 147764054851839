// import config from '@/__config'

const model = {
  options: {
    id: true,
    timestamp: true,
  },
  modelName: "xc_work_overtime",
  label: "加班申請",
  fields: {
    id: {
      type: "text",
      label: 'ID',
      readonly: true
    },
    start_at: {
      type: 'datetime',
      label: '加班開始時間'
    },
    hour: {
      type: "number",
      label: "加班時數",
    },
    remark: {
      type: 'textarea',
      label: '備註',
    },
    status: {
      type: 'select',
      label: '狀態',
      readonly: true,
      defauleValue: 1,
      items: [
        {
          text: '簽核中',
          value: 'processing'
        },
        {
          text: '已核准',
          value: 'approved'
        },
        {
          text: '不同意',
          value: 'disapproved'
        },
        {
          text: '結算中',
          value: 'settling'
        },
        {
          text: '已支付',
          value: 'payed'
        },
        {
          text: '收回',
          value: 'withdrawn'
        },
        {
          text: '取消',
          value: 'canceled'
        },
      ]
    },
    exchange_for_compensatory_leave: {
      type: 'radio',
      label: '是否換補休',
      defaultValue: 0,
      items: [
        {
          label: '否',
          value: 0,
        },
        {
          label: '是',
          value: 1,
        },
      ]
    },
    xc_employee: {
      type: "belongsTo",
      label: "申請人",
      textKey: "name",
      modelName: 'xc_employee'
    },
    // admin: {
    //   type: "belongsTo",
    //   label: "CMS使用者",
    //   textKey: "name",
    // modelName: config.wsmodule.admin.admin_blur ? 'cmser' : 'admin',
    //   readonly: true,
    // },
    status_updated_at: {
      type: "datetime",
      label: "狀態更新時間",
      readonly: true,
    },
    work_overtime_type: {
      type: 'select',
      label: '加班類型',
      readonly: true,
      items: [
        {
          text: '平日加班',
          value: 'weekday'
        },
        {
          text: '休息日加班',
          value: 'weekend'
        },
        {
          text: '國定假日加班',
          value: 'official_holiday'
        }
      ]
    }
  },
  crudSetting: {
    index: {
      showFields: [
        "xc_employee",
        "created_at",
        "start_at",
        "hour",
        "status",
        "work_overtime_type",
      ],
      pageMode: false,
      creatable: false,
      updatable: false,
      showFieldsUpdate: [
        "status",
      ]
    },
    index_my: {
      showFields: [
        "created_at",
        "start_at",
        "hour",
        "status",
        "work_overtime_type",
      ],
      pageMode: false,
      updatable: false,
      dialogRead: true,
    },
    create_my: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'start_at',
                'hour',
                'exchange_for_compensatory_leave',
                'remark',
              ],
            },
          ],
        },
      ],
    },
    read: {

    }
  }
}

export default model