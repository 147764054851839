<template>
  <div class="ws-state-tags">
    <TagTargetModelTags
      v-if="target"
      class="mb-8"
      :target="target"
      @click-tag="$_onClickModelTag($event)"
    />
    <form @submit.prevent="$_onTagAddFromInput()">
      <WsState
        :type="inputType"
        :placeholder="placeholder"
        v-model="addingText"
      ></WsState>
    </form>
    <div class="ws-state-tags__tags">
      <a
        class="ws-state-tags__tag"
        v-for="(tag, tagIndex) in value"
        :key="tagIndex"
      >
        <div class="ws-state-tags__tag__text">{{tag}}</div>
        <WsIcon
          @click="$_onRemove(tagIndex)"
          name="icon-md-cancel"
        />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    addingText: null,
  }),
  methods: {
    $_onClickModelTag($event) {
      const _value = this.value ? [...this.value] : [];
      if (_value.includes($event.name)) {
        return;
      } else {
        _value.push($event.name);
      }
      this.$emit("input", _value);
    },
    $_onTagAddFromTagsArr(newTagsArr) {
      const _value = this.value ? [...this.value] : [];
      newTagsArr.forEach((newTag) => {
        if (_value.includes(newTag)) {
          return;
        } else {
          _value.push(newTag);
        }
      });
      this.$emit("input", _value);
    },
    $_onTagAddFromInput() {
      if (!this.addingText) {
        return;
      }
      const newTagsArr = this.addingText.split(" ");
      this.$_onTagAddFromTagsArr(newTagsArr);
      this.addingText = null;
    },
    $_onRemove(index) {
      let _value = this.value ? JSON.parse(JSON.stringify(this.value)) : [];
      if (!Array.isArray(_value)) {
        _value = [];
      } else {
        _value.splice(index, 1);
      }
      this.$emit("input", _value);
    },
  },
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    inputType: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default() {
        return this.$t("enter_to_create_new_tag");
      },
    },
    target: {},
  },
  computed: {},
};
</script>

<style>
</style>