<template>
  <div>
    <WsActionApi
      fetchUrl="/xc_employee_salary_slip"
      :params="_params"
      v-slot="{ res, isFetching }"
    >
      <WsLoading v-if="isFetching"></WsLoading>
      <XcEmployeeSalarySlipUpdateTable
        ref="XcEmployeeSalarySlipUpdateTable"
        v-else
        review
        :xc_employee_salary_slips="res.data"
      />
    </WsActionApi>
    <WsBottomNav>
      <template v-slot:rightActions>
        <WsBtn
          class="ml-8"
          @click="$_onSubmitCheck()"
        >完成覆核</WsBtn>
      </template>
    </WsBottomNav>
  </div>
</template>

<script>
export default {
  methods: {
    $_onSubmitCheck() {
      if (!this.$refs.XcEmployeeSalarySlipUpdateTable.checkSubmit()) {
        this.$store.dispatch("app/setAlert", {
          title: "尚有未確認的項目",
          description: "",
        });
      } else {
        this.$store.dispatch("app/setAlert", {
          title: "確定送出覆核？",
          description: "",
          action: this.$_onSubmit,
        });
      }
    },
    async $_onSubmit() {
      this.$store.dispatch("app/startPageLoading");
      try {
        await this.$axios.post(
          `/xc_employee_salary_payjob/${this._id}/review_confirm`
        );
        this.$router.push("/xc_employee_salary_payjob");
      } catch (error) {
        alert(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
  },
  computed: {
    _params() {
      return {
        xc_employee_salary_payjob: this._id,
      };
    },
    _id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style>
</style>