<template>
  <div>
    <WsActionApi
      class="width-full"
      :fetchUrl="_fetchUrl"
      v-slot="{ res, isFetching }"
    >
      <WsLoading v-if="isFetching"></WsLoading>
      <vue-excel-editor
        ref="VueExcelEditor"
        v-else-if="res"
        class="width-full"
        :value="$_getFormatedData(res.data)"
      >
        <vue-excel-column
          v-for="column in _excelColumns"
          :key="column.field"
          v-bind="column"
        />
      </vue-excel-editor>
    </WsActionApi>
    <WsFixedIconBtns :btns="btns"></WsFixedIconBtns>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //       單位
      // 申請人
      // 人員別
      // 申請日期
      // 加班類別
      // 申請開始日期
      // 申請結束日期
      // 申請加班時數
      // 實際開始時間
      // 實際結束時間
      // 實際加班時數
      // 加班費時數
      // 補休時數
      // 額外補休
      // 補休總時數
      // 已休補休時數
      // 補休有效期限
      // 加班誤餐次數
      // 加班歸屬單位
      // 事由
      // 代簽辦人
      // 表單狀態
      // 加班單種類
      // 經刷卡檢核
      // 發薪年月
      btns: [
        {
          icon: "icon-md-print",
          text: "匯出",
          size: 60,
          onClick: () => {
            this.$_print();
          },
        },
      ],
      excelFields: {
        xc_employee_department: {
          label: "部門",
        },
        xc_employee_name: {
          label: "姓名",
        },
        xc_employee_type: {
          label: "雇用別",
        },
        created_at: {
          label: "申請日期",
          width: "100px",
        },
        xc_work_overtime_type: {
          label: "加班類別",
        },
        start_date: {
          label: "申請開始日期",
          width: "100px",
        },
      },
      excelColumns: [
        {
          field: "xc_employee_type",
          label: "雇用別",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "type",
          label: "假別",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "created_at",
          label: "申請日期",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "start_date",
          label: "請假開始日期",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "days",
          label: "請假天數",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "start_at",
          label: "請假開始時間 (不足一日)",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "hours",
          label: "時數 (不足一日)",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "note",
          label: "事由",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "status",
          label: "表單狀態",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "is_aboard",
          label: "是否出國",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
        {
          field: "pay_at",
          label: "發薪年月",
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
        },
      ],
    };
  },
  methods: {
    $_print() {
      const format = "xlsx";
      const exportSelectedOnly = false;
      const filename = "請假單";
      this.$refs.VueExcelEditor.exportTable(
        format,
        exportSelectedOnly,
        filename
      );
    },
    $_getSlectValueText(value, items) {
      const item = items.find((item) => item.value === value);
      return item ? item.text : "";
    },
    $_getFormatedData(data) {
      return data.map((item) => {
        return {
          xc_employee_department: item.xc_employee.xc_employee_department?.name,
          xc_employee_name: item.xc_employee.name,
          xc_employee_type: item.xc_employee.xc_employee_type?.name,
          created_at: this.$moment(item.created_at).format("YYYY-MM-DD"),
        };
      });
    },
  },
  computed: {
    _excelColumns() {
      const _excelColumns = [];
      for (const excelFieldKey in this.excelFields) {
        const excelField = this.excelFields[excelFieldKey];
        _excelColumns.push({
          field: excelFieldKey,
          type: "string",
          width: "80px",
          editable: false,
          readonly: true,
          ...excelField,
        });
      }
      return _excelColumns;
    },
    _fetchUrl() {
      return `/xc_work_overtime/index/date?start=${this.$route.query.start}&end=${this.$route.query.end}&xc_employee=${this.$route.query.xc_employee}`;
    },
  },
};
</script>

<style>
</style>