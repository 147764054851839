<template>
  <WsMain>
    <WsCrud
      :modelName="$store.state.stone_model.system_event.modelName"
      label="行事曆項目"
      :fields="_fields"
      :showFields="showFields"
    ></WsCrud>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      showFields: ["start_date", "name", "type"],
    };
  },
  computed: {
    _fields() {
      return this.$o_o.$h.model.getFieldsByKeys(
        this.$store.state.stone_model.system_event.fields,
        ["id", "name", "type", "start_date", "days", "note"]
      );
    },
  },
};
</script>