<template>
  <XcTaskIndex
    label="我的 管理 Task"
    :showFields="showFields"
    :params="_params"
    :filterTabs="_filterTabs"
    :filterSelects="_filterSelects"
  ></XcTaskIndex>
</template>

<script>
export default {
  data() {
    return {
      showFields: ["id", "status", "due_date", "taker", "xc_project", "name"],
    };
  },
  methods: {
    async $_onOpen($event) {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.xc_task.open($event.item.id);
        this.$refs.WsCrud.updateModelData({
          ...$event.item,
          status: "4",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    async $_onTrRequest($event) {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.xc_task.tr_request($event.item.id);
        this.$refs.WsCrud.updateModelData({
          ...$event.item,
          status: "2",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    $_getCreateFields(state) {
      const _createFields = {};
      _createFields.name = this.$store.state.stone_model.xc_task.fields.name;
      _createFields.xc_project =
        this.$store.state.stone_model.xc_task.fields.xc_project;
      if (state.xc_project) {
        _createFields.taker = {
          ...this.$store.state.stone_model.xc_task.fields.taker,
          fetchUrl: `/xc_project/${state.xc_project.id}/member`,
        };
      }
      _createFields.content =
        this.$store.state.stone_model.xc_task.fields.content;
      _createFields.tags = this.$store.state.stone_model.xc_task.fields.tags;
      return _createFields;
    },
    $_getUpdateFields(state) {
      const _updateFields = {};
      _updateFields.name = this.$store.state.stone_model.xc_task.fields.name;
      _updateFields.xc_project =
        this.$store.state.stone_model.xc_task.fields.xc_project;
      _updateFields.taker = {
        ...this.$store.state.stone_model.xc_task.fields.taker,
      };
      if (state.xc_project) {
        _updateFields.taker.fetchUrl = `/xc_project/${state.xc_project.id}/member`;
      }
      _updateFields.content =
        this.$store.state.stone_model.xc_task.fields.content;
      _updateFields.tags = this.$store.state.stone_model.xc_task.fields.tags;
      return _updateFields;
    },
  },
  computed: {
    _filterSelects() {
      let _filterSelects = {
        taker: {
          label: "執行人",
          type: "belongsTo",
          modelName: this.$o_o.$c.wsmodule.admin.admin_blur ? "cmser" : "admin",
          textKey: "name",
          col: 3,
           extendParams: {
            is_active: 1
          },
        },
      };
      _filterSelects = this.$o_o.$h.module.extendModuleHelperAction(
        "xc_task",
        "xc_task.js",
        "getIndexFilterSelects",
        {
          filterSelects: _filterSelects,
        }
      );
      return _filterSelects;
    },
    _indexFields() {
      const _indexFields = {
        ...this.$store.state.stone_model.xc_task.fields,
      };
      _indexFields.taker = {
        ...this.$store.state.stone_model.xc_task.fields.taker,
        type: "user_label",
      };
      return _indexFields;
    },
    _params() {
      return {
        creator: this.$store.state.auth.currentUser.id,
      };
    },
    _filterTabs() {
      return [
        {
          text: "待處理",
          value: 4,
          params: {
            status: "1,3,5",
          },
        },
        {
          text: "全部",
          value: "all",
          params: {
            status: "__all",
          },
        },
      ];
    },
    _updateFields() {
      const _updateFields = {
        name: this.$store.state.stone_model.xc_task.fields.name,
        taker: {
          ...this.$store.state.stone_model.xc_task.fields.taker,
        },
        content: this.$store.state.stone_model.xc_task.fields.content,
        tags: this.$store.state.stone_model.xc_task.fields.tags,
      };
      return _updateFields;
    },
    _customTableActions() {
      return [
        {
          emit: "tr-request",
          tooltip: this.$t("發出 TR"),
          text: "TR",
          displayCheck: (item) => {
            if (item.status == 1 && item.taker) {
              return true;
            } else {
              return false;
            }
          },
        },
        {
          emit: "open",
          tooltip: this.$t("Open"),
          text: "Go",
          displayCheck: (item) => {
            if ((item.status == 3 || item.status == 1) && item.taker) {
              return true;
            } else {
              return false;
            }
          },
        },
      ];
    },
  },
};
</script>

<style></style>