<template>
  <component
    @click="$emit('click',$event)"
    class="ws-link"
    :is="_component"
    :target="target"
    :to="to"
    :href="href"
    :style="[{color:_color}]"
  >
    <slot></slot>
  </component>
</template>

<script>
export default {
  computed: {
    _component() {
      if (this.href) {
        return "a";
      } else if (this.to) {
        if (this.to.startsWith("http://") || this.to.startsWith("https://")) {
          return "a";
        } else {
          return "router-link";
        }
      } else {
        return "div";
      }
    },
    _color() {
      if (this.color) {
        return this.color;
      } else {
        return this.$color.link;
      }
    },
  },
  props: {
    href: {
      type: String,
    },
    to: {
      type: String,
    },
    target: {
      type: String,
    },
    color: {
      type: String,
    },
  },
};
</script>

<style>
</style>