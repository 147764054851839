<template>
  <WsMain>
    <WsActionApi
      :fetchUrl="`/my/xc_employee`"
      v-slot="{ res, isFetching }"
    >
      <WsLoading v-if="isFetching"></WsLoading>
      <WsInfoForm
        v-else
        :fields="$store.state.stone_model.xc_employee.fields"
        :value="res.data"
      ></WsInfoForm>
    </WsActionApi>
  </WsMain>
</template>

<script>
export default {};
</script>

<style>
</style>