<template>
  <div>
    <LayoutModelCreate
      v-bind="$store.state.stone_model.xc_task"
      @afterSubmit="$_afterSubmit($event)"
      :label="_label"
    >
    </LayoutModelCreate>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: `${this.$t("新增")} ${this.$t(
        this.$store.state.stone_model.xc_task.label
      )}`,
    };
  },
  computed: {
    _label() {
      return this.$store.state.stone_model.xc_task.label;
    },
  },
  methods: {
    $_afterSubmit($event) {
      if ($event) {
        let _url = `${location.origin}/xc_task/${$event.id}`;
        navigator.clipboard.writeText(_url);
        this.$store.dispatch("app/addSnack", `Task 連結新增至剪貼簿`);
      }
    },
  },
};
</script>

<style>
</style>