<template>
  <div
    class="ws-app-header"
    :class="[{'has-drawer': _sideMenuOpen}]"
  >
    <WsFlex
      v-if="_showMenuBtn"
      alignItems="center"
      class="menu-btn ml-24 mr-16"
    >
      <WsIcon
        @click="$_onMenuBtnClick()"
        size="24"
        name="icon-md-dock-to-right"
      />
    </WsFlex>
    <div class="ws-app-header__btns">
      <WsBtn
        v-for="(topic,topicKey) in _topics"
        :key="topicKey"
        class="mx-2"
        v-ripple.300
        :to="`/cms_topic/${topicKey}`"
        styleType="plain"
        :isActive="$store.state.app.topic==topicKey"
      >{{topic.title}}</WsBtn>
    </div>
    <WsSearchBar v-if="$o_o.$c.cms.search_global"></WsSearchBar>
    <WsLink
      class="flex-0"
      to="/me"
    >
      <WsFlex
        class="pa-8 sm-pa-12 cursor-pointer"
        alignItems="center"
      >
        <WsAvatar
          size="28"
          :borderWidth="$config.wsmodule.admin.fields&&$config.wsmodule.admin.fields.color?2:0"
          :borderColor="$store.state.auth.currentUser.color"
          :src="$store.state.auth.currentUser.avatar"
        ></WsAvatar>
        <WsText class="ml-12 d-xs-none">{{$store.state.auth.currentUser.name}}</WsText>
      </WsFlex>
    </WsLink>
  </div>
</template>

<script>
export default {
  methods: {
    $_onMenuBtnClick() {
      this.$store.dispatch("app/menuToggle");
    },
  },

  computed: {
    _avatar() {
      if (!this._user) {
        return null;
      } else {
        return this._user.avatar;
      }
    },
    _user() {
      return this.$store.state.auth.currentUser;
    },
    _topics() {
      return this.$o_o.$h.menu.getCmsTopics();
    },
    _sideMenuOpen() {
      return this.$store.state.app.sideMenuOpen
    },
    _showMenuBtn() {
      if (this.$store.state.app.window.width >= 767) {
        return !this._sideMenuOpen
      } else {
        return true
      }
    },
  },

  props: {
    userLink: {
      type: String,
      default: "/user",
    },
  },
};
</script>