<template>
  <div>
    <WsActionApi
      fetchUrl="my/overview/xc_work_overtime"
      v-slot="{ res, isFetching }"
    >
      <WsLoading v-if="isFetching"></WsLoading>
      <WsBoardCard001
        v-else
        v-bind="$_getCardProps(res)"
      />
    </WsActionApi>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showTypes: ["xc_work_overtime"],
    };
  },
  methods: {
    $_getCardProps(res) {
      if (!res || !res.xc_work_overtime) return {};
      return {
        items: [
          {
            label: "加班詳情",
            items: [
              {
                label: "本月加班時數",
                value: res.xc_work_overtime.total_hours_this_month,
              },
            ],
          },
        ],
        urlText: "我的所有加班單",
        url: "/my/xc_work_overtime",
      };
    },
  },
};
</script>
