<template>
  <WsMain class="xs-pa-0">
    <WsCrud
      ref="WsCrud"
      :modelName="$store.state.stone_model.xc_leave_day.modelName"
      label="請假"
      :fields="_fields"
      :customTableActions="_customTableActions"
      :showFields="showFields"
      @status-ok="$_onStatusOk($event)"
      @status-no="$_onStatusNo($event)"
      :inRowBtnRead="true"
    ></WsCrud>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      showFields: ["xc_employee", "created_at", "days", "start_date", "status"],
    };
  },
  methods: {
    async $_onStatusOk($event) {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.xc_leave_day.approve($event.item.id);
        this.$refs.WsCrud.updateModelData({
          ...$event.item,
          status: "approved",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    async $_onStatusNo($event) {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.xc_leave_day.disapprove($event.item.id);
        this.$refs.WsCrud.updateModelData({
          ...$event.item,
          status: "disapproved",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
  },
  computed: {
    _customTableActions() {
      if (this.$config.wsmodule.xc_process) {
        return [];
      } else {
        return [
          {
            emit: "status-ok",
            text: "OK",
            displayCheck: (item) => {
              return (
                item.status == "processing" || item.status == "disapproved"
              );
            },
          },
          {
            emit: "status-no",
            text: "No",
            displayCheck: (item) => {
              return item.status == "processing" || item.status == "approved";
            },
          },
        ];
      }
    },
    _fields() {
      return {
        ...this.$o_o.$h.model.getFieldsByKeys(
          this.$store.state.stone_model.xc_leave_day.fields,
          [
            "xc_employee",
            "created_at",
            "type",
            "status",
            "start_date",
            "days",
            "start_at",
            "hours",
            "relate_files",
            "note",
          ]
        ),
      };
    },
  },
};
</script>