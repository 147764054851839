import axios from 'axios'

export default {
  cancel(id) { // 取消 新聘作業
    return new Promise((resolve, reject) => {
      axios
        .post(`/xc_employee_hire/${id}/cancel`)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  withdraw(id) { // 收回 新聘作業
    return new Promise((resolve, reject) => {
      axios
        .post(`/xc_employee_hire/${id}/withdraw`)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}