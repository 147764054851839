<template>
  <WsMain>
    <WsCrud
      ref="WsCrud"
      v-bind="$store.state.stone_model.xc_substitute.crudSetting.index_my_substitute"
      modelName="my_substitute/xc_substitute"
      label="我的職位代理(代理人)"
      :fields="_fields"
      :inRowBtnRead="true"
    ></WsCrud>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  computed: {
    _fields() {
      return this.$o_o.$h.model.getFieldsByKeys(
        this.$store.state.stone_model.xc_substitute.fields,
        ["admin", "start_at", "end_at", "note", "xc_projects"]
      );
    },
  },
};
</script>