export default {
  options: {
    id: true,
    timestamp: true,
    tags: true,
  },
  modelName: "xc_rfq",
  label: "RFQ",
  fields: {
    status: {
      type: 'radio',
      label: '狀態',
      defaultValue: 'negotiating',
      items: [
        {
          value: 'negotiating',
          label: '洽談中'
        },
        {
          value: 'quoting',
          label: '報價中'
        },
        {
          value: 'readytosign',
          label: '準備簽約'
        },
        {
          value: 'deal',
          label: '成交'
        },
        {
          value: 'endtracking',
          label: '結束追蹤'
        },
      ]
    },
    title: {
      type: 'text',
      label: '需求主旨',
      rules: 'required'
    },
    request_content: {
      type: 'editor',
      label: '需求說明',
      defaultValue: '需求說明：<br><br>參考網站：<br><br>參考圖片：',
    },
    attachments: {
      type: 'file_files',
      label: '附件',
      storageTarget: 'xc_rfq'
    },
    budget: {
      type: 'text',
      label: '預算',
    },
    contact_at: {
      type: 'datetime',
      label: '聯絡時間',
    },
    // contract_links: {
    //   type: 'list',
    //   label: '合約連結',
    //   fields: {
    //     name: {
    //       type: 'text',
    //       label: '名稱',
    //       rules: 'required'
    //     },
    //     link: {
    //       type: 'link',
    //       label: '連結',
    //       rules: 'required'
    //     },
    //     remark: {
    //       type: 'textarea',
    //       label: '備註',
    //     },
    //   },
    // },
    source: {
      type: 'text',
      label: '來源',
    },
    source_domain: {
      type: 'text',
      label: '來源網域',
    },
    client_name: {
      type: 'text',
      label: '客戶名稱',
    },
    website: {
      type: 'text',
      label: '客戶網站',
    },
    contact_window: {
      type: 'text',
      label: '聯絡窗口',
    },
    contact_window_email: {
      type: 'text',
      label: '聯絡窗口信箱',
    },
    payload: {
      type: 'payload',
      fields: {
        type: {
          type: 'text',
          label: '產業類型',
        },
        has_domain: {
          type: 'switch',
          label: '有網域',
        },
        has_logo: {
          type: 'switch',
          label: '有LOGO',
        }
      }
    }
  },
  crudSetting: {
    index: {
      pageMode: true,
      showFields: [
        'status',
        'title',
        'budget',
        'contact_at',
      ],
    },
    create: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'request_content',
              ],
            },
          ],
        },
      ],
      secondary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'status',
                'title',
                'client_name',
                'website',
                'contact_window',
                'contact_window_email',
                'payload',
                'attachments',
                'budget',
                'contact_at',
              ],
            },
          ],
        },
      ]
    },
    update: {
      submitToIndex: true,
      titleKey: 'title',
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'request_content',
              ],
            },
          ],
        },
      ],
      secondary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'status',
                'title',
                'client_name',
                'website',
                'contact_window',
                'contact_window_email',
                'payload',
                'attachments',
                'budget',
                'contact_at',
                // 'contract_links',
              ],
            },
          ],
        },
      ]
    },
    read: {
      leftFields: [
        "request_content",
      ],
      rightFields: [
        'status',
        'title',
        'client_name',
        'website',
        'contact_window',
        'contact_window_email',
        'payload',
        'attachments',
        'budget',
        'contact_at',
        // 'contract_links',
      ],
      titleKey: 'name',
      childCruds: [
        {
          modelName: 'xc_rfq_note',
        },
      ],
    },
  }
}