<template>
  <WsModelIndex
    ref="WsModelIndex"
    label="工資管理"
    getUrl="/xc_employee/index/salary"
    :fields="_fields"
    :readable="true"
    :dialogRead="true"
    :showFields="showFields"
    :getReadFetchUrl="$_getReadFetchUrl"
  ></WsModelIndex>
</template>

<script>
export default {
  data() {
    return {
      showFields: [
        "no",
        "name",
        "approved_salary",
        "approved_salary_without_transportation",
      ],
    };
  },
  computed: {
    _fields() {
      const _fields = {
        no: this.$store.state.stone_model.xc_employee.fields.no,
        name: this.$store.state.stone_model.xc_employee.fields.name,
        basic_salary:
          this.$store.state.stone_model.xc_employee_hire.fields.basic_salary,
        meal_expenses:
          this.$store.state.stone_model.xc_employee_hire.fields.meal_expenses,
        work_allowance:
          this.$store.state.stone_model.xc_employee_hire.fields.work_allowance,
        transportation_allowance:
          this.$store.state.stone_model.xc_employee_hire.fields
            .transportation_allowance,
        approved_salary:
          this.$store.state.stone_model.xc_employee_hire.fields.approved_salary,
        approved_salary_without_transportation:
          this.$store.state.stone_model.xc_employee_hire.fields
            .approved_salary_without_transportation,
      };
      return _fields;
    },
  },
  methods: {
    $_getReadFetchUrl(item) {
      return `xc_employee/${item.id}/salary`;
    },
  },
};
</script>

<style>
</style>